import { CATEGORIES_ACTION_TYPES } from './categoriesActionTypes';
import initialState from '../initial-state';

export default function (state = initialState.categories, action) {
  switch (action.type) {
    case CATEGORIES_ACTION_TYPES.LOAD_CATEGORIES_SUCCESS:
      return action.categories;
    default:
      return state;
  }
}
