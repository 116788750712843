import { API_STATUS_ACTION_TYPES } from './apiStatusActionTypes';
import initialState from '../initial-state';

// Courtesy Dan Abramov
function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === '_SUCCESS';
}

export default function apiCallStatusReducer(
  state = initialState.apiCallsInProgress,
  action
) {
  if (action.type == API_STATUS_ACTION_TYPES.BEGIN_API_CALL) {
    return state + 1;
  } else if (
    actionTypeEndsInSuccess(action.type) ||
    action.type == API_STATUS_ACTION_TYPES.API_CALL_ERROR
  ) {
    return state - 1;
  }
  return state;
}
