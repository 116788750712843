import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './css/common.css';
import { SecretRouter } from './App';
import { RouteList, admin_route } from './constants/Routes';
import usePermission from './hooks/usePermissions';
import { token } from './api/apiUtils';
import { hasPaidModuleAccess } from './utils';
import { Scopes, Slug } from './constants/Routes/permissionConstants';

/**
 * AppRoute component is responsible for rendering the application routes based on user permissions.
 *
 * @component
 * @param {Object} userDetail - User details obtained from the Redux store.
 * @returns {JSX.Element|null} - The JSX element representing the application routes, or null if user details are not available.
 */
const AppRoute = ({ userDetail }) => {
  const hasPermission = usePermission();
  const navigate = useNavigate();
  const location = useLocation();
  const tokenV2 = token();

  useEffect(() => {
    // Use the effect to handle navigation after component mounts
    if (!tokenV2) {
      // Store the redirected url
      if (location.pathname != '/login' && location.pathname != '/') {
        navigate(
          `/login?redirected_URI=${encodeURIComponent(window.location.href)}`
        );
      }
      if (location.pathname == '/') {
        navigate('/login');
      }
    }
  }, [tokenV2, location.pathname, navigate]);

  useEffect(() => {
    if (
      location.pathname == '/' &&
      !location.search &&
      hasPermission(Scopes.VIEW, Slug.SUPPLIER_MATRIX)
    ) {
      navigate('/supplier');
    }
  }, [tokenV2]);

  if (Object.keys(userDetail).length === 0) return null;

  const getChildRoutes = routeList => {
    let childRoutes = [];
    routeList.forEach(route => {
      if (
        route.child_routes &&
        !hasPaidModuleAccess(userDetail, route.paid_feature)
      ) {
        childRoutes = [...childRoutes, ...route.child_routes];
      }
    });
    return childRoutes;
  };

  /**
   * Returns an array of JSX elements representing the allowed routes based on user permissions.
   *
   * @function
   * @returns {JSX.Element[]} - An array of JSX elements representing the allowed routes.
   */
  const routeElements = () => {
    return getChildRoutes([...RouteList, admin_route])
      .filter(({ slug, permission, is_superuser = false }) => {
        const isPermitted = hasPermission(permission, slug);
        if (is_superuser) {
          return (
            (userDetail.is_superuser || userDetail.is_staff) && isPermitted
          );
        }
        return isPermitted;
      })
      .map(({ name, component: Component, path }) => {
        return <Route key={name} path={path} element={Component} />;
      });
  };

  return (
    <Routes>
      <Route element={<SecretRouter />}>{routeElements()}</Route>
    </Routes>
  );
};

function mapStateToProps(state) {
  return {
    userDetail: state.userDetail
  };
}

export default connect(mapStateToProps)(AppRoute);
