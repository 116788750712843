import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pathMatch } from '../../../utils';
import classes from './HeaderLayout.module.scss';
import { Breadcrumb } from './GradeHeader';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { formatDate } from '../../../utils/MeltingSection';

/**
 * Header component for the Heat Details section of the application.
 * This component displays a header with dynamic content based on the current route.
 * When viewing test details, it shows an 'ArrowBackIcon', a breadcrumb navigation to 'Heat Details',
 * and the name of the test with its status color-coded.
 *
 * @returns {JSX.Element} The header component for the Heat Details section, with content changing based on whether test details are being viewed.
 */
const HeatDetailsHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    shift_date = null,
    heat_name = '',
    is_submitted = null
  } = useSelector(state => state.heatLogDetail);

  const isHeatDetail = pathMatch('/heats', '/\\d+', location.pathname);
  if (!isHeatDetail)
    return <span className={classes.title}> Heat Details </span>;

  return (
    <div>
      <div className={classes.breadCrumbContainer}>
        <>
          <Breadcrumb navigate={navigate} text='Heat Details' path='/heats' />
        </>
        <i className='ri-arrow-right-s-line' />
        {(heat_name || shift_date) && (
          <span
            className={classes.heatDetailsHeaderText}
            style={{ fontSize: isMobile ?? '12px' }}
          >
            {`${heat_name} | ${formatDate(shift_date)}`}
          </span>
        )}
        {is_submitted !== null && !is_submitted && (
          <div className={classes.draftTag}>Draft</div>
        )}
      </div>
    </div>
  );
};

export default HeatDetailsHeader;
