export const SPECTRO_ACTION_TYPES = {
  GET_SPECTRO_LIST_SUCCESS: 'GET_SPECTRO_LIST_SUCCESS',
  GET_SPECTRO_SUCCESS: 'GET_SPECTRO_SUCCESS',
  ADD_SPECTRO_SUCCESS: 'ADD_SPECTRO_SUCCESS',
  UPDATE_SPECTRO_SUCCESS: 'UPDATE_SPECTRO_SUCCESS',
  CREATE_SPECTRO: 'CREATE_SPECTRO',
  CLEAR_SPECTRO: 'CLEAR_SPECTRO',
  SPECTRO_OVERVIEW_DATA_SUCCESS: 'SPECTRO_OVERVIEW_DATA_SUCCESS',
  SPECTRO_FILTER_OPTIONS_SUCCESS: 'SPECTRO_FILTER_OPTIONS_SUCCESS',
  SPECTRO_READING: 'SPECTRO_READING',
  SPECTRO_READING_ADD_DIL: 'SPECTRO_READING_ADD_DIL',
  SPECTRO_READING_ADD_DIL_INVENTORY: 'SPECTRO_READING_ADD_DIL_INVENTORY',
  GET_ITEM_INVENTORY_BY_LIST: 'GET_ITEM_INVENTORY_BY_LIST',
  GET_ITEM_INVENTORY_DETAIL: 'GET_ITEM_INVENTORY_DETAIL',
  UPDATE_ITEM_INVENTORY: 'UPDATE_ITEM_INVENTORY',
  UPDATE_ITEM_INVENTORY_LIST: 'UPDATE_ITEM_INVENTORY_LIST'
};
