import { PART_LIST_ACTION_TYPES } from './partActionType';
import initialState from '../initial-state';

/**
 * Reducer function for the part list state slice.
 *
 * @param {Array} state - The current state of the part list.
 * @param {object} action - The action to be applied to the state.
 * @returns {Array} - The updated state of the part list.
 */
export function partList(state = initialState.partList, action) {
  switch (action.type) {
    case PART_LIST_ACTION_TYPES.GET_PART_LIST_SUCCESS:
      return action.partList;
    case PART_LIST_ACTION_TYPES.UPDATE_PART_LIST_SUCCESS:
      return state.map(part => {
        if (part.id === action.updatedGrade.id) {
          return {
            ...part,
            ...action.updatedGrade
          };
        }
        return part;
      });
    default:
      return state;
  }
}
// GET_PART_DETAIL_SUCCESS partDetail
/**
 * Reducer function for the part detail state slice.
 *
 * @param {Array} state - The current state of the part detail.
 * @param {object} action - The action to be applied to the state.
 * @returns {Array} - The updated state of the part list.
 */
export function partDetail(state = initialState.partDetail, action) {
  switch (action.type) {
    case PART_LIST_ACTION_TYPES.GET_PART_DETAIL_SUCCESS:
      return action.partDetail;
    default:
      return state;
  }
}

export const addPartCurrentStep = (
  state = initialState.addPartCurrentStep,
  action
) => {
  switch (action.type) {
    case 'SET_CURRENT_STEP':
      return action.payload;
    default:
      return state;
  }
};

export const addPartStepData = (
  state = initialState.addPartStepData,
  action
) => {
  switch (action.type) {
    case PART_LIST_ACTION_TYPES.ADD_PART_BASIC_STEP_DATA_UPDATE:
      return {
        ...state,
        basic_data: action.payload
      };
    case PART_LIST_ACTION_TYPES.ADD_PART_INVENTORY_ITEM_STEP_DATA_UPDATE:
      return {
        ...state,
        part_item: action.payload
      };
    default:
      return state;
  }
};
