import React from 'react';
import { toast } from 'react-toastify';
import { parseError } from '../../utils/Grade';

/**
 * Default toast message displayed for pending, error and success states.
 *
 * @constant
 * @type {object}
 */
const DEFAULT_TOAST_MESSAGE = {
  pending: 'Request pending',
  error: 'Request error',
  success: 'Request success'
};

/**
 * Calls a function after a specified delay.
 *
 * @param {object} options - Options for the delay and the function to execute.
 * @param {number} options.delay - The delay in milliseconds.
 * @param {Function} options.onSuccess - The function to call after the delay.
 * @returns {Promise} A promise that resolves after the delay and function execution.
 */
const onFinally = ({ delay, onSuccess }) =>
  new Promise(resolve =>
    setTimeout(() => {
      onSuccess?.();
      resolve();
    }, delay)
  );

/**
 * Handles an API call with a loading spinner and toast notifications for pending, error and success states.
 *
 * @param {object} options - Options for the API call, callback function, toast messages, and delay.
 * @param {Function} options.apiFunc - The API function to call.
 * @param {Function} options.onSuccess - The callback function to call after the API call succeeds.
 * @param {object} options.toastText - Custom toast messages for pending, error and success states.
 * @param {number} [options.delay=0] - The delay in milliseconds before the onSuccess function is called.
 * @returns {Promise} A promise that resolves after the API call and onSuccess function execution.
 */
export const handleApiCallWithToast = async ({
  apiFunc,
  onSuccess,
  toastText,
  delay = 0
}) => {
  return toast.promise(
    apiFunc()
      .then(() => onFinally({ delay, onSuccess }))
      .catch(async error => {
        const errorToastText = parseError(error.message);
        await onFinally({ delay });
        toast.dismiss();
        toast.error(<span>{errorToastText}</span>);
        throw Error;
      }),
    {
      ...DEFAULT_TOAST_MESSAGE,
      ...toastText
    }
  );
};
