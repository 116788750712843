import { Avatar } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';
import { ProfileIcon } from '../../../assets/icons/modifiedIcons';
import { stringAvatar } from '../../../utils';
import classes from './HeaderLayout.module.scss';
import RenderConditionally from '../../RenderConditionally';
import HTMLToolTipList from '../../HTMLToolTipList';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Logout from './Logout';

const DesktopView = ({ user }) => {
  return (
    <>
      <div className={classes.profile}>
        <RenderConditionally condition={user.name}>
          <span>{user.name?.split(' ')[0]}</span>
        </RenderConditionally>

        <RenderConditionally condition={user.customer?.company_name}>
          <span>{user.customer?.company_name}</span>
        </RenderConditionally>
      </div>
    </>
  );
};

const MobView = ({ user, mobAnchor, handleClickAway }) => {
  return (
    <>
      {mobAnchor && (
        <>
          <div
            onClick={handleClickAway}
            className={classes.mobLogoutOnclickout}
          ></div>
          <div className={classes.mobLogoutStyle}>
            <div className={classes.avtarAlingment}>
              {user.name ? (
                <Avatar
                  className={classes.AvatarInitials}
                  {...stringAvatar(String(user.name))}
                />
              ) : (
                <ProfileIcon fontSize={60} />
              )}
            </div>
            <div className={classes.userNameLogout}>{user.name}</div>
            <div className={classes.companyNameLogout}>
              {user.customer?.company_name}
            </div>
            <Logout />
          </div>
        </>
      )}
    </>
  );
};

const UserProfile = () => {
  const [mobAnchor, setMobAnchor] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const user = useSelector(state => state.userDetail);

  const handleClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };
  const handleClickAway = () => setMobAnchor(false);

  return (
    <div className={clsx(classes.userProfile)}>
      <HTMLToolTipList
        width='223px'
        padding='1px'
        marginRight='40px'
        isTooltipOpen={isTooltipOpen}
        setIsTooltipOpen={setIsTooltipOpen}
        id='htmlToolTip'
        arrowStyles={{
          left: 'auto !important',
          right: '15px !important',
          transform: 'none !important'
        }}
        onClick={e => {
          e.stopPropagation();
          if (!isTooltipOpen) {
            setIsTooltipOpen(true);
          }
        }}
        title={
          <React.Fragment>
            <div className={classes.avtarAlingment}>
              {user.name ? (
                <Avatar
                  className={classes.AvatarInitials}
                  {...stringAvatar(String(user.name))}
                />
              ) : (
                <ProfileIcon fontSize={60} />
              )}
            </div>
            <div className={classes.userNameLogout}>{user.name}</div>
            <div className={classes.companyNameLogout}>
              {user.customer?.company_name}
            </div>
            <Logout />
          </React.Fragment>
        }
      >
        <div>
          <div className={classes.userProfileBox} onClick={handleClick}>
            {user.name ? (
              <Avatar
                style={{ paddingTop: '2%' }}
                {...stringAvatar(String(user.name))}
              />
            ) : (
              <ProfileIcon fontSize={40} />
            )}
            {!isMobile && <DesktopView user={user} />}
            <span
              style={{
                marginTop: !isMobile ? '-10px' : '0px',
                transition: '300ms ease-in-out all',
                transform: isTooltipOpen ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            >
              <i className='ri-arrow-down-s-line'></i>
            </span>
          </div>
          {isMobile && (
            <MobView
              user={user}
              handleClickAway={handleClickAway}
              mobAnchor={mobAnchor}
            />
          )}
        </div>
      </HTMLToolTipList>
    </div>
  );
};

export default UserProfile;
