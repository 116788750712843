import React, { lazy } from 'react';
import { Routes } from 'react-router-dom';
import LoadLazyComponent from '../../components/LoadLazyComponent';
import usePermission from '../../hooks/usePermissions';
import { Scopes, Slug } from '../../constants/Routes/permissionConstants';
import { routeElements } from '../../utils';

const Parts = lazy(() => LoadLazyComponent(() => import('../Parts/index')));
const AddPart = lazy(() => LoadLazyComponent(() => import('../Parts/AddPart')));
const ViewParts = lazy(() =>
  LoadLazyComponent(() => import('../../componentsV2/parts/viewParts/ViewPart'))
);
const PartDetails = lazy(() =>
  LoadLazyComponent(() => import('../../componentsV2/parts/PartDetails'))
);

const partAppRoutes = [
  {
    path: '/',
    component: Parts,
    permission: [],
    exact: false
  },
  {
    component: AddPart,
    permission: Scopes.EDIT,
    path: '/add',
    exact: false
  },
  {
    component: ViewParts,
    permission: [],
    path: '/:id',
    exact: false
  },
  {
    component: PartDetails,
    permission: Scopes.EDIT,
    path: '/:id/edit',
    exact: false
  }
];

const PartsApp = () => {
  const hasPermission = usePermission();
  return (
    <Routes>{routeElements(partAppRoutes, hasPermission, Slug.PARTS)}</Routes>
  );
};

export default PartsApp;
