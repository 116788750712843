import React, { cloneElement, useEffect } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/system';

const HTMLToolTipList = ({
  children,
  title,
  width,
  height,
  marginRight = '0px',
  isTooltipOpen,
  setIsTooltipOpen,
  id,
  padding,
  arrowStyles,
  ...props
}) => {
  useEffect(() => {
    if (id) {
      window.addEventListener('click', function (e) {
        if (!document.getElementById(id)?.contains(e.target)) {
          setIsTooltipOpen(false);
        }
      });
    }
  }, []);

  const additionalProps = {
    onMouseEnter: () => {
      if (setIsTooltipOpen) {
        setIsTooltipOpen(true);
      }
    }
  };
  const ToolTipList = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
      open={isTooltipOpen}
      id={id}
      arrow
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      marginRight,
      width,
      height,
      background: 'rgba(0, 0, 0, 0.6)',
      backdropFilter: 'blur(10px)',
      borderRadius: '8px',
      padding,
    },
    ["& .MuiTooltip-arrow"]: arrowStyles
  }));

  return (
    <ToolTipList title={title}>
        {cloneElement(children, Object.keys(props).length > 0 ? props : additionalProps)}
    </ToolTipList>
  );
};

export default HTMLToolTipList;
