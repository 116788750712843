import React, { lazy } from 'react';
import { Slug } from './permissionConstants';
import {
  ActiveRawMaterialIcon,
  ControlPlanActiveIcon,
  ControlPlanInactiveIcon,
  InactiveRawMaterialIcon,
  QualityReportActive,
  QualityReportInactive,
  SupplierActiveIcon,
  SupplierInactiveIcon
} from '../../assets/icons/modifiedIcons';
import LoadLazyComponent from '../../components/LoadLazyComponent';
import InventoryHeader from '../../componentsV2/Layout/Header/InventoryHeader';
import QualityReportHeader from '../../componentsV2/Layout/Header/QualityReportHeader';
import ControlPlanHeader from '../../componentsV2/Layout/Header/ControlPlanHeader';

const RawMaterialApp = lazy(() =>
  LoadLazyComponent(() => import('../../pages/RawMaterialApp'))
);

const QualityReportsApp = lazy(() =>
  LoadLazyComponent(() => import('../../pages/QualityReports'))
);

const ControlPlanApp = lazy(() => LoadLazyComponent(() => import('../../pages/ControlPlanApp')))
const SupplierMatrixApp = lazy(()=>
  LoadLazyComponent(() => import('../../pages/SupplierMatrix'))
)

export const Raw_materials_route = {
  name: 'RAW MATERIALS',
  can_view: [Slug.SUPPLIER_MATRIX, Slug.ITEM_INVENTORY, Slug.QUALITY_REPORTS, Slug.CONTROL_PLAN],
  child_routes: [
    {
      name : 'Supplier Matrix',
      icon : SupplierInactiveIcon,
      active_icon : SupplierActiveIcon,
      component: <SupplierMatrixApp />,
      header: () => 'Supplier Matrix',
      path: 'supplier/*',
      slug: Slug.SUPPLIER_MATRIX,
      matchRoutes: '^/supplier(/d*)?',
      permission: []
    },
    {
      name: 'Quality Reports',
      icon: QualityReportInactive,
      active_icon: QualityReportActive,
      component: <QualityReportsApp />,
      header: () => <QualityReportHeader />,
      path: 'qualityreports/*',
      slug: Slug.QUALITY_REPORTS,
      matchRoutes: '^/qualityreports(/d*)?',
      permission: []
    },
    {
      name: 'Inventory',
      icon: InactiveRawMaterialIcon,
      active_icon: ActiveRawMaterialIcon,
      component: <RawMaterialApp />,
      header: () => <InventoryHeader />,
      path: 'inventory/*',
      slug: Slug.ITEM_INVENTORY,
      matchRoutes: '^/inventory(/d*)?',
      permission: []
    },
    {
      name: 'Control Plan',
      icon: ControlPlanInactiveIcon,
      active_icon: ControlPlanActiveIcon,
      component: <ControlPlanApp />,
      header: () => <ControlPlanHeader />,
      path: 'control/*',
      slug: Slug.CONTROL_PLAN,
      matchRoutes: '^/control(/d*)?',
      permission: []
    },
  ]
};
