import initialState from '../initial-state';
import { TEST_REPORT_ACTION_TYPE } from './testActionTypes';

export function testReportList(state = initialState.testReportList, action) {
  switch (action.type) {
    case TEST_REPORT_ACTION_TYPE.GET_TEST_REPORT_LIST_SUCCESS:
      return action.testReportList;
    case TEST_REPORT_ACTION_TYPE.UPDATE_TEST_REPORT_LIST_SUCCESS:
      return state.map(test => {
        if (test.id === action.updatedTest.id) {
          return {
            ...test,
            ...action.updatedTest
          };
        }
        return test;
      });
    default:
      return state;
  }
}
export function testReportDetail(
  state = initialState.testReportDetail,
  action
) {
  switch (action.type) {
    case TEST_REPORT_ACTION_TYPE.GET_TEST_REPORT_DETAIL_SUCCESS:
      return action.testReportDetail;
    default:
      return state;
  }
}

export function pendingTestCount(
  state = initialState.pendingTestCount,
  action
) {
  switch (action.type) {
    case TEST_REPORT_ACTION_TYPE.GET_TEST_REPORT_PENDING_TEST_COUNT:
      return action.pendingTestCount;
    default:
      return state;
  }
}

export function testReportHeaderStat(
  state = initialState.testReportHeaderStat,
  action
) {
  switch (action.type) {
    case TEST_REPORT_ACTION_TYPE.GET_TEST_REPORT_HEADER_STAT:
      return action.headerStat;
    default:
      return state;
  }
}
