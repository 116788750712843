import React, { lazy } from 'react';
import { Slug } from './permissionConstants';
import {
  ActiveDashBoardIcon,
  ActiveOrdersIcon,
  ActiveRaiseRFQIcon,
  DashboardIcon,
  OrdersIcon,
  RaiseRFQIcon
} from '../../assets/icons/modifiedIcons';
import LoadLazyComponent from '../../components/LoadLazyComponent';

const RFQForm = lazy(() =>
  LoadLazyComponent(() => import('../../pages/RFQ/RFQ'))
);

const ExPriceComponent = lazy(() =>
  LoadLazyComponent(() => import('../../componentsV2/ChargeMix/ExPriceTable'))
);

const OrderApp = lazy(() =>
  LoadLazyComponent(() => import('../../pages/OrderApp/OrderApp'))
);

const OrderForm = lazy(() =>
  LoadLazyComponent(() => import('../../pages/AddOrder'))
);

export const Procurement_route = {
  name: 'PROCUREMENT',
  can_view: [Slug.RFQ, Slug.ORDER],
  child_routes: [
    {
      name: 'Pricing and Rfq',
      icon: DashboardIcon,
      active_icon: ActiveDashBoardIcon,
      component: <ExPriceComponent />,
      permission: [],
      header: () => 'Pricing & RFQ',
      path: '/pricing',
      matchRoutes: '^/pricing$',
      slug: Slug.RFQ
    },
    {
      slug: Slug.RFQ,
      component: <RFQForm />,
      is_superuser: true,
      permission: [],
      name: 'Raise RFQ',
      header: () => 'Raise RFQ',
      path: '/quote',
      matchRoutes: '^/quote$',
      icon: RaiseRFQIcon,
      active_icon: ActiveRaiseRFQIcon
    },
    {
      name: 'Orders',
      icon: OrdersIcon,
      active_icon: ActiveOrdersIcon,
      component: <OrderApp />,
      header: () => 'Orders',
      permission: [],
      slug: Slug.ORDER,
      path: 'orders/*',
      matchRoutes: '^/orders(?:/.*)?$'
    },
    {
      name: 'Add Order',
      icon: OrdersIcon,
      active_icon: ActiveOrdersIcon,
      component: <OrderForm />,
      header: () => 'Add Order',
      path: '/add-order',
      permission: [],
      slug: Slug.ORDER,
      is_superuser: true,
      is_staff: true,
      matchRoutes: '^/add-order$'
    }
  ]
};
