import React from 'react';
import StretchContainer from '../../../General/StretchContainer';
import styles from './ListingHeaderLoader.module.scss';
import { Skeleton } from '@mui/material';

const ListingHeaderLoader = () => {
  return (
    <StretchContainer
      sx={{ flexWrap: 'wrap', marginBottom: 10, gap: 10, paddingBottom: 11 }}
      noPadding
    >
      <div className={styles.filterBoxes}>
        <Skeleton
          animation='wave'
          height={72}
          width={180}
          component={'div'}
          variant='rectangular'
        />
        <Skeleton
          animation='wave'
          height={72}
          width={180}
          component={'div'}
          variant='rectangular'
        />
        <Skeleton
          animation='wave'
          height={72}
          width={180}
          component={'div'}
          variant='rectangular'
        />
      </div>
      <div className={styles.filterBoxes}>
        <Skeleton
          animation='wave'
          height={42}
          width={200}
          component={'div'}
          variant='rectangular'
        />
        <Skeleton
          animation='wave'
          height={42}
          width={42}
          component={'div'}
          variant='rectangular'
        />
        <Skeleton
          animation='wave'
          height={42}
          width={42}
          component={'div'}
          variant='rectangular'
        />
      </div>
    </StretchContainer>
  );
};

export default ListingHeaderLoader;
