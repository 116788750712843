import * as chargeMixApi from '../../api/chargeMixApi';
import { ANALYTICS_EVENTS } from '../../analytics/analyticsEvents';

import { createFromDefaultAction } from '../actionUtils';
import {
  FETCH_CHARGEMIX,
  FETCH_ALL_CHARGEMIX,
  FETCH_CHARGEMIX_ELEMENTS,
  ADD_CHARGEMIX,
  UPDATE_CHARGEMIX,
  CLONE_CHARGEMIX,
  GET_INVENTORY_ITEM_SUGGESTION,
  DEMO_MODE,
  DRAFT_CHARGEMIX_CHANGES,
  CHARGEMIX_OVERVIEW,
  FETCH_CHARGEMIX_LIST,
  FETCH_CHARGEMIX_SUGGESTIONS
} from '../actionTypes';
import { actionClearType, actionSuccessType } from '../actionUtils/actionTypes';
import { GET_CM_RR } from '../../constants/ChargeMix/common';
import { COMMON_ACTION_TYPES } from '../commonActionTypes';

const { BASIC } = COMMON_ACTION_TYPES;

export function getChargeMix(id) {
  return createFromDefaultAction(FETCH_CHARGEMIX, () =>
    chargeMixApi.getChargeMix(id)
  );
}

export function clearChargeMix() {
  return { type: actionClearType(FETCH_CHARGEMIX) };
}

export function getAllChargeMix(isAdminView) {
  return createFromDefaultAction(FETCH_ALL_CHARGEMIX, () =>
    chargeMixApi.getAllChargeMix(isAdminView)
  );
}

export function clearChargeMixList() {
  return { type: actionClearType(FETCH_ALL_CHARGEMIX) };
}

export function getChargeMixElements() {
  return createFromDefaultAction(FETCH_CHARGEMIX_ELEMENTS, () =>
    chargeMixApi.getChargeMixElements()
  );
}

export function clearChargeMixElements() {
  return { type: actionClearType(FETCH_CHARGEMIX_ELEMENTS) };
}

export function addChargeMixV2(data) {
  return createFromDefaultAction(
    ADD_CHARGEMIX,
    () => chargeMixApi.addChargeMixV2(data),
    {
      afterSuccessCall: {
        $actionMeta: {
          type: actionSuccessType(FETCH_CHARGEMIX),
          modificationType: 'replaceData',
          thisData: state => state.chargeMix.read // Better approach -> Use state selectors
        }
      }
    }
  );
}

export function createCMInventory(data) {
  return createFromDefaultAction(
    ADD_CHARGEMIX,
    () => chargeMixApi.createCMInventory(data),
    {
      afterSuccessCall: {
        $actionMeta: {
          type: actionSuccessType(FETCH_CHARGEMIX),
          modificationType: 'replaceData',
          thisData: state => state.chargeMix.read // Better approach -> Use state selectors
        }
      }
    }
  );
}
export function updateCMInventory(data) {
  return createFromDefaultAction(
    UPDATE_CHARGEMIX,
    () => chargeMixApi.updateCMInventory(data),
    {
      afterSuccessCall: {
        $actionMeta: {
          type: actionSuccessType(FETCH_CHARGEMIX),
          modificationType: 'replaceData',
          thisData: state => state.chargeMix.read, // Better approach -> Use state selectors
          enhancedPayload: (payload, thisData) => {
            return payload;
          }
        }
      }
    }
  );
}

export function updateChargeMixV2(step, id, data) {
  return createFromDefaultAction(
    UPDATE_CHARGEMIX,
    () => chargeMixApi.updateChargeMixV2(step, id, data),
    {
      afterSuccessCall: {
        $actionMeta: {
          type: actionSuccessType(FETCH_CHARGEMIX),
          modificationType: 'replaceData',
          thisData: state => state.chargeMix.read,
          enhancedPayload: (payload, thisData) => {
            if (step === BASIC && thisData.cm_items?.length > 0) {
              payload.cm_items = thisData.cm_items;
            }

            return payload;
          }
        }
      }
    }
  );
}

export function getInventoryItemSuggestion(cm_data) {
  const cm_element_Ids = cm_data?.cm_target_chemistry.map(ele => ele.element);

  return createFromDefaultAction(
    GET_INVENTORY_ITEM_SUGGESTION,
    () => chargeMixApi.filterInventoryItem(cm_data.grade_id),
    {
      afterSuccessCall: {
        $actionMeta: {
          type: actionSuccessType(FETCH_CHARGEMIX),
          modificationType: 'replaceData',
          thisData: state => state.chargeMix.read,
          enhancedPayload: (payload, thisData) => {
            const { cm_rr } = GET_CM_RR(cm_data.is_composition);

            const itemSuggestion = payload.map(item => ({
              ...item,
              item_name: item.name,
              cm_type: item.cm_type,
              metal_rr: item.material_rr,
              min_qty: item.grade_min_qty,
              max_qty: item.grade_max_qty,
              [cm_rr]: item.item_rr
                .filter(ele => cm_element_Ids.indexOf(ele.element) > -1)
                .map(rr => ({
                  element: rr.element,
                  rate: rr.composition,
                  min_rr: rr.min_rr,
                  max_rr: rr.max_rr
                }))
            }));

            return { ...thisData, cm_items: itemSuggestion };
          }
        }
      }
    }
  );
}

export function getItemInventory(query) {
  return function (dispatch) {
    return chargeMixApi
      .getInventoryItems()
      .then(data => {
        dispatch(searchItemInventorySuccess(data));
        return data;
      })
      .catch(error => {
        throw error;
      });
  };
}

export function cloneChargeMix(data, chargeMix) {
  return createFromDefaultAction(
    CLONE_CHARGEMIX,
    () => chargeMixApi.CloneChargeMix(data),
    {
      analytics: {
        $amplitude:
          ANALYTICS_EVENTS.chargeMix.cloneChargeMixAmplitudeEvents(chargeMix),
        $ga: ANALYTICS_EVENTS.chargeMix.cloneChargeMixGaEvents(chargeMix)
      }
    },
    { successMsg: 'Your chargeMix was Cloned' }
  );
}

export function archiveChargeMix(data, chargeMix) {
  return createFromDefaultAction(
    CLONE_CHARGEMIX,
    () => chargeMixApi.ArchiveChargeMix(data),
    {
      afterSuccessCall: {
        $actionMeta: {
          type: actionSuccessType(FETCH_ALL_CHARGEMIX),
          modificationType: 'addData',
          thisData: state => state.chargeMix.all // Better approach -> Use state selectors
        }
      }
    },
    { successMsg: 'Your chargeMix was Archived' }
  );
}

export function updateDemoMode(data) {
  return createFromDefaultAction(DEMO_MODE, () => data, {
    afterSuccessCall: {
      $actionMeta: {
        type: actionSuccessType(FETCH_CHARGEMIX),
        modificationType: 'replaceData',
        thisData: state => state.chargeMix.read
      }
    }
  });
}

export function clearChargeMixDemoMode() {
  return { type: actionClearType(DEMO_MODE) };
}

export function draftChargeMixChanges(data) {
  return createFromDefaultAction(DRAFT_CHARGEMIX_CHANGES, () => data, {
    afterSuccessCall: {
      $actionMeta: {
        type: actionSuccessType(DRAFT_CHARGEMIX_CHANGES),
        modificationType: 'replaceData',
        thisData: state => state.chargeMix.draft_cm_data,
        enhancedPayload: (payload, thisData) => {
          return { ...thisData, ...payload };
        }
      }
    }
  });
}

export function getChargeMixOverview() {
  return createFromDefaultAction(CHARGEMIX_OVERVIEW, () =>
    chargeMixApi.getChargeMixOverview()
  );
}

export function getChargeMixList() {
  return createFromDefaultAction(FETCH_CHARGEMIX_LIST, () =>
    chargeMixApi.getChargeMixList()
  );
}

export function getChargemixSuggestion(cm_id) {
  return createFromDefaultAction(FETCH_CHARGEMIX_SUGGESTIONS, () =>
    chargeMixApi.getChargemixSuggestion(cm_id)
  );
}
