export function isDeepEqual(a, b, { checkArrOrder } = { checkArrOrder: true }) {
  if (typeof a !== typeof b) return false;

  if (a === null || b === null) {
    return a === b;
  }

  if (typeof a !== 'object') {
    return isSamePrimitiveValue(a, b);
  }

  if (Array.isArray(a) || Array.isArray(b)) {
    return compareArray(a, b, { checkArrOrder });
  }

  return isSameObject(a, b);
}

function isSamePrimitiveValue(a, b) {
  if (a === b) return true;

  if (typeof a === 'number' && typeof b === 'number' && isNaN(a) && isNaN(b))
    return true;

  return false;
}

function isSameObject(obj1, obj2) {
  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  for (let key in obj1) {
    const result = isDeepEqual(obj1[key], obj2[key]);
    if (!result) return false;
  }

  return true;
}

function compareArray(arr1, arr2, { checkArrOrder }) {
  if (arr1.length !== arr2.length) return false;

  if (checkArrOrder) return compareArrByOrder(arr1, arr2);

  return compareArrWithoutOrder(arr1, arr2);
}

function compareArrByOrder(arr1, arr2) {
  for (let i in arr1) {
    const result = isDeepEqual(arr1[i], arr2[i], { checkArrOrder: true });
    if (!result) return false;
  }

  return true;
}

function compareArrWithoutOrder(arr1, arr2) {
  let arr1Map = arr1.reduce((accumulator, currentVal, index) => {
    accumulator[currentVal] = accumulator[currentVal]
      ? [...accumulator[currentVal], index]
      : [index];
    return accumulator;
  }, {});

  for (let j in arr2) {
    if (arr1Map[j]) {
      let lastIdx = arr1Map[j].length - 1;

      if (isDeepEqual(arr1[arr1Map[j][lastIdx]], j, { checkArrOrder: false })) {
        arr1Map[j].pop();
        continue;
      }
    }

    return false;
  }

  return true;
}
