import React, { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import styles from './DebounceInputSearch.module.scss';
import clsx from 'clsx';
import { sendAnalytics } from '../../utils/analyticsUtils';
import { SearchIcon } from '../../assets/icons/modifiedIcons';
import { useSelector } from 'react-redux';

const DebouceInputSearch = ({
  handleChange,
  query,
  sx = {},
  inputStyles = {},
  eventName,
  eventPrefix = '',
  eventDescription
}) => {
  const [searchActive, setSearchActive] = useState();

  const { analyticsKey } = useSelector(state => state);

  useEffect(() => {
    return () => {
      debounceFun.cancel();
    };
  });

  const debounceFun = debounce(function (e) {
    handleChange(e);
  }, 500);
  return (
    <div className={styles.inputHeader} style={sx}>
      <div
        className={clsx(styles.form, searchActive ? styles.searchActive : null)}
        style={inputStyles}
      >
        <input
          type='search'
          style={{ borderRadius: '8px' }}
          onFocus={e => {
            setSearchActive(true);
            const analytics_Details = {
              defaultName:
                eventName || `${analyticsKey || 'RAW_MATERIAL'}_Search`,
              defaultDetails:
                eventDescription ||
                `${analyticsKey} search clicked with initial value of ${e.target.value}`
            };

            sendAnalytics(analytics_Details);
          }}
          onBlur={e => {
            setSearchActive(false);
            const analytics_Details = {
              defaultName:
                eventName || `${analyticsKey || 'RAW_MATERIAL'}_Search`,
              defaultDetails:
                eventDescription ||
                `${analyticsKey}  search changed to ${e.target.value}`
            };
            sendAnalytics(analytics_Details);
          }}
          defaultValue={query.search}
          onChange={e => debounceFun(e.target.value)}
          placeholder='Search'
          aria-label='Search through site content'
        />
        <button>
          <SearchIcon />
        </button>
      </div>
    </div>
  );
};

export default DebouceInputSearch;
