import * as amplitude from '@amplitude/analytics-browser';
import config from '../config/config';
import { isProduction } from '.';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const internalUser = ['NowPurchase', 'Demo Account'];

/**
 * Checks if the user is an internal user, or if the user's IP address matches a specific value.
 *
 * @async
 * @param {object} userDetail - The details of the user.
 * @returns {Promise<Boolean>} True if the user is internal or the IP matches, False otherwise.
 */
const isInternalUser = async userDetail => {
  return (
    internalUser.includes(userDetail?.customer?.company_name) ||
    userDetail.is_staff
  );
};

/**
 * Logs a custom event to Amplitude, if the application is in production mode.
 *
 * @async
 * @param {string} event_name - The name of the event.
 * @param {object} event_props - The properties of the event.
 * @returns {Promise} The promise returned by the 'amplitude-js' logging function.
 */
export async function Add_Amplitude_Event_Analytics(event_name, event_props) {
  if (isProduction()) {
    const sessionReplayProperties = sessionReplayPlugin();
    amplitude.add(sessionReplayProperties);
    amplitude.track(event_name, {
      ...sessionReplayProperties
    });
  }
}

/**
 * Initializes Amplitude with certain options, if the application is in production mode.
 *
 * @async
 * @returns {Promise} The promise returned by the 'amplitude-js' initialization function.
 */
export async function Initialize_Amplitude(userDetail) {
  const checkInternalUser = await isInternalUser(userDetail);
  if (isProduction() && !checkInternalUser) {
    amplitude.init(`${config.AMPLITUDE_KEY}`, null, {
      defaultTracking: false,
      plugins: [
        sessionReplayPlugin({
          sampleRate: 1
        })
      ]
    });
  }
}

/**
 * Sets the User ID in Amplitude to a specific format, if the application is in production mode
 * and the user is not an internal user.
 *
 * @async
 * @param {object} userDetail - The details of the user.
 * @returns {Promise} The promise returned by the 'amplitude-js' setUserId function.
 */
export async function Set_UserId_Amplitude(userDetail) {
  if (isProduction()) {
    if (Object.keys(userDetail).length > 0) {
      const UserName = `${
        userDetail.customer?.company_name.substring(0, 6) +
        '_' +
        userDetail.name?.substring(0, 6) +
        '_' +
        userDetail.id
      }`;
      amplitude.setUserId(UserName);
    }
  }
}
