export const SPECTRO_CONSTS = {
  DEFAULT_PAGE_SIZE: 50,
  DEFAULT_PAGE_NUM: 1,
  DEFAULT_SEARCH_VAL: '',
  DEFAULT_SAMPLE_TYPE: '',
  DEFAULT_GRADE_TYPE: '',
  DEFAULT_OVERVIEW_FILTER_GRADE: 'All Grades',
  MODAL_WEB_ELEMENT_COUNTS: 10,
  MODAL_MOBILE_ELEMENT_COUNTS: 4,
  GRADE_TYPE_NULL: 'NULL',
  GRADE_TYPE_FINAL: 'Final',
  GRADE_TYPE_BATH: 'Bath',
  GRADE_TYPE_ADDITION: 'Addition',
  GRADE_TYPE_LADLE: 'Ladle',
  GRADE_TYPE_PIT: 'Pit',
  START_DATE: 'start',
  END_DATE: 'end'
};

export const badgeInitialData = [
  'heat_date__lte',
  'heat_date__gte',
  'readings__sample_type',
  'grade__grade_code',
  'grade__part_name',
  'grade',
  'cm'
];

export const filterBox = [
  {
    text: 'Total Readings',
    color: '#1579BE',
    value: 'ALL',
    key: 'count'
  },
  {
    text: 'Tagged Readings',
    color: '#2BA24C',
    value: 'true',
    key: 'tagged_count'
  },
  {
    text: 'Untagged Readings',
    color: '#E43E2B',
    value: 'false',
    key: 'untagged_count'
  }
];
