import { useSearchParams, useLocation } from 'react-router-dom';
import React from 'react';

const useQuery = () => {
  const location = useLocation();

  const { search } = location.state?.backgroundLocation ?? location;

  const searchParams = new URLSearchParams(search);
  const [_, setSearchParams] = useSearchParams(search);

  const query = {};

  for (const [key, value] of searchParams.entries()) {
    query[key] = value.length && value == Number(value) ? Number(value) : value;
  }

  const setQuery = queryObj => {
    let newQueryObj = {};
    for (const x in queryObj) {
      if (queryObj[x] && queryObj[x].toString().length){
          newQueryObj[x] = queryObj[x];
      }
    }
    // Reset the page property to 1 if the search property changes
    if ( queryObj.search && queryObj.search !== query.search ) {
        const { page, ...rest } = newQueryObj;
        newQueryObj = { ...rest }
    }
    setSearchParams(newQueryObj);
  };

  return [query, setQuery];
};

export default useQuery;
