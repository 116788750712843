import { addDays, format, startOfMonth, startOfWeek, subDays } from 'date-fns';

export const heatsFilterBoxData = [
  {
    text: 'Total Heats',
    color: '#1579BE',
    value: 'total'
  },
  {
    text: 'Pending for Review',
    color: '#E43E2B',
    value: 'pending'
  },
  {
    text: 'Draft',
    color: '#E39600',
    value: 'draft'
  },
  {
    text: 'Completed',
    color: '#2BA24C',
    value: 'submitted'
  }
];
export const YESTERDAY = 'Yesterday';
export const TODAY = 'Today';
export const TOTAL = 'total';
export const DRAFT = 'draft';
export const COMPLETED = 'submitted';
export const CUSTOM = 'Custom';
export const colour_filter = [
  '#1579BE',
  '#9636E1',
  '#009797',
  '#D58C00',
  '#E43E2B',
  '#177C70'
];

export const SCRAP_TO_LM = 'Scrap To LM%';

export const furnaceUtilisationBarColour = {
  sum_idle_time: '#D58C00',
  sum_hold_time: '#E43E2B'
};

export const tickStyles = { fontSize: 12, fill: '#909090', fontWeight: 400 };

export const xAxisLabelStyles = {
  fontSize: 12,
  fill: '#909090',
  fontWeight: 700,
  position: 'center',
  dy: 15
};

export const yAxisLabelStyles = {
  fontSize: 12,
  fill: '#909090',
  fontWeight: 700,
  angle: -90,
  position: 'center',
  dx: -20 // Adjust the horizontal position
};

export const customDotStyle = {
  radius: 12,
  stroke: 'white',
  strokeWidth: 1,
  fillOpacity: 1
};

export const ChartDataType = {
  DATE_WISE: 'DATE_WISE',
  HEAT_WISE: 'HEAT_WISE',
  FURNACE_WISE: 'FURNACE_WISE'
};
export const getCalenderTabs = (handleQueryChange, setIsCalenderOpen) => [
  {
    title: 'Yesterday',
    action: () =>
      handleQueryChange({
        title: 'Yesterday',
        startDate: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        queries: { show_all: '' }
      })
  },
  {
    title: 'This Week',
    action: () =>
      handleQueryChange({
        title: 'This Week',
        startDate: format(
          startOfWeek(new Date(), { weekStartsOn: 1 }),
          'yyyy-MM-dd'
        ),
        endDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        queries: { show_all: '' }
      })
  },
  {
    title: 'This Month',
    action: () =>
      handleQueryChange({
        title: 'This Month',
        startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
        endDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
        queries: { show_all: '' }
      })
  },
  {
    title: 'All Time',
    action: () =>
      handleQueryChange({
        title: 'All Time',
        startDate: '',
        endDate: '',
        queries: { show_all: 1 }
      })
  },
  {
    title: 'Custom',
    action: () => setIsCalenderOpen(prevState => !prevState)
  }
];
