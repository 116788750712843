export const CHARGEMIX_COMMON_CONSTS = {
  PRODUCT: 'Product',
  QTY: 'User Qty',
  KG: '(kg)',
  RUPEE: '(₹)',
  RATE: 'Rate/kg',
  YIELD: '(Yield)',
  MIN: 'Min',
  MAX: 'Max',
  STOCK: 'Stock (kg)',
  ALL: 'ALL ITEMS',
  METAL_RR: 'Metal Recovery Rate',
  METAL_RR_YIELD: 'Metal Recovery Rate / Yield',
  TO_FIXED_CONST: 4,
  TO_FIXED_CHEM_CONST: 3,
  QTY_RANGE_INDEX_FINAL_RECOVERY: 7,
  QTY_RANGE_INDEX_FINAL: 6,
  WARNING_AFTER_DAYS: 10,
  TC_START_INDEX: 3,
  RR_TC_START_INDEX: 4,
  TARGET_CHEMISTRY: 'Target Chemistry',
  FINAL_CHEMISTRY: 'User Final Calculations',
  BATH_CHEMISTRY: 'User Bath Calculations',
  NODULARIZATION: 'Nodularization',
  ADDITIVES_TITLE: 'Additives',
  FURNACE_TITLE: 'Furnace (Kg)',
  LADLE_TITLE: 'Ladle',
  SUGGESTED_QTY: 'Suggested Qty',
  LIQUID_METAL_WT: 'Liquid Metal WT',
  LIQUID_METAL_WT_VAR_NAME: 's_heel',
  LIQUID_METAL: 'Liquid Metal',
  USER_QTY: 'User Qty',
  USER_CALC: 'User Calculations',
  SUGGESTED_CALC: 'Suggested Calculations',
  CE_VALUE: 'CE Value',
  SUGGESTED_ITEM_TITLE: 'New Suggested Raw Material'
};

export const GET_CM_RR = (isComposition, is_stock) =>
  isComposition
    ? {
        cm_rr: 'cm_item_composition',
        cm_rr_head: 'Composition',
        bathColStartIdx: is_stock ? 5 : 4
      }
    : {
        cm_rr: 'cm_recovery_rate',
        cm_rr_head: 'Element Recovery Rate',
        bathColStartIdx: is_stock ? 4 : 3
      };
