import initialState from '../initial-state';
import { HEAT_LOG_ACTION_TYPES } from './heatLogActionTypes';

export const heatLogDetail = (state = initialState.heatLogDetail, action) => {
  switch (action.type) {
    case HEAT_LOG_ACTION_TYPES.SET_HEAT_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case HEAT_LOG_ACTION_TYPES.RESET_HEAT_DETAILS:
      return {};

    default:
      return state;
  }
};
