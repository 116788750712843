import React, { useState, useRef } from 'react';
import { Button, Drawer } from '@mui/material';
import { PaginationWrapper } from '../../PaginationWrapper';
import classes from './SpectroListingTable.module.scss';
import SpectroHeader from './SpectroHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import RenderConditionally from '../../RenderConditionally';
import FilterDrawer from './FilterDrawer';
import useQuery from '../../../hooks/useQuery';
import StretchContainer from '../../General/StretchContainer';
import { format, isToday, isYesterday, parseISO } from 'date-fns';
import { ElementBoxes } from '.';
import { is8601_to_readable_datetime } from '../../../utils';

export const getDateSpectro = data => {
  if (!data) return ''; // handle null or undefined

  const date = parseISO(data);
  let displayDate;

  if (isToday(date)) {
    displayDate = `Today - ${format(date, 'hh:mm a')}`;
  } else if (isYesterday(date)) {
    displayDate = `Yesterday - ${format(date, 'hh:mm a')}`;
  } else {
    const readableDate = is8601_to_readable_datetime(data);
    if (readableDate) {
      displayDate = `${readableDate.join(' - ')}`;
    } else {
      return ''; // Handle the scenario where is8601_to_readable_datetime returns null or undefined
    }
  }

  return displayDate;
};

const MobileSpectroReading = ({ data, tc }) => {
  const readingRef = useRef();
  return (
    <div>
      {data.map((d, i) => {
        return (
          <div key={i} style={{ paddingBottom: '0.75rem' }}>
            <div className={classes.spectroReadingHeader}>
              <div
                className={classes.spectroReadingTag}
                style={
                  d.sample_type == 'Bath'
                    ? { color: '#009797', background: '#0097971a' }
                    : d.sample_type == 'Final'
                    ? { color: '#9636E1', background: '#9636E11a' }
                    : { opacity: 0 }
                }
              >
                {d.sample_type}
              </div>
              <div className={classes.spectroReadingDate}>
                {getDateSpectro(d.reading_created_at)}
              </div>
            </div>
            <ElementBoxes ref={readingRef} elements={d.reading_avg} tc={tc} />
          </div>
        );
      })}
    </div>
  );
};

export default function MobileSpectroList({
  spectrometerList,
  isLoading,
  limit,
  count,
  tableRef,
  muiStyles,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [filterOpen, setFilterOpen] = useState(false);
  const [query, setQuery] = useQuery();

  return (
    <div ref={tableRef}>
      <RenderConditionally condition={!isLoading && !spectrometerList?.length}>
        <div className={classes.spectroCard}>
          <div className={classes.noReadingsFound}>No Readings Found</div>
        </div>
      </RenderConditionally>

      <Drawer
        anchor='right'
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        classes={{ paper: muiStyles.drawerPaper }}
      >
        <FilterDrawer
          handleClose={() => setFilterOpen(false)}
          setQuery={setQuery}
          query={query}
        />
      </Drawer>

      <div>
        <SpectroHeader setFilterOpen={setFilterOpen} />
        <StretchContainer sx={{ display: 'block' }} noPadding>
          {spectrometerList?.map((spectroo, i) => {
            return (
              <div
                style={
                  !(i % 2)
                    ? { backgroundColor: '#fff' }
                    : { backgroundColor: '#f5f5f5' }
                }
                key={i}
              >
                <div className={classes.cmListGrid}>
                  <div className={classes.mobInnerSection}>
                    <div className={classes.mobTitle}>CM ID</div>
                    <div className={classes.innerText}>
                      {spectroo.cm_data.cm_tag_id || '-'}
                    </div>
                  </div>
                  <div className={classes.mobInnerSection}>
                    <div className={classes.mobTitle}>Charge Mix Name</div>
                    <div className={classes.innerText}>
                      {spectroo.cm_data.cm_name || '-'}
                    </div>
                  </div>
                  <div className={classes.mobInnerSection}>
                    <div className={classes.mobTitle}>Heat No.</div>
                    <div className={classes.innerText}>
                      {spectroo.heat_name}
                    </div>
                  </div>
                  <div className={classes.mobInnerSection}>
                    <div className={classes.mobTitle}>Grade / Code</div>
                    <div className={classes.innerText}>
                      {spectroo.grade_name}
                    </div>
                  </div>
                  <div className={classes.mobInnerSection}>
                    <div className={classes.mobTitle}>Part Name</div>
                    <div className={classes.innerText}>
                      {spectroo.cm_data.part_name || '-'}
                    </div>
                  </div>
                  <div className={classes.mobInnerSection}>
                    <div className={classes.mobTitle}>Part No.</div>
                    <div className={classes.innerText}></div>
                  </div>
                </div>
                <div className={classes.spectroReadingGrid}>
                  <div className={classes.spectroReadingInner}>
                    <div className={classes.mobTitle}>
                      Spectrometer Readings
                    </div>
                    <MobileSpectroReading
                      data={spectroo.readings}
                      tc={spectroo.cm_data.cm_target_chemistry}
                    />
                  </div>
                </div>
                <div className={classes.spectroReadingGrid2}>
                  <Button
                    variant='contained'
                    onClick={() => navigate(`${spectroo.id}`)}
                    sx={{
                      background: '#E8F2F8',
                      borderRadius: '0.25rem',
                      border: '1px solid #1579b3',
                      boxShadow: 'none',
                      margin: '1.5rem 0',
                      textTransform: 'capitalize'
                    }}
                  >
                    <div className={classes.btnContainer}>
                      <span>View</span>
                      <i className='ri-arrow-right-line'></i>
                    </div>
                  </Button>
                </div>
              </div>
            );
          })}
          <PaginationWrapper limit={limit} count={count} />
        </StretchContainer>
      </div>
    </div>
  );
}
