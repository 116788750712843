import { INVENTORY_ACTION_TYPES } from './inventoryActionTypes';
import initialState from '../initial-state';

export default function inventory(state = initialState.inventory, action) {
  switch (action.type) {
    case INVENTORY_ACTION_TYPES.GET_INVENTORY_SUCCESS:
    case INVENTORY_ACTION_TYPES.ADD_INVENTORY_SUCCESS:
      return action.inventory;
    default:
      return state;
  }
}
