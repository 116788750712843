import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RightArrowIcon } from '../../../assets/icons/modifiedIcons';
import { isMobile } from 'react-device-detect';
import { goBack, pathMatch } from '../../../utils';
import classes from './HeaderLayout.module.scss';
import { Breadcrumb } from './GradeHeader';

/**
 * Component to display the Control Plan details in a breadcrumb-like format.
 *
 * @param {object} props - Component props.
 * @param {object} props.label - The details of the plan to be displayed.
 * @returns {JSX.Element} A div element representing the control plan detail display.
 */
const ControlPlanDetailDisplay = ({ label }) => (
  <div className={classes.breadCrumbContainer}>
    <span>
      <RightArrowIcon fontSize={16} />
    </span>
    <div className={classes.controlPlanLabel}>{label}</div>
  </div>
);

/**
 * Header component for the Control Plan section, including navigation and Control Plan detail display.
 * Uses redux to manage state and react-router-dom for navigation.
 *
 * @returns {JSX.Element} The header component with conditional rendering based on the current URL path.
 */
const ControlPlanHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentStep = useSelector(state => state.addControlPlanCurrentStep);
  const controlPlanDetail = useSelector(state => state.addControlPlanData);

  const isAddControlPath = pathMatch('/control', '/add', location.pathname);
  const isControlUpdatePath = pathMatch('/control', '/\\d+', location.pathname);

  const getLabel = () => {
    if (isAddControlPath) {
      return `Add New Plan - ${currentStep.split('_').join(' ')}`;
    }
    if (isControlUpdatePath) {
      return `RM${controlPlanDetail.tag_id}`;
    }
    return null;
  };

  if (!isAddControlPath && !isControlUpdatePath) {
    return <span className={classes.title}>Control Plan</span>;
  }

  return (
    <div className={classes.headerDiv}>
      <ArrowBackIcon
        className={classes.backActionIcon}
        onClick={() => goBack(navigate, location)}
      />
      <Breadcrumb navigate={navigate} text='Control Plan' path='/control' />

      {(isAddControlPath ||
        (isControlUpdatePath &&
          Object.keys(controlPlanDetail || {}).length > 0 &&
          !isMobile)) && (
        <ControlPlanDetailDisplay
          controlPlanDetail={controlPlanDetail}
          label={getLabel()}
        />
      )}
    </div>
  );
};

export default ControlPlanHeader;
