import { login, sendOTP, signup } from '../../api/LoginApi';
import { apiCallError, beginApiCall } from '../apiStatus/apiStatusAction';
import { AUTH_ACTION_TYPES } from './authActionTypes';

export function sendOtpSuccess(mobileno) {
  return {
    type: AUTH_ACTION_TYPES.SEND_OTP_SUCCESS,
    mobileno
  };
}

export function loginSuccess(token) {
  return {
    type: AUTH_ACTION_TYPES.LOGIN_SUCCESS,
    token
  };
}

function logoutAction() {
  return {
    type: AUTH_ACTION_TYPES.LOGOUT_APP
  };
}

/**
 * Action Creator. Returns an action of the type 'SIGNUP_REQUEST_SUCCESS'
 */
export function requestSignUpSuccess(user) {
  return {
    type: AUTH_ACTION_TYPES.SIGNUP_REQUEST_SUCCESS,
    user
  };
}

export function sendOTPAction(contact) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return sendOTP(contact)
      .then(result => {
        dispatch(sendOtpSuccess(contact));
      })
      .catch(error => {
        dispatch(apiCallError());
        throw error;
      });
  };
}

export function loginAction(otp) {
  return function (dispatch, getState) {
    const {
      auth: { mobileno }
    } = getState();
    dispatch(beginApiCall());
    return login(mobileno, otp)
      .then(({ token }) => {
        dispatch(loginSuccess(token));
      })
      .catch(error => {
        dispatch(apiCallError());
        throw error;
      });
  };
}

/**
 * This function is used to send signup request response or login Failed response to the client
 * @param {Object} userDetail when user requested for Signup
 * @param {string} userDetail when user login failed ,basically contains contact no.
 * @param {string} type indicates if type is signup request or login failed
 * returns the response of api
 */
export function signUpAction(userDetail, type) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return signup(userDetail, type)
      .then(user => {
        dispatch(requestSignUpSuccess(user));
      })
      .catch(error => {
        dispatch(apiCallError());
        throw error;
      });
  };
}

/**
 * This function is used to send login Failed response to the client
 * @param {string} userDetail when user login failed ,basically contains contact no.
 * @param {string} type indicates if type is signup request or login failed
 * returns the response of api
 */
export function loginFailAction(userDetail, type) {
  return function () {
    return signup(userDetail, type).catch(error => {
      throw error;
    });
  };
}

export function logout(navigate) {
  return function (dispatch) {
    localStorage.clear();
    dispatch(logoutAction());
    navigate('/login');
  };
}
