import { toast } from 'react-toastify';
import { createQueryParam } from '..';
import { cFetch, token } from '../../api/apiUtils';
import config from '../../config/config';
import { downloadData } from '../Spectrometer';
import { ChartDataType } from '../../constants/MeltingSection';

export const formatDateTimeFlat = () => {
  let dateString = new Date().toISOString();
  dateString = dateString.replaceAll(/\D/g, '');
  return dateString;
};

export const getHeatsMeltingData = async params => {
  const queryParam = createQueryParam(params);
  try {
    const response = await cFetch(
      `${config.API_URL}/api/heatlog/list_desktop/?${queryParam}`,
      {
        method: 'GET',
        headers: {
          authorization: token()
        }
      }
    );

    return response;
  } catch (err) {
    console.error(err);
  }
};

export const getHeatsCountByFilter = async params => {
  const queryParam = createQueryParam(params);
  try {
    const response = await cFetch(
      `${config.API_URL}/api/heatlog/list_aggregate/?${queryParam}`,
      {
        method: 'GET',
        headers: {
          authorization: token()
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const getFurnaceDropdownList = async () => {
  try {
    const data = await cFetch(`${config.API_URL}/api/dropdown/furnace/`, {
      method: 'GET',
      headers: {
        authorization: token()
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getShiftDropdownList = async () => {
  try {
    const data = await cFetch(`${config.API_URL}/api/dropdown/shift/`, {
      method: 'GET',
      headers: {
        authorization: token()
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getGradeDropdownList = async () => {
  try {
    const data = await cFetch(`${config.API_URL}/api/dropdown/grade/`, {
      method: 'GET',
      headers: {
        authorization: token()
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getHeatDetailsPreview = async params => {
  try {
    const response = await fetch(
      `${config.API_URL}/api/heatlog/preview/${params}/`,
      {
        method: 'GET',
        headers: {
          authorization: token()
        }
      }
    );

    return response;
  } catch (err) {
    console.error(err);
  }
};

export const acceptHeatReading = async id => {
  const acceptedData = {
    is_accepted: true
  };
  try {
    const response = await fetch(
      `${config.API_URL}/api/heatlog/submit/${id}/`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          authorization: token()
        },
        body: JSON.stringify(acceptedData)
      }
    );

    return response;
  } catch (err) {
    console.error(err);
  }
};

export const getHeatDetails = async id => {
  try {
    const response = await cFetch(
      `${config.API_URL}/api/heatlog/list_desktop/${id}/`,
      {
        method: 'GET',
        headers: {
          authorization: token()
        }
      }
    );
    return response;
  } catch (err) {
    console.error(err);
  }
};

export const downloadHeatReport = query => {
  const queryParam = createQueryParam(query);
  const demo = fetch(`${config.API_URL}/api/heatlog/download/?${queryParam}`, {
    method: 'GET',
    headers: {
      authorization: token(),
      'Content-Type': 'application/json'
    }
  })
    .then(res => {
      if (!res.ok) {
        throw new Error(`HTTP status ${res.status}`);
      }
      return res.text();
    })
    .then(data => {
      if (data) {
        downloadData(data, `heat_report_${formatDateTimeFlat(new Date())}.csv`);
      } else {
        console.error('Received empty data.');
      }
    })
    .catch(error => {
      console.error('Failed to download the file:', error);
      throw error;
    });

  toast.promise(demo, {
    pending: 'Downloading Data',
    success: 'File downloaded successfully',
    error: 'Error downloading'
  });
};

/**
 * Formats a date string into the format "DD(suffix) Month, YYYY".
 * @param {string} dateString - The date string in the format "YYYY-MM-DD".
 * @returns {string} The formatted date string.
 */
export const formatDate = dateString => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  const suffix = day => {
    if (day % 10 === 1 && day !== 11) {
      return 'st';
    } else if (day % 10 === 2 && day !== 12) {
      return 'nd';
    } else if (day % 10 === 3 && day !== 13) {
      return 'rd';
    } else {
      return 'th';
    }
  };

  return `${day}${suffix(day)} ${month}, ${year}`;
};

/**
 * Checks if the provided data contains a date range.
 * @param {Object} data - The data to check.
 * @returns {boolean} True if the data contains a date range, false otherwise.
 */
export const ifReadingHasADateRange = data => {
  if (Array.isArray(data)) {
    return data[0]?.date !== undefined;
  }
  return data?.date_wise !== undefined;
};

/**
 * Formats a date string into 'DD.MM.YYYY' format.
 *
 * @param {string} dateString - The date string to format.
 * @returns {string} - The formatted date string in 'DD.MM.YYYY' format.
 */
export const formatCalenderDate = dateString => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

/**
 * Determines the type of data in the furnace chart data.
 *
 * @param {Object} chartData - The furnace chart data.
 * @returns {string} - The type of data, one of 'date_wise', 'heat_wise', or 'furnace_wise'.
 */
export const getFurnaceChartDataType = chartData => {
  if (chartData?.date_wise !== undefined) {
    return ChartDataType.DATE_WISE;
  } else if (chartData?.heat_wise !== undefined) {
    return ChartDataType.HEAT_WISE;
  } else {
    return ChartDataType.FURNACE_WISE;
  }
};

/**
 * Retrieves the name of a furnace based on the furnace ID.
 * @param {Array} furnaceData - The array of furnace data objects.
 * @param {number|string} furnaceId - The ID of the furnace to find.
 * @returns {Object|null} - The furnace data object that matches the given furnace ID, or null if not found.
 */
export const getFurnaceName = (furnaceData, furnaceId) => {
  return furnaceId
    ? furnaceData.find(data => data.furnace == furnaceId)?.name
    : 'Total';
};

/**
 * Converts a value in seconds to hours.
 *
 * @param {number} seconds - The value in seconds to convert.
 * @param {number} [precision=2] - The number of decimal places to include in the result.
 * @returns {number} - The equivalent value in hours, rounded to the specified precision.
 */
export const convertSecToHrs = (seconds, precision = 2) => {
  const hours = seconds / 3600;
  return parseFloat(hours.toFixed(precision));
};
