import React, { forwardRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { Drawer } from '@mui/material';
import { removeInsignificantZeros } from '../../../utils/Spectrometer';
import classes from './SpectroListingTable.module.scss';
import { isMobile } from 'react-device-detect';
import MobileSpectroList from './MobileSpectroList';
import AddDilModal from '../AddDilModal';
import { makeStyles } from '@mui/styles';
import FilterDrawer from './FilterDrawer';
import DownloadDrawer from './DownloadDrawer';
import ElementBox, { elementInRange } from '../../ElementBox';
import SpectroHeader from './SpectroHeader';
import SpectroWebTable from './SpectroWebTable';
import Tippy from '@tippyjs/react';
import { toFixedValue } from '../../../utils/ChargeMix';
import 'tippy.js/dist/svg-arrow.css';
import ListingTableLoader from '../../Loader/Common/Listing/ListingTableLoader';

export const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: 400,
    backgroundColor: theme.palette.background.default
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  }
}));

const ElementBoxes = forwardRef(
  ({ elements, tc, hasHoverEffect, error }, ref) => {
    const [elementsInView, setElementsInView] = useState(0);

    useEffect(() => {
      if (ref.current) {
        const boxWidth = 4.5 * 16; // Convert rem to pixels
        const containerWidth =
          ref.current.clientWidth - (hasHoverEffect ? 150 : 100);
        setElementsInView(Math.floor(containerWidth / boxWidth));
      }
    }, [ref]);

    return (
      <div className={classes.elementBoxesContainer} ref={ref}>
        {elements.slice(0, elementsInView).map((element, index) => {
          const tcElement =
            tc && tc.find(t => t.element__symbol === element.element_symbol);
          const eleRange = tcElement ? tcElement.ele_range : '';
          const valueToDisplay =
            toFixedValue(element.recovery_rate) || element.element_composition;
          return (
            <ElementBox
              elementSymbol={element.element_symbol}
              elementValue={valueToDisplay}
              key={index}
              error={valueToDisplay < 0 && error}
              elementRange={eleRange}
              isEleInRange={element?.in_range}
              isEleInTolerance={element?.in_tolerance}
              eleDeviation={element?.deviation}
              sx={{
                minWidth: '4rem',
                height: '2.8rem',
                ...(!isMobile ? { maxWidth: '4.5rem' } : {})
              }}
            />
          );
        })}
        {elementsInView < elements.length && (
          <Tippy
            allowHTML='true'
            animation='fade'
            className={classes.tippyContainer}
            content={
              <div>
                {elements
                  .slice(
                    elementsInView,
                    Math.min(elementsInView + 7, elements.length)
                  )
                  ?.map((d, i) => {
                    const tcElement =
                      tc &&
                      tc.find(t => t.element__symbol === d.element_symbol);
                    const eleRange = tcElement ? tcElement.ele_range : '';
                    const valueToDisplay =
                      toFixedValue(d.recovery_rate) || d.element_composition;
                    const { _, ele_arrow } = elementInRange({
                      isEleInRange: d?.in_range,
                      isEleInTolerance: d?.in_tolerance,
                      eleDeviation: d?.deviation,
                      elementRange: eleRange,
                      elementValue: valueToDisplay
                    });
                    return (
                      <div key={i} className={classes.tippyContainerRow}>
                        <div style={{ fontWeight: 700 }}>
                          {d.element_symbol}
                        </div>
                        <div>{valueToDisplay}</div>
                        <div>
                          {ele_arrow && (
                            <div className={classes.arrow_aling}>
                              {ele_arrow}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                {Math.min(elementsInView + 7, elements.length) !=
                  elements.length && (
                  <div>
                    and{' '}
                    {elements.length -
                      Math.min(elementsInView + 7, elements.length)}{' '}
                    more elements
                  </div>
                )}
              </div>
            }
          >
            <div
              style={{
                minWidth: '4rem',
                maxWidth: '4rem',
                height: '2.8rem'
              }}
              className={classes.elementBox}
            >
              <span>{elements.length - elementsInView}</span>
              <span>...</span>
            </div>
          </Tippy>
        )}
      </div>
    );
  }
);

ElementBoxes.displayName = 'ElementBoxes';

export { ElementBoxes };

const renderSpectrometerReadings = (data, index, parent) => {
  if (!data) return null;
  if (!data?.reading_avg) {
    return <div>No Average reading found</div>;
  }
  const elements_limit = 8;

  let cm_target_chemistry_elements = [];
  let reading_avg = data.reading_avg;

  if (parent.cm_data?.cm_target_chemistry) {
    cm_target_chemistry_elements = reading_avg
      .map(data => {
        const { relaxed_min = null, relaxed_max = null } =
          parent.cm_data?.cm_target_chemistry.find(
            tc => tc.element__symbol == data.element_symbol
          ) || {};

        return {
          ...data,
          ele_range: parent.cm_data?.cm_target_chemistry.find(
            tc => tc.element__symbol == data.element_symbol
          )?.ele_range,
          relaxed_max,
          relaxed_min
        };
      })
      .filter(({ ele_range }) => ele_range);
  }

  let other_elements = reading_avg
    .filter(
      ({ element_symbol }) =>
        !cm_target_chemistry_elements.find(
          data => data.element_symbol === element_symbol
        )
    )
    .map(data => ({
      ...data,
      ele_range: null
    }));

  let elements_to_show = [...cm_target_chemistry_elements, ...other_elements];

  // Ensuring that the number of elements doesn't exceed the limit
  elements_to_show = elements_to_show.slice(0, elements_limit);

  return (
    <div className={classes.spectroReadings}>
      {elements_to_show.map(
        (
          {
            element_symbol,
            recovery_rate,
            ele_range,
            relaxed_max,
            relaxed_min,
            in_range,
            in_tolerance,
            deviation,
          },
          i
        ) => {
          const { className } = elementInRange({
            elementValue : removeInsignificantZeros(recovery_rate),
            elementRange : { ele_range, relaxed_min, relaxed_max },
            eleDeviation : deviation,
            isEleInRange : in_range,
            isEleInTolerance : in_tolerance
          });
          return (
            <div key={i} className={clsx(className, classes.elementBox)}>
              <div>{element_symbol}</div>
              <div>{removeInsignificantZeros(recovery_rate) || '-'}</div>
            </div>
          );
        }
      )}
    </div>
  );
};

const SpectroListingTable = ({
  query,
  setQuery,
  spectrometerList,
  limit,
  offset,
  count,
  page,
  isLoading
}) => {
  const [addDilModal, setAddDilModal] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const muiStyles = useStyles();
  // const MemorizedMenu = memo(FilterMenu);

  if (isMobile) {
    return (
      <MobileSpectroList
        spectrometerList={spectrometerList}
        isLoading={isLoading}
        limit={limit}
        offset={offset}
        count={count}
        page={page}
        muiStyles={muiStyles}
        renderSpectrometerReadings={renderSpectrometerReadings}
      />
    );
  }

  return (
    <>
      <AddDilModal
        open={addDilModal}
        handleClose={() => setAddDilModal(false)}
      />
      <Drawer
        anchor='right'
        open={filterOpen}
        onClose={() => setFilterOpen(false)}
        classes={{ paper: muiStyles.drawerPaper }}
      >
        <FilterDrawer
          handleClose={() => setFilterOpen(false)}
          setQuery={setQuery}
          query={query}
        />
      </Drawer>
      <Drawer
        anchor='right'
        open={downloadOpen}
        onClose={() => setDownloadOpen(false)}
        classes={{ paper: muiStyles.drawerPaper }}
      >
        <DownloadDrawer
          handleClose={() => setDownloadOpen(false)}
          query={query}
        />
      </Drawer>
      <div className={classes.spectroListingViewContainer}>
        <SpectroHeader
          setFilterOpen={setFilterOpen}
          setAddDilModal={setAddDilModal}
          setDownloadOpen={setDownloadOpen}
        />
        {isLoading ? <ListingTableLoader /> : <SpectroWebTable />}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  userDetail: state.userDetail,
  spectrometerFilterOptions: state.spectrometerFilterOptions,
  spectrometerList: state.spectrometerList?.results
});

export default connect(mapStateToProps)(SpectroListingTable);
