import React from 'react';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { random_item } from '../../utils';
import styles from './SpinnerWrapper.module.scss';

const RandomNumber = Math.floor(Math.random() * 25) % 3;

function SpinnerWrapper({ classes }) {
  var LoaderText = [
    'Check market prices of FerroAlloys and Pig Iron in WhatsApp',
    'Sell old grinding wheels and buy new ones!',
    'Connect your inventory to NowPurchase to automatically create orders'
  ];
  var location = useLocation();
  var RandomLoadText;
  var pathnamearr = location.pathname.split('/').filter(ele => ele != '');
  if (pathnamearr.includes('chargemix')) {
    RandomLoadText =
      'Proprietary technology to help you select the right ChargeMix based on your inventory, market prices & supply.';
  } else {
    RandomLoadText = LoaderText[RandomNumber];
  }
  return (
    <div
      className={clsx(
        styles.spinnerbody,
        classes?.root
      )}
    >
      <Spinner className={styles.spinner} />
      <div className={styles.randomtext}>Loading...</div>
    </div>
  );
}
export default SpinnerWrapper;
