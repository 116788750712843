import { toast } from 'react-toastify';
import { sendAnalytics } from '../analyticsUtils';
import { isMobile } from 'react-device-detect';
import { toFixedValue } from '../ChargeMix';
import config from '../../config/config';
import { token } from '../../api/apiUtils';

import { createQueryParam } from '..';
import styles from '../../componentsV2/Spectrometer/SpectroDetails/SpectroDetails.module.scss';
import { EAF, FURNACE_TYPE } from '../../constants/Furnace';
import { SPECTRO_CONSTS } from '../../constants/Spectrometer';
import { isEafFurnace } from '../Grade';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import classes from '../../componentsV2/ElementBox/ElementBox.module.scss';

const {
  GRADE_TYPE_NULL: NULL,
  GRADE_TYPE_BATH: BATH,
  GRADE_TYPE_FINAL: FINAL,
  GRADE_TYPE_ADDITION: ADDITION,
  GRADE_TYPE_LADLE: LADLE,
  GRADE_TYPE_PIT: PIT
} = SPECTRO_CONSTS;

export const calcOffset = (pageNum, limit) => {
  return (pageNum - 1) * limit;
};

export const elementsToShow = [
  'C',
  'Mn',
  'Si',
  'S',
  'P',
  'Mg',
  'Cr',
  'Cu',
  'Ni'
];

export function removeInsignificantZeros(num) {
  if (!num && num !== 0) return '';

  let result = num;

  if (typeof result !== 'number') {
    result = Number(result);
  }

  return Number(result.toFixed(4)).toString();
}

export const INITIAL_MODAL_STATE = {
  open: false,
  date: { value: new Date(), error: '' },
  heat: { value: '', error: '' },
  grade: { value: '', error: '' },
  chargeMix: { value: '', error: '' },
  type: { value: '', error: '' },
  elements: [],
  elementsValue: {},
  isEdit: false,
  error: ''
};

export function validate(obj) {
  let hasError = false;
  const modalData = { ...obj };
  const emptyRowsIdx = new Set();
  let data;

  for (const x in modalData) {
    if (
      [
        'open',
        'elements',
        'isEdit',
        'date',
        'error',
        'id',
        'customer',
        'rawSpectroMeasurements',
        'isDateEditable'
      ].includes(x)
    ) {
      continue;
    }

    if (x === 'elementsValue') {
      let hasTableData = false;

      for (
        let i = 0;
        i < modalData.elementsValue[modalData.elements[0]].length;
        i++
      ) {
        let hasRowData = false;

        for (const element of modalData.elements) {
          if (modalData.elementsValue[element][i].value.length) {
            hasTableData = true;
            hasRowData = true;
          }
        }

        if (hasRowData) {
          for (const element of modalData.elements) {
            const currentElement = modalData.elementsValue[element][i];

            if (!currentElement.value.length) {
              // currentElement.error = "This field is required.";
              // hasError = true;
            } else if (currentElement.value >= 98) {
              const msg = 'Value should be between 0 and 98';

              toast.error(msg, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true
              });

              currentElement.error = msg;
              hasError = true;
            } else {
              currentElement.error = '';
            }
          }
        } else {
          emptyRowsIdx.add(i);
        }
      }

      if (!hasTableData) {
        toast.error('Atleast one reading required', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true
        });

        modalData.error = 'Atleast one reading is required';

        hasError = true;
      }

      continue;
    }

    if (!modalData[x].value.toString().length) {
      modalData[x].error = 'This field is required.';
      hasError = true;
    } else {
      modalData[x].error = '';
    }
  }

  if (!hasError) {
    const copy = JSON.parse(JSON.stringify(modalData));

    for (const element of copy.elements) {
      copy.elementsValue[element] = copy.elementsValue[element].filter(
        (_, i) => !emptyRowsIdx.has(i)
      );
    }

    const spectro_measurements = [];
    const reading_avg = [];

    for (const x in copy.elementsValue) {
      for (let i = 0; i < copy.elementsValue[x].length; i++) {
        if (!spectro_measurements[i]) {
          spectro_measurements[i] = {
            id: copy.rawSpectroMeasurements?.[i]?.id,
            spectro_readings: []
          };
        }

        if (copy.elementsValue[x][i].value.length) {
          spectro_measurements[i].spectro_readings.push({
            element_symbol: x,
            recovery_rate: copy.elementsValue[x][i].value
          });
        }
      }
    }

    for (const x in copy.elementsValue) {
      const length = copy.elementsValue[x]?.reduce(
        (acc, curr) => (curr.value.length ? (acc = acc + 1) : acc),
        0
      );

      if (length) {
        reading_avg.push({
          recovery_rate:
            copy.elementsValue[x].reduce((acc, curr) => +acc + +curr.value, 0) /
            length,
          element_symbol: x
        });
      }
    }

    data = {
      id: copy.id,
      reading_created_at: copy.date.value,
      cm_id: copy.chargeMix.value,
      grade: copy.grade.value,
      heat_no: copy.heat.value,
      spectro_measurements,
      reading_avg,
      sample_type: copy.type.value
    };
  }

  return { hasError, data, modalData };
}

function getSpectroMeasurements(spectroMeasurements) {
  if (!spectroMeasurements) return;

  const readings = {};

  for (let i = 0; i < spectroMeasurements.length; i++) {
    const { spectro_readings } = spectroMeasurements[i];

    for (const { element_symbol, recovery_rate } of spectro_readings) {
      if (!readings[element_symbol]) {
        readings[element_symbol] = [];
      }

      readings[element_symbol][i] = recovery_rate;
    }
  }

  for (const x in readings) {
    for (let i = 0; i < readings[x].length; i++) {
      if (!readings[x][i]) readings[x][i] = '';
    }
  }

  return readings;
}

export const downloadData = (data, title) => {
  if (!data) {
    console.error('No data to download');
    return;
  }
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = title;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const handleDownloadGrade = () => {
  fetch(`${config.API_URL}/api/cm/download_grade/`, {
    method: 'GET',
    headers: {
      authorization: token(),
      'Content-Type': 'text/csv'
    }
  })
    .then(res => res.text())
    .then(data => {
      downloadData(data, 'grade.csv');
    });
};

export const downloadRawMaterials = () => {
  fetch(`${config.API_URL}/api/cm/download_inventory/`, {
    method: 'GET',
    headers: {
      authorization: token(),
      'Content-Type': 'text/csv'
    }
  })
    .then(res => res.text())
    .then(data => {
      downloadData(data, 'raw_materials.csv');
    });
};

export const postSpectroReading = async overviewData => {
  try {
    await fetch(`${config.API_URL}/api/iot/spectrometer_create_reading/`, {
      method: 'POST',
      headers: {
        authorization: token(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(overviewData)
    });
  } catch (error) {
    console.error(error);
  } finally {
    toast.success('Reading created successfully');
  }
};

export const getCmList = async search => {
  try {
    const data = await fetch(
      `${config.API_URL}/api/c/charge_mix_list/?search=${search}`,
      {
        method: 'GET',
        headers: {
          authorization: token(),
          'Content-Type': 'application/json'
        }
      }
    );
    return data.json();
  } catch (error) {
    console.error(error);
  }
};

export const getCmList_v2 = async search => {
  try {
    const data = await fetch(
      `${config.API_URL}/api/c/charge_mix_list/?search=${search}&spectro_tagged=True`,
      {
        method: 'GET',
        headers: {
          authorization: token(),
          'Content-Type': 'application/json'
        }
      }
    );
    return data.json();
  } catch (error) {
    console.error(error);
  }
};

export const getGradeList = async search => {
  try {
    const data = await fetch(
      `${config.API_URL}/api/c/metal_grade_list/?search=${search}`,
      {
        method: 'GET',
        headers: {
          authorization: token(),
          'Content-Type': 'application/json'
        }
      }
    );
    return data.json();
  } catch (error) {
    console.error(error);
  }
};
export const getGradeList_v2 = async search => {
  try {
    const data = await fetch(
      `${config.API_URL}/api/c/metal_grade_list/?search=${search}&spectro_tagged=True`,
      {
        method: 'GET',
        headers: {
          authorization: token(),
          'Content-Type': 'application/json'
        }
      }
    );
    return data.json();
  } catch (error) {
    console.error(error);
  }
};

export const getGradeCodeList_v2 = async () => {
  try {
    const data = await fetch(
      `${config.API_URL}/api/cm/metalgrade/grade_code_list/`,
      {
        method: 'GET',
        headers: {
          authorization: token(),
          'Content-Type': 'application/json'
        }
      }
    )
      .then(d => d.json())
      .then(d => d.grade_codes);
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getGradeDetails = async gradeId => {
  try {
    const data = await fetch(
      `${config.API_URL}/api/c/metal_grades/${gradeId}/`,
      {
        method: 'GET',
        headers: {
          authorization: token(),
          'Content-Type': 'application/json'
        }
      }
    );
    return data.json();
  } catch (error) {
    console.error(error);
  }
};

export const exportAsCSV = query => {
  const queryParam = createQueryParam(query);
  try {
    const demo = fetch(
      `${config.API_URL}/api/s/download_spectro/?${queryParam}`,
      {
        method: 'GET',
        headers: {
          authorization: token(),
          'Content-Type': 'application/json'
        }
      }
    )
      .then(data => data.text())
      .then(data => downloadData(data, 'spectrometer_list.csv'));
    toast.promise(demo, {
      pending: 'Downloading Data',
      success: 'File downloaded successfully',
      error: 'Error downloading'
    });
  } catch (error) {
    console.error(error);
  }
};

export function getRowActionData(row) {
  const data = JSON.parse(JSON.stringify(INITIAL_MODAL_STATE));

  data.chargeMix.value = row.cm_id ?? '';
  data.date.value = new Date(row.reading_created_at);
  data.heat.value = row.heat_no ?? '';
  data.type.value = row.sample_type ?? '';
  data.id = row.id;
  data.customer = row.customer;
  data.grade.value = row.grade ?? '';
  data.elementsValue = getSpectroMeasurements(row.spectro_measurements);
  data.open = true;
  data.isEdit = true;
  data.rawSpectroMeasurements = row.spectro_measurements;
  data.isDateEditable = !!row.spectro_xml_file;
  data.elementsToShow = elementsToShow;

  for (const x in data.elementsValue) {
    for (let i = 0; i < data.elementsValue[x].length; i++) {
      data.elementsValue[x][i] = {
        value: data.elementsValue[x][i],
        error: ''
      };
    }
  }

  if (data.readings) {
    data.elements = Object.keys(data.elementsValue);
  }

  return data;
}

export function handleRowClick(row, col, navigate) {
  // event.stopPropagation();
  // event.preventDefault();
  // IN CASE OF MOBILE NAVIGATE WITHOUT BACKGROUND LOCATION
  if (isMobile) {
    return navigate(`${data.heat.value}`, {
      state: { modal: data }
    });
  }

  if (row.cm_name && col.id == 'cm_name') {
    return window.open(
      `/cm/chargemix/wizard/${row.cm?.id}`,
      '_blank',
      'noopener,noreferrer'
    );
  }

  // NAVIGATE WITH BACKGROUND LOCATION TO OPEN MODAL
  return navigate(`/spectrometer/${row.id}`);
}

export function handleRowAction(event, data, navigate, location) {
  event.stopPropagation();
  event.preventDefault();

  return openModal(
    `/spectrometer/${data.heat.value}`,
    data,
    navigate,
    location
  );
}

export function openModal(to, data, navigate, location) {
  const analytics_Details = {
    defaultName: ' Spectrometer List',
    defaultDetails: `Spectrometer List`
  };
  sendAnalytics(analytics_Details);

  return navigate(to, {
    state: {
      backgroundLocation: { ...location, pathname: '/spectrometer' },
      modal: data
    }
  });
}

export function isDataWithinRange(val, { min, max }) {
  return Number(val) >= Number(min) && Number(val) <= Number(max);
}

export const elementInRange = (range, value, element) => {
  if (range) {
    const splitRange = range.split('-');
    const min = toFixedValue(splitRange[0] || 0);
    const max = toFixedValue(splitRange[1] || 100);

    if (value < min || value > max) {
      return false;
    }

    // IN CASE OF S DATA IS GOING TO BE INVALID IN CASE OF 0 0R LESSER
    if (element == 'S' && value <= 0) {
      return false;
    }

    return true;
  }

  return true;
};

/**
 * this function is used to get element Spectrometer Reading
 * @param {object} chargeMixData
 * @param {object} item
 * return elemet spectro reading
 */
export const elementSpectroReading = ({ cm_target_chemistry }, item) => {
  return cm_target_chemistry.find(
    com => com.element__symbol == item.element_symbol
  )?.ele_range;
};

/**
 * this function is used to get element Spectrometer symbol
 * @param {object} cm_elements
 * @param {object} item
 * return elemet symbol
 */
export const elementSpectroSymbol = (cm_elements, item) => {
  return cm_elements.find(cm_ele => cm_ele.id == item.element).symbol;
};

/**
 * this function returns target chemistry in a Map with key being symbol and value being its range
 * @param {object} targetChemistry
 * return Map(key being symbol and value being its range)
 * )
 */
export function getTCRangeBySymbol(targetChemistry) {
  const tcBySymbolMap = new Map();

  targetChemistry?.forEach(item => {
    const tcElementSymbol = item.element__symbol;
    tcBySymbolMap.set(tcElementSymbol, item.ele_range);
  });

  return tcBySymbolMap;
}

/**
 * Returns the ISO date string for the given number of days before today.
 * If no day is provided, returns the ISO date string for today.
 *
 * @param {number} days The number of days before today to get the ISO date string for.
 * @param {string} timeZone  the time zone to get the ISO date string
 * @returns {string} The ISO date string for the given day.
 */
export const getISOTime = (days = 0, timeZone = 'Asia/Kolkata') => {
  const millisecondsInDay = 24 * 60 * 60 * 1000;
  const time = days ? Date.now() - days * millisecondsInDay : Date.now();
  const date = new Date(time);
  const options = {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };
  const [month, day, year] = date.toLocaleString('en-US', options).split('/');
  const isoDate = `${year}-${month}-${day}`;
  return isoDate;
};

/**
 * This function finds the specific element in the gradeBathTc array by its symbol,
 * then calculates and returns the range by using its minimum and maximum values.
 * If the element is not found, it returns null.
 *
 * @param {Object[]} gradeBathTc - An array of objects, each representing an element.
 * Each object must have at least the properties 'element__symbol', 'min', and 'max'.
 * @param {string} elementSymbol - The symbol of the element whose range is to be found.
 *
 * @returns {(string|null)} The range of the given element (in the format 'min-max'), or null if the element was not found.
 *
 * @example
 *
 * const gradeBathTc = [
 *   { element__symbol: 'H', min: '0.1', max: '1.2' },
 *   { element__symbol: 'He', min: '0.3', max: '1.8' },
 *   ...
 * ];
 *
 * const rangeH = getElementRange(gradeBathTc, 'H');  // '0.1-1.2'
 * const rangeNe = getElementRange(gradeBathTc, 'Ne');  // null
 */
export function getElementRange(gradeBathTc, elementSymbol) {
  const element = gradeBathTc.find(
    element => element.element__symbol === elementSymbol
  );
  if (element) {
    const min = parseFloat(element.min).toFixed(1);
    const max = parseFloat(element.max).toFixed(1);
    return `${min}-${max}`;
  } else {
    return null;
  }
}

/**
 * Function to fetch header stats for spectrometer
 *
 * @returns {Promise<object>} - JSON object containing header stats data
 */
export async function getHeaderStat() {
  try {
    const data = await fetch(
      `${config.API_URL}/api/spectrometer/cmheat/heat_count/`,
      {
        method: 'GET',
        headers: {
          authorization: token(),
          'Content-Type': 'application/json'
        }
      }
    );
    return data.json();
  } catch (error) {
    console.error(error);
  }
}

export const getReadingsData = spectroReading => {
  const finalReadings = [];
  const bathReadings = [];
  const pitReadings = [];
  const additionReadings = [];
  const ladleReadings = [];
  const untaggedReadings = [];

  spectroReading.readings.map(d => {
    if (d.sample_type == FINAL) {
      finalReadings.push(d);
    } else if (d.sample_type == BATH) {
      bathReadings.push(d);
    } else if (d.sample_type == PIT) {
      pitReadings.push(d);
    } else if (d.sample_type == ADDITION) {
      additionReadings.push(d);
    } else if (d.sample_type == LADLE) {
      ladleReadings.push(d);
    } else {
      untaggedReadings.push(d);
    }
  });
  return {
    finalReadings,
    bathReadings,
    pitReadings,
    additionReadings,
    ladleReadings,
    untaggedReadings
  };
};

export const getTcData = (spectroReading, furnace_type) => {
  const {
    finalReadings,
    bathReadings,
    additionReadings,
    ladleReadings,
    pitReadings,
    untaggedReadings
  } = getReadingsData(spectroReading);
  const { grade } = spectroReading;
  const createGradeTcData = source =>
    (grade?.[source] || []).map(d => ({
      ...d,
      ele_range: `${d.min || ''}-${d.max || ''}`
    }));

  const cmData = spectroReading.cm_data || spectroReading.cm;

  const {
    cm_target_chemistry,
    cm_bath_target_chemistry,
    ladle_tc,
    addition_tc,
    pit_tc
  } = cmData;

  const hasBathChemistry =
    cmData?.has_bath_chemistry || spectroReading.grade?.has_bath_chemistry;

  const createViewData = (data, tag, type, text) => [
    {
      data: data || createGradeTcData(type),
      tag: <div className={styles[`sampleType${tag}Box`]}>{text || tag}</div>
    }
  ];

  const bathMappingData = [
    {
      tcData:
        isEafFurnace(furnace_type) || hasBathChemistry
          ? createViewData(cm_bath_target_chemistry, BATH, 'bath_tc')
          : [],
      readings: bathReadings,
      targetChemistry: cm_bath_target_chemistry,
      type: BATH
    },
    {
      tcData: [],
      readings: untaggedReadings,
      targetChemistry: null,
      type: ''
    }
  ];
  const mappingData =
    furnace_type == FURNACE_TYPE[EAF]
      ? [
          {
            tcData: createViewData(pit_tc, FINAL, 'pit_tc', PIT),
            readings: pitReadings,
            targetChemistry: pit_tc,
            type: PIT
          },
          {
            tcData: createViewData(ladle_tc, BATH, 'ladle_tc', LADLE),
            readings: ladleReadings,
            targetChemistry: ladle_tc,
            type: LADLE
          },
          {
            tcData: createViewData(addition_tc, BATH, 'addition_tc', ADDITION),
            readings: additionReadings,
            targetChemistry: addition_tc,
            type: ADDITION
          },
          ...bathMappingData
        ]
      : [
          {
            tcData: createViewData(cm_target_chemistry, FINAL, 'grade_tc'),
            readings: finalReadings,
            targetChemistry: cm_target_chemistry,
            type: FINAL
          },
          ...bathMappingData
        ];
  return mappingData;
};

export const getSuggestionText = bathReading => {
  const { sample_type, in_range } = bathReading;
  switch (sample_type) {
    case BATH:
      return in_range ? ADDITION : BATH;
    case ADDITION:
      return in_range ? LADLE : ADDITION;
    case LADLE:
      return LADLE;
    case FINAL:
      return FINAL;
  }
};

export const getSampleType = furnace_type => {
  const commonFilter = [
    {
      label: 'All',
      value: NULL
    },
    {
      label: BATH
    }
  ];
  if (furnace_type == FURNACE_TYPE[EAF]) {
    return [
      ...commonFilter,
      {
        label: ADDITION
      },
      {
        label: LADLE
      },
      {
        label: PIT
      }
    ];
  }
  return [
    ...commonFilter,
    {
      label: FINAL
    }
  ];
};

/**
  Check if an element value is within a given range.
  @param {number} elementValue - The value of the element to be checked.
  @param {string} range - The range to be checked, in the format of "min-max".
  @returns {object} An object containing a boolean flag indicating whether the element is within the range, tolerance or has a deviation.
**/
export function calcReadingInRange(elementValue, range, show_success = true) {
  const success_class = show_success
    ? classes.tc_element_box_green
    : classes.tc_element_box_grey;
  const ele_range = range?.ele_range;
  const resp_temp = {
    ele_className: classes.tc_element_box_grey,
    ele_arrow: null
  };
  if (ele_range === undefined || !ele_range) return resp_temp;
  const { relaxed_min, relaxed_max } = range;
  let [min, max] = ele_range.split('-');
  min = min ? parseFloat(min) : null;
  max = max ? parseFloat(max) : null;
  if (!min && !max) return resp_temp;
  if (!elementValue)
    return { ele_className: classes.tc_element_box_grey, ele_arrow: null };

  if (elementValue >= min && elementValue <= max)
    return { ele_className: success_class, ele_arrow: null };
  if (elementValue < min) {
    if (relaxed_min && elementValue > relaxed_min) {
      return {
        ...resp_temp,
        ele_className: classes.tc_element_box_warn
      };
    }
    return {
      ele_className: classes.tc_element_box_red,
      ele_arrow: (
        <ArrowDownwardIcon sx={{ fontSize: '16px', color: '#E43E2B' }} />
      )
    };
  }
  if (elementValue > max) {
    if (relaxed_max && elementValue < relaxed_max) {
      return {
        ...resp_temp,
        ele_className: classes.tc_element_box_warn
      };
    }
    return {
      ele_className: classes.tc_element_box_red,
      ele_arrow: <ArrowUpwardIcon sx={{ fontSize: '16px', color: '#E43E2B' }} />
    };
  }
  return { ele_className: success_class, ele_arrow: null };
}
