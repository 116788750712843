import { SPECTRO_ACTION_TYPES } from './spectroActionTypes';
import initialState from '../initial-state';

export function spectrometerList(
  state = initialState.spectrometerList,
  action
) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.GET_SPECTRO_LIST_SUCCESS:
      return action.spectrometerList;
    case SPECTRO_ACTION_TYPES.ADD_SPECTRO_SUCCESS:
      return { ...state, results: [...state.results, action.spectrometer] };

    case SPECTRO_ACTION_TYPES.UPDATE_SPECTRO_SUCCESS:
      return {
        ...state,
        results: state.results.map(ele =>
          ele.id == action.spectrometer.id ? action.spectrometer : ele
        )
      };

    default:
      return state;
  }
}

export function spectrometer(state = initialState.spectrometer, action) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.GET_SPECTRO_SUCCESS:
      return action.spectrometer;
    case SPECTRO_ACTION_TYPES.CLEAR_SPECTRO:
      return null;

    default:
      return state;
  }
}

export function spectrometerOverviewData(
  state = initialState.spectrometerOverviewData,
  action
) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.SPECTRO_OVERVIEW_DATA_SUCCESS:
      return action.overViewData;

    default:
      return state;
  }
}

export function spectrometerFilterOptions(
  state = initialState.spectrometerFilterOptions,
  action
) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.SPECTRO_FILTER_OPTIONS_SUCCESS:
      return action.options;

    default:
      return state;
  }
}

export function spectroReading(state = initialState.spectroReading, action) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.SPECTRO_READING:
      return action.spectroReading;
    default:
      return state;
  }
}

export function spectroReadingAddDil(
  state = initialState.spectroReadingAddDil,
  action
) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.SPECTRO_READING_ADD_DIL:
      return action.addDilResponse;
    default:
      return state;
  }
}

export function inventoryAddDil(state = initialState.inventoryAddDil, action) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.SPECTRO_READING_ADD_DIL_INVENTORY:
      return { ...state, data: action.data, data_loaded: true };
    default:
      return state;
  }
}

export function getItemInventoryDetails(
  state = initialState.inventoryDetails,
  action
) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.GET_ITEM_INVENTORY_DETAIL:
      return { ...state, data: action.data, data_loaded: true };
    case SPECTRO_ACTION_TYPES.UPDATE_ITEM_INVENTORY:
      return {
        ...state,
        data: action.data,
        data_loaded: true
      };
    default:
      return state;
  }
}

/**
 * reducer function for item inventory by list of items
 */
export function itemInventoryByList(
  state = initialState.itemInventoryByList,
  action
) {
  switch (action.type) {
    case SPECTRO_ACTION_TYPES.GET_ITEM_INVENTORY_BY_LIST:
      return { ...state, data: action.data, data_loaded: true };
    case SPECTRO_ACTION_TYPES.UPDATE_ITEM_INVENTORY_LIST:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.map(item =>
            item.id === action.data.id ? action.data : item
          )
        },
        data_loaded: true
      };
    default:
      return state;
  }
}
