export const Slug = {
  CHARGEMIX: 'chargemix',
  SPECTROMETER: 'spectrometer',
  ITEM_INVENTORY: 'iteminventory',
  RFQ: 'rfq',
  ORDER: 'order',
  GRADES: 'grade',
  MATERIAL_TEST: 'materialtest',
  QUALITY_REPORTS: 'rmtest',
  SUPPLIER_MATRIX: 'suppliermatrix',
  CONTROL_PLAN: 'controlplan',
  MELTING_SECTION: 'heatlog',
  PARTS: 'parts',
  PRODUCTION_PLAN: 'productionplan'
};
export const Scopes = {
  VIEW: ['view', 'all', 'edit', 'share', 'archive'],
  EDIT: ['edit', 'archive', 'all'],
  SHARE: ['share', 'all'],
  DELETE: ['archive', 'all']
};
