import { Modal } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import classes from './TermsAndCondition.module.scss';

const index = ({ open, setOpen, handleClick, userDetail }) => {
  const handleClose = () => {
    if (userDetail.is_terms_accepted == true) {
      setOpen(false);
    }
  };
  return (
    <Modal open={open} sx={{ maxWidth: 1050 }} onClose={handleClose}>
      <div className={classes.modalDiv}>
        <div
          style={{
            display: 'flex',
            padding: '30px 30px 21px 30px',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={{ fontSize: 20, fontWeight: 700 }}>
            Terms & Conditions
          </div>
          {userDetail?.is_terms_accepted ? (
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleClick();
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <g clipPath='url(#clip0_65_233)'>
                  <path
                    d='M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z'
                    fill='#909090'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_65_233'>
                    <rect width='24' height='24' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            </div>
          ) : null}
        </div>
        <div className={classes.content}>
          <p>
            You agree that by accessing the website, you have read, understood,
            and agree to be bound by all of these Terms of Use. If you do not
            agree with all of these Terms of Use, then you are expressly
            prohibited from using the Site and you must discontinue use
            immediately.
          </p>
          <p>
            Supplemental Terms of Use or documents that may be posted on the
            Site from time to time are hereby expressly incorporated herein by
            reference. We reserve the right, in our sole discretion, to make
            changes or modifications to these Terms of Use at any time and for
            any reason.
          </p>
          <p>
            We will alert you about any changes by updating the &ldquo;Last
            updated&rdquo; date of these Terms of Use, and you waive any right
            to receive specific notice of each such change.
          </p>
          <p>
            It is your responsibility to periodically review these Terms of Use
            to stay informed of updates. You will be subject to and will be
            deemed to have been made aware of and to have accepted, the changes
            in any revised Terms of Use by your continued use of the Site after
            the date such revised Terms of Use are posted.
          </p>
          <p>
            <strong>
              <u>Terms of Use</u>
            </strong>
          </p>
          <p>&nbsp;</p>
          <ol>
            <li>
              These terms of use (&ldquo;Terms&rdquo;) are being entered into
              between the user (&ldquo;User&rdquo;) and BizHero India Private
              Limited, at 48 Ganga Jamuna Building, 28/1 Shakespeare Sarani,
              Kolkata 700017, West Bengal (&ldquo;Now Purchase&rdquo;).
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='2'>
            <li>
              These Terms govern User&rsquo;s availing of Now Purchase&rsquo;s
              services (&ldquo;Services&rdquo;, as stated below) using its
              proprietary cloud-based solution called Metal Cloud. Metal Cloud
              comprises of Now Purchase&rsquo;s proprietary technology to
              provide, inter alia, Charge Mix Module, Power Insights,
              Spectrometer services to its users. If any term here is
              unacceptable to you, we request you to not avail Now
              Purchase&rsquo;s Services.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='3'>
            <li>
              User acknowledges that Metal Cloud, its proprietary content and
              associated intellectual property, the trademark Now Purchase and
              logos thereof are owned by Now Purchase.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='4'>
            <li>
              Now Purchase provides all or some of the following Services on
              Metal Cloud to a User as agreed between the User and Now Purchase:
            </li>
          </ol>
          <p>&nbsp;</p>
          <ul>
            <li>Commodity Purchase</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              The prices listed in Commodity Purchase are not guaranteed in any
              manner by Now Purchase.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees that prices listed in Commodity Purchase are subject
              to change without notice per market conditions.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees that the prices listed in Commodity Purchase are not
              offers for sale.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Charge Mix Module</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees and understands that the Charge Mix feature, including
              the addition/ dilution feature, on Metal Cloud functions based on
              a theoretical model, ideal conditions, and assumptions.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees and understands that the results obtained from the
              composition suggested by the Charge Mix feature on Metal cloud
              might vary depending on real-world factors.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees that the recommendations made by Charge Mix are auto
              generated and not manually provided.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees that Now Purchase is not responsible for any
              consequence of using the Charge Mix feature contrary to ideal
              conditions and/or assumptions and/or recommendations made by
              Charge Mix.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Spectrometer</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees that Now Purchase is not responsible for any
              third-party hardware product used by User to use the Spectrometer
              service provided by Now Purchase.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees that Spectrometer functions based on hardware and
              software elements/ components and its accuracy is dependent on
              such elements. User further agrees that where said elements/
              components are provided/ manufactured by a third party, it shall
              solely responsible for the same.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees that Now Purchase is not responsible for any
              consequence of using the Spectrometer feature contrary to
              recommendations for Spectrometer and/or the hardware elements.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>Power Insights</li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees that Now Purchase is not responsible for any
              third-party product, including IoT sensors and energy meters, used
              by User to avail the Power Insights service provided by Now
              Purchase. Such third-party shall be solely liable for any such
              product.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees that Power Insights functions based on hardware and/or
              software elements and its accuracy is dependent on such elements.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees that Now Purchase is not responsible for any
              consequence of using the Power Insights feature contrary to
              recommendations for Power Insights or based on inaccurate readings
              provided by or behalf of the User.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User shall provide adequate support to Now Purchase as may be
              reasonably requested by Now Purchase for providing its services to
              the User. Now Purchase shall not be liable and/or responsible for
              any person and/or conduct and/or consequence of conduct of such
              person engaged by the User to support Now Purchase.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ul>
            <li>
              User agrees that Now Purchase may, on the User&rsquo;s behalf,
              obtain third-party product, including IoT sensors and energy
              meters, to be used by User to avail the Power Insights service
              provided by Now Purchase.
            </li>
          </ul>
          <p>&nbsp;</p>
          <ol start='5'>
            <li>
              All Services are provided by Now Purchase on &ldquo;as is&rdquo;
              basis.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='6'>
            <li>
              Now Purchase takes all reasonable security measures to keep
              User&rsquo;s personal information and information pertaining to
              its products confidential.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='7'>
            <li>
              User hereby, by availing Services, gives specific consent to Now
              Purchase to store and process its information for the purpose of
              providing agreed Services to the User. User hereby, by availing
              Services, gives specific consent to Now Purchase for storing
              and/or processing said information, including through a third
              party authorized by Now Purchase and/or any software used by Now
              Purchase to provide and/or improve its services. User agrees that
              Now Purchase shall not be liable for storing and/or processing
              said information by a third-party.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='8'>
            <li>
              Now Purchase is not responsible for consequences of Services
              emanating from User&rsquo;s negligence and/ or violation of these
              Terms.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='9'>
            <li>
              Now Purchase does not guarantee that Metal Cloud and Services will
              be available at all times. Now Purchase may experience hardware,
              software, or other problems or need to perform maintenance related
              thereto, resulting in interruptions, delays, or errors. User
              agrees that Now Purchase has no liability whatsoever for any loss,
              damage, or inconvenience caused by User&rsquo;s inability to
              access or use Metal Ckoud or Services during any downtime or
              discontinuance of the website or Services. Now Purchase shall
              provide advance notice of 24 hours before such downtime or
              discontinuance of the website.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='10'>
            <li>
              If, on occurrence of a force majeure event, such as but not
              limited to (i) acts of God, fire, flood, drought, famine,
              earthquake, hurricane, typhoon, tsunami, or other natural calamity
              or natural disaster; (ii) epidemic or pandemic; (iii) terrorism or
              terrorist attack, insurrection, civil war, civil commotion or
              riots, war, biological or chemical warfare, threat of or
              preparation for war, armed conflict, imposition of sanctions,
              embargo, or breaking off of diplomatic relations; (iv) nuclear
              explosion, radioactive or chemical contamination, sonic boom,
              ionizing radiation; (v) any labour or trade dispute, strikes,
              industrial action, lockout, non- availability/ restriction of
              water or electric supply, (vi) any acts/ orders/ directions of any
              statutory authority; prohibition or restriction by a statutory
              authority; affecting Now Purchase&rsquo;s ability to perform any
              of its Services, or such performance of Services is interrupted or
              delayed, then such non-performance, delayed performance, or
              interrupted performance of Services, as the case may be, will not
              be treated as default by Now Purchase, for such period till the
              force majeure event continues.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='11'>
            <li>
              the Company shall implement a click-wrap mechanism of its website
              and mobile application, which enables the Company to seek specific
              and express consents from its users for its privacy policy, so
              that the users are enabled to have the option to either
              specifically and expressly provide or withhold consent in respect
              of the information shared by them. The Company may do so by adding
              a clickable hyperlink to the Company’s privacy policy and terms
              and conditions at the time that it is acquiring the consent of its
              users to its terms and conditions. For recording the consent of
              the consumer, the Company must ensure that the check box appearing
              next to it is not pre-ticked. The users should also have the
              option to reject by clicking the cancel button or closing the
              window
            </li>
          </ol>
          <ol start='12'>
            <li>
              User agrees to use the Services responsibly and only by taking
              reasonable precautions while availing Services.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='13'>
            <li>
              Now Purchase&rsquo;s nodal person of contact, an Indian resident,
              is as follows:
            </li>
          </ol>
          <p>&nbsp;</p>
          <p>Name: Prateek Losalka</p>
          <p>Email address: prateek@nowpurchase.com</p>
          <p>Phone number: 9883777618</p>
          <p>&nbsp;</p>
          <ol start='14'>
            <li>
              User agrees to not tamper with Metal Cloud in any manner and not
              provide unauthorized access to Metal Cloud to any person or entity
              in any manner.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='15'>
            <li>
              User agrees that Now Purchase&rsquo;s liability for Services
              provided to User shall not exceed the total fee paid by User to
              Now Purchase.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='16'>
            <li>
              Users shall not use Now Purchase&rsquo;s name, logos, service
              marks, trade names, trademarks and/or any other intellectual
              property without the express prior written approval of Now
              Purchase.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='17'>
            <li>
              Any provision of these Terms that violates or is prohibited by
              applicable law or that is unenforceable in any jurisdiction will,
              be ineffective to the extent of such violation, prohibition or
              unenforceability without invalidating the remaining provisions of
              these Terms, and any such violation, prohibition or
              unenforceability in any jurisdiction will not invalidate or render
              unenforceable such provision in any other jurisdiction.
            </li>
          </ol>
          <p>&nbsp;</p>
          <ol start='18'>
            <li>
              These Terms, and any dispute arising out of these Terms, shall be
              governed by the laws of India.
            </li>
          </ol>
          <p>&nbsp;</p>
          Hereby,
          <div className={classes.acceptance}>
            I accept the{' '}
            <a
              href='https://img1.nowpurchase.com/docs/Privacy+Policy.pdf'
              rel='noreferrer'
              target='_blank'
            >
              Privacy policy
            </a>
          </div>
          <div className={classes.acceptance}>
            I accept the{' '}
            <a
              href='https://img1.nowpurchase.com/docs/Terms+of+Use.pdf'
              rel='noreferrer'
              target='_blank'
            >
              Terms of use
            </a>
          </div>
          <div className={classes.acceptance}>
            I accept the{' '}
            <a
              href='https://img1.nowpurchase.com/docs/Data+Security+Policy+.pdf'
              rel='noreferrer'
              target='_blank'
            >
              Data Security policies
            </a>
          </div>
          <p>&nbsp;</p>
        </div>
        {userDetail?.is_terms_accepted == false ? (
          <div className={classes.buttonWrapper}>
            <button
              className={classes.button}
              style={{
                backgroundColor: 'rgba(33, 126, 192, 1)',
                borderRadius: 4,
                marginTop: 10,
                marginBottom: 30
              }}
              onClick={() => {
                handleClick();
                setOpen(false);
              }}
            >
              Accept T&C
            </button>
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    userDetail: state.userDetail
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(index);
