import { CHARGEMIX_COMMON_CONSTS } from '../../constants/ChargeMix/common';
import { COMMON_ACTION_TYPES } from '../../redux/commonActionTypes';
import { FURNANCE_CONSTS } from '../../constants/ChargeMix/Furnace';
import config from '../../config/config';
import { token, cFetch } from '../../api/apiUtils';

import { calculateChemistry } from '../ChargeMixV2';

const { FURNACE, ADDITIVES } = COMMON_ACTION_TYPES;

/**
 * Calculates the recovery rate based on the given parameters.
 * @param {Object} options - Options object containing the following properties:
 *   - has_sponge_iron {boolean}: Indicates whether sponge iron is present for furnace materials.
 *   - isComposition {boolean}: Indicates whether the material is a composition.
 * @param {number} rr_rate - The recovery rate value.
 * @param {number} metal_rr - The metal recovery rate value (used only if cm_type is not FURNACE).
 * @param {string} cm_type - The type of charge mix.
 * @returns {number} - The calculated recovery rate.
 */
export function getRecoveryRate(
  { has_sponge_iron, isComposition },
  rr_rate,
  metal_rr,
  cm_type
) {
  // DON'T USE metal_rr INCASE OF FURNACE MATERIALS
  if (cm_type == FURNACE && !has_sponge_iron) return Number(rr_rate);

  return Number(rr_rate) * ((isComposition && Number(metal_rr) / 100) || 1);
}

/**
 * Generates a quantity label based on the toggle value.
 * @param {boolean} toggle - Toggle value indicating the state of the label.
 * @returns {string} - The generated HTML for the quantity label.
 */
export const qtyLable = toggle => {
  // Lable toggle on click
  const qtyRangeUnitEle = document.getElementById('ActiveQtyRangeUnit');
  const newQtyRangeUnit =
    !toggle || !qtyRangeUnitEle || qtyRangeUnitEle.textContent != 'kg'
      ? true
      : false;

  return `<div id="QtyRangeContainer">
  Qty Range 
  <div id="QtyRangeUnitContainer">
     <span id="${
       newQtyRangeUnit ? 'NormalQtyRangeUnit' : 'ActiveQtyRangeUnit'
     }">%</span>
     <span id="${
       newQtyRangeUnit ? 'ActiveQtyRangeUnit' : 'NormalQtyRangeUnit'
     }">kg</span>
  </div>
</div>`;
};

/**
 * Generates a quantity label for a specific context based on the toggle value.
 * @param {boolean} toggle - Toggle value indicating the state of the label.
 * @returns {string} - The generated HTML for the quantity label.
 */
export const hfQtyLable = toggle => {
  const newQtyRangeUnit = !toggle ? true : false;

  return `<div id="QtyRangeContainer">
  Qty Range 
  <div id="QtyRangeUnitContainer">
     <span id="${
       newQtyRangeUnit ? 'NormalQtyRangeUnit' : 'ActiveQtyRangeUnit'
     }">%</span>
     <span id="${
       newQtyRangeUnit ? 'ActiveQtyRangeUnit' : 'NormalQtyRangeUnit'
     }">kg</span>
  </div>
</div>`;
};

/**
 * Calculates the quantity range in kilograms based on the given quantity and furnace size.
 * @param {number} qty - The quantity value.
 * @param {number} furnaceSize - The furnace size value.
 * @returns {number} - The quantity range in kilograms.
 */
export function calculateQtyRangeInKg(qty, furnaceSize) {
  return Number((qty * furnaceSize) / 100);
}

/**
 * Calculates the savings percentage based on the total saving and current value.
 * @param {number} total_saving - The total saving value.
 * @param {number} current - The current value.
 * @returns {string} - The savings percentage rounded to 2 decimal places as a string.
 *                    Returns '0%' if total_saving is less than or equal to 0.
 */
export function calculateSavingsPercentage(total_saving, current) {
  if (total_saving <= 0) return 0;
  return `${((total_saving / current) * 100).toFixed(2)}%`;
}

/**
 * Calculates the savings based on the optimized value and current value.
 * @param {number} optimized - The optimized value.
 * @param {number} current - The current value.
 * @returns {number} - The calculated savings.
 *                     Returns 0 if the savings is less than or equal to 0.
 */
export function calculateSavings(optimizied, current) {
  const savings = Math.round(current - optimizied);
  if (savings > 0) return savings;
  return 0;
}

/**
 * Calculates the monthly savings based on the optimized value and current value.
 * @param {number} optimized - The optimized value.
 * @param {number} current - The current value.
 * @returns {number} - The calculated monthly savings.
 *                     Returns 0 if the savings is less than or equal to 0.
 */
export function calculateMonthlySavings(optimizied, current) {
  const savings = Math.round(current - optimizied) * 30;
  if (savings > 0) return savings;
  return 0;
}

/**
 * Gets the updated furnace size based on the charge mix type, current furnace size, and heel quantity.
 * @param {string} cm_type - The type of charge mix.
 * @param {number} furnaceSize - The current furnace size value.
 * @param {number} heelQty - The heel quantity value.
 * @returns {number} - The updated furnace size.
 *                     Returns the current furnace size if cm_type is FURNACE or ADDITIVES,
 *                     otherwise returns furnaceSize minus heelQty.
 */
export function getUpdatedFurnaceSize(cm_type, furnaceSize, heelQty) {
  return cm_type === FURNACE || cm_type === ADDITIVES
    ? furnaceSize
    : furnaceSize - heelQty;
}

/**
 * @param {Number || String} value
 * @param {Number || String} to default CHARGEMIX_COMMON_CONSTS.TO_FIXED_CHEM_CONST
 * @returns {Number} With Decimal Places
 */
export function toFixedValue(
  value,
  to = CHARGEMIX_COMMON_CONSTS.TO_FIXED_CHEM_CONST
) {
  if (isNaN(value)) {
    return value;
  }

  return Number(Number(value).toFixed(to));
}

/**
 * Converts a percentage value to kilograms based on the given percentage and furnace size.
 * @param {number} percentage - The percentage value.
 * @param {number} furnaceSize - The furnace size value.
 * @returns {number} - The converted value in kilograms (rounded to the nearest whole number).
 */
export const percentageToKg = (percentage, furnaceSize) =>
  Math.round((percentage * furnaceSize) / 100);

export const Furnace_Size_List = [
  { label: '1000' },
  { label: '1100' },
  { label: '1150' },
  { label: '1200' },
  { label: '1250' },
  { label: '1300' },
  { label: '1350' },
  { label: '1400' },
  { label: '1450' },
  { label: '1500' },
  { label: '1550' }
];

export const FURNACE_INDUCTION = 'INDUCTION';
export const FURNACE_CUPOLA = 'CUPOLA';
export const FURNACE_OTHERS = 'OTHERS';

export const furnace_types = [
  FURNACE_INDUCTION,
  FURNACE_CUPOLA,
  FURNACE_OTHERS
];

/**
 * Determines the quantity range column index for furnace materials based on the given data.
 * @param {Object} data - The data object containing properties related to composition and stock.
 * @returns {number} - The quantity range column index for furnace materials.
 */
export const qtyRangeColIndexFinal = data => {
  if (data.is_composition && data.is_stock) {
    return CHARGEMIX_COMMON_CONSTS.QTY_RANGE_INDEX_FINAL_RECOVERY + 1;
  } else if (data.is_composition && !data.is_stock) {
    return CHARGEMIX_COMMON_CONSTS.QTY_RANGE_INDEX_FINAL_RECOVERY;
  } else if (!data.is_composition && data.is_stock) {
    return CHARGEMIX_COMMON_CONSTS.QTY_RANGE_INDEX_FINAL + 1;
  } else if (!data.is_composition && !data.is_stock) {
    return CHARGEMIX_COMMON_CONSTS.QTY_RANGE_INDEX_FINAL;
  }
};

/**
 * Determines the quantity range column index for furnace materials based on the given data.
 * @param {Object} data - The data object containing properties related to composition and stock.
 * @returns {number} - The quantity range column index for furnace materials.
 */
export const qtyRangeColIndexFurnace = data => {
  if (data.is_composition && data.is_stock) {
    return FURNANCE_CONSTS.QTY_RANGE_INDEX_RECOVERY + 1;
  } else if (data.is_composition && !data.is_stock) {
    return FURNANCE_CONSTS.QTY_RANGE_INDEX_RECOVERY;
  } else if (!data.is_composition && data.is_stock) {
    return FURNANCE_CONSTS.QTY_RANGE_INDEX + 1;
  } else if (!data.is_composition && !data.is_stock) {
    return FURNANCE_CONSTS.QTY_RANGE_INDEX;
  }
};

/**
 * Fetch the PDF file.
 *
 * @param {Number} id - ChargeMix I'd.
 * @returns {void}
 */
export const getPdf = async id => {
  return fetch(`${config.API_URL}/api/cm/pdf/${id}/pdf`, {
    headers: {
      authorization: token()
    }
  }).then(response => {
    response
      .blob()
      .then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.click();
        alink.remove();
      })
      .catch(err => {
        console.error(`Failed to Load the Pdf. Please, Try again.`);
      });
  });
};
/**
 * Share the ChargeMix PDF file over WhatsApp.
 *
 * @param {Number} id - ChargeMix I'd
 * @param {Object} bodyData - Payload for Api Call with the list of users
 * @returns {void}
 */
export const shareOverWhatsApp = async (id, bodyData) => {
  await cFetch(`${config.API_URL}/api/cm/pdf/${id}/pdf`, {
    method: 'POST',
    headers: {
      authorization: token()
    },
    body: JSON.stringify(bodyData)
  });
};

/**
 * Fetch the user contacts to share over WhatsApp.
 *
 * @returns {Array} An Array of objects containing user contact details
 */
export const fetchUserContacts = async () => {
  try {
    const response = await cFetch(`${config.API_URL}/api/r/cust_users/`, {
      method: 'GET',
      headers: {
        authorization: token(),
        'Content-Type': 'application/json'
      }
    });
    return response;
  } catch (err) {
    console.error(`Failed to load the user contacts data. Please try again.`);
    return null;
  }
};

/**
 * Retrieves the chemistry data based on the given parameters.
 * @param {Object} options - Options object containing the following properties:
 *   - predKey {string}: The key used to retrieve the predicted chemistry value.
 *   - chargeMixData {Object}: The charge mix data object.
 *   - elementSymbolById {Object}: The element symbol lookup object.
 * @returns {Object} - The chemistry data object with element symbols as keys and corresponding values.
 */
export const getChemistry = ({ predKey, chargeMixData, elementSymbolById }) => {
  if (chargeMixData.pred_chemistry) {
    return chargeMixData.cm_target_chemistry.reduce((acc, d) => {
      acc[d.element__symbol] =
        chargeMixData.pred_chemistry?.[d.element__symbol]?.[predKey] || 0;
      return acc;
    }, {});
  } else {
    return calculateChemistry(
      chargeMixData,
      elementSymbolById,
      'optimized_qty',
      [FURNACE, ADDITIVES]
    );
  }
};

/**
 * Generates an array of border objects based on the provided rows with errors.
 *
 * @param {Array} rowWithError - An array of objects representing rows with errors.
 * @returns {Array} - An array of border objects specifying the ranges and styles for the borders.
 */
export function getBorders(rowWithError) {
  let borders = [];
  const commonBorder = {
    width: 1,
    color: 'red'
  };
  rowWithError.forEach(({ rowIdx, colStart, colEnd }) => {
    borders.push({
      range: {
        from: {
          row: rowIdx,
          col: colStart
        },
        to: {
          row: rowIdx,
          col: colEnd
        }
      },
      left: {
        width: colStart === 1 ? 2 : 1,
        color: 'red'
      },
      right: {
        ...commonBorder
      },
      top: {
        ...commonBorder
      },
      bottom: {
        ...commonBorder
      }
    });
  });
  return borders;
}
