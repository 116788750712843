import { FAVOURITE_ACTION_TYPES } from './favouriteActionTypes';
import initialState from '../initial-state';

export default function favourites(state = initialState.favourites, action) {
  switch (action.type) {
    case FAVOURITE_ACTION_TYPES.LOAD_FAVOURITE_SUCCESS:
      return action.favourite;
    case FAVOURITE_ACTION_TYPES.MAKE_FAV_ITEM_OPTIMISTIC:
      return [...state, { ...action.favourite }];
    case FAVOURITE_ACTION_TYPES.REMOVE_FAV_ITEM_OPTIMISTIC:
      return state.filter(favourite => favourite.id != action.favId);
    default:
      return state;
  }
}
