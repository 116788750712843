import React from 'react';
import styles from '../ProductionPlan.module.scss';
import { Backdrop, Box, Button, Fade, Modal } from '@mui/material';
import classes from '../../RawMaterial/RawMaterialTable.module.scss';
import { boxStyle } from '../../ChargeMix/FinalAnalysis/PrintModal';

const CancelModal = ({ open, handleClose, handleConfirm, heat_no }) => {
  return (
    <Modal
      open={open}
      onClose={handleConfirm}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backdropFilter: 'blur(4px)' // Add your desired filter here
        }
      }}
    >
      <Fade in={open}>
        <Box sx={{ ...boxStyle }}>
          <div className={styles.addSuccessModal}>
            <div className={styles.modalHeader} style={{ color: 'red' }}>
              <i className='ri-alert-line'></i>
              <span> Are You Sure ? </span>
            </div>
            <div className={styles.modalDesc}>
              Unsaved Changes for the ongoing Heat{' '}
              <span className={styles.highlightedText}>{heat_no}</span> will be
              lost.
            </div>
            <div className={styles.modalFooter}>
              <button
                type='button'
                onClick={handleClose}
                className={classes.cancelButton}
              >
                <i className='ri-close-line'></i>
                <span>Cancel</span>
              </button>
              <Button
                variant='contained'
                type='button'
                onClick={handleConfirm}
                className={styles.continueButtton}
              >
                <i className='ri-check-line'></i>
                <span style={{ marginLeft: '0.5rem' }}>Okay</span>
              </Button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CancelModal;
