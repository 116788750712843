import React, { lazy } from 'react';
import { Slug } from './permissionConstants';
import {
  ActiveGradeIcon,
  ActivePartsIcon,
  InActivePartsIcon,
  InactiveGradeicon
} from '../../assets/icons/modifiedIcons';
import LoadLazyComponent from '../../components/LoadLazyComponent';
import GradeHeader from '../../componentsV2/Layout/Header/GradeHeader';
import PartsApp from '../../pages/Parts/PartApp';
import PartsHeader from '../../componentsV2/Layout/Header/PartsHeader';

const GradesApp = lazy(() =>
  LoadLazyComponent(() => import('../../pages/GradesApp/GradesApp'))
);

export const Grades_route = {
  name: 'Grades & Parts',
  can_view: [Slug.GRADES, Slug.PARTS],
  child_routes: [
    {
      name: 'Grades',
      icon: InactiveGradeicon,
      active_icon: ActiveGradeIcon,
      component: <GradesApp />,
      header: () => <GradeHeader />,
      permission: [],
      slug: Slug.GRADES,
      path: 'grades/*',
      matchRoutes: '^/grades(?:/.*)?$'
    },
    {
      name: 'Parts',
      icon: InActivePartsIcon,
      active_icon: ActivePartsIcon,
      component: <PartsApp />,
      header: () => <PartsHeader />,
      permission: [],
      slug: Slug.PARTS,
      path: '/parts/*',
      matchRoutes: '^/parts(?:/.*)?$'
      // matchRoutes: '^/part(s|/add|/\\d+/?(edit)?/?)$'
    }
  ]
};
