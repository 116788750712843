import React from 'react';
import styles from './ListingTable.module.scss';
import {
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  Paper
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import RenderConditionally from '../../RenderConditionally';
import { PaginationWrapper } from '../../PaginationWrapper';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

/**
 * renderCellData renders the cell data for a given row and column.
 *
 * @function
 * @param {Object} row - The data of the row.
 * @param {Object} col - The column configuration based upon the row data.
 * @returns {JSX.Element|string} A React JSX element representing the cell data, or a fallback string '-'.
 */
function renderCellData(row, col) {
  const data = col.data(row);
  return col.cell(data) ?? '-';
}

const TableHeadComponent = ({ tableSchema, headerClassNames }) => (
  <TableHead>
    <TableRow
      classes={{
        root: clsx(headerClassNames)
      }}
    >
      {tableSchema.map(column => (
        <TableCell
          key={column.id}
          align={column.align}
          style={{ ...column?.headerStyle, ...column.style }}
        >
          {column.header}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);

const TableBodyComponent = ({
  tableSchema,
  tableData,
  onRowClick,
  changeRowStyle
}) => (
  <TableBody>
    {(tableData || []).map((row, index) => (
      <TableRow
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.05 * index }}
        key={index}
        component={motion.tr}
        classes={{
          root: clsx(
            styles.tableRowEffect,
            onRowClick && styles.onRowClick,
            changeRowStyle && changeRowStyle(row)
          )
        }}
      >
        {tableSchema.map(column => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={
                column.width
                  ? { width: column.width, ...column.style }
                  : { ...column.style }
              }
              onClick={e =>
                !column.disableClickHandler ? onRowClick?.(e, row) : null
              }
            >
              {renderCellData(row, column)}
            </TableCell>
          );
        })}
      </TableRow>
    ))}
  </TableBody>
);

/**
 * A generic listing table component for displaying data in a table format.
 *
 * @component
 * @param {Array} tableSchema - An array defining the table structure and behavior.
 * @param {Object} tableData - The data to be displayed in the table along with the total count and results.
 * @param {Function} onRowClick - A function to handle row click events.
 * @param {Function} changeRowStyle - A function to customize the style of individual rows based on hover effects.
 * @param {number} limit - The maximum number of items per page.
 * @returns {JSX.Element} A React JSX element representing the generic listing table.
 */
const ListingTable = ({
  tableSchema,
  tableData,
  tableCount,
  onRowClick,
  changeRowStyle,
  limit,
  headerClassNames,
  customTableContainerClass,
  customTableId,
  stickyHeader = false
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <TableContainer
        component={Paper}
        classes={{
          root: clsx(styles.listingTableContainer, customTableContainerClass)
        }}
      >
        <Table
          stickyHeader={stickyHeader}
          classes={{ root: clsx(styles.listTable) }}
          id={customTableId}
        >
          <TableHeadComponent
            tableSchema={tableSchema}
            headerClassNames={headerClassNames}
          />
          <TableBodyComponent
            tableSchema={tableSchema}
            tableData={tableData}
            changeRowStyle={changeRowStyle}
            onRowClick={onRowClick}
          />
        </Table>
        <RenderConditionally condition={tableCount && limit}>
          <PaginationWrapper limit={limit} count={tableCount} />
        </RenderConditionally>
      </TableContainer>
    </AnimatePresence>
  );
};
export default ListingTable;
