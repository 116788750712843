export const FURNANCE_CONSTS = {
  MOD_QTY: 'Qty Round Off',
  FURNACE_MERGE_CELL_INDEX: 1,
  ADDITIVES_MERGE_CELL_INDEX: 10,
  NODULARIZATION_MERGE_CELL_INDEX: 21,
  LADLE_MERGE_CELL_INDEX: 24,
  HEEL: 'Heel',
  MELTING_FURNACE_LIQ_WT: 'Melting furnace Liq. metal',
  QTY_RANGE_INDEX_RECOVERY: 5,
  QTY_RANGE_INDEX: 4,
  QTY_SUM_ROW: 0,
  QTY_SUM_COLUMN: 1,
  SPONGE_DRI_IRON: 'DRI / Sponge Iron',
  DRI_IRON: 'DRI',
  SPONGE_IRON: 'Sponge Iron',
  FOUNDRY_RETURN: 'FOUNDRY RETURN'
};
