import RenderConditionally from "../../RenderConditionally";
import classes from './HeaderLayout.module.scss';
import { logout } from '../../../redux/auth/authAction';
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { useState } from "react";
import { sendAnalytics } from "../../../utils/analyticsUtils";


/**
 * Logout Component.
 * 
 * This component provides a logout confirmation UI with the option to stay logged in.
 * It displays a confirmation dialog when the user attempts to log out.
 * 
 * @component
 * @param {Function} logout - The function to handle logout.
 * @param {Object} user - The user details.
 * @returns {JSX.Element} JSX.Element representing the Logout component.
 */
const Logout = ({ logout, user }) => {

    const [logoutConfirmation, setLogoutConfirmation] = useState(false);
    const navigate = useNavigate();


    const handleLogout = () => {
        const analytics_Details = {
          defaultName: `Logout`,
          defaultDetails: `logout :${user.name}`
        };
        sendAnalytics(analytics_Details);
        logout(navigate);
      }


    return <> 
        <RenderConditionally condition={!logoutConfirmation}>
            <div
            className={classes.logoutStyle}
            onClick={(e) => {
                e.stopPropagation();
                setLogoutConfirmation(true)
            }}
            >
                <i className="ri-logout-circle-r-line"></i>
                <div className={classes.btnLogoutText}>Logout</div>
            </div>
            </RenderConditionally>
            <RenderConditionally condition={logoutConfirmation}>
            <div className={classes.logoutConfirmation}>
                <div className={classes.logoutText}>Stay Logged in?</div>
                <div className={classes.logoutBtnContainer}>
                    <button onClick={handleLogout} className={classes.logoutBtn}>
                        <i className="ri-check-line"></i>
                        <span>Logout</span>
                    </button>
                    <button onClick={(e)=>{
                        e.stopPropagation();
                        setLogoutConfirmation(false)
                        }} 
                        className={classes.cancelLogout}>
                        <i className="ri-close-line"></i>
                        <span>Yes</span>
                    </button>
                </div>
            </div>
        </RenderConditionally>
    </>
}

const mapStateToProps = state => ({
    user: state.userDetail,
});

const mapDispatchToProps = {
    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
