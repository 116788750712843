import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import config from '../config/config';

function OrientationPopUp({ modalActive }) {
  const [modal, setModal] = useState(false);
  useEffect(() => {
    setModal(modalActive);
  }, [modalActive]);

  const toggle = () => setModal(!modal);
  return (
    <Modal isOpen={modal} toggle={toggle} className='orientPopUp'>
      <ModalBody>
        <img
          src={`${config.ECOM_IMGIX_URL}/PotraitOrientation.png?auto=format&auto=compress&w=100`}
        />
        <h3>Please rotate your device</h3>
        <p>
          We donot support landscape mode yet. Please go back to Potrait
          orientation for the best experience
        </p>
      </ModalBody>
    </Modal>
  );
}

export default OrientationPopUp;
