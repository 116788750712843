import config from '../config/config';
import { createQueryParam } from '../utils';
import { cFetch, token } from './apiUtils';

/**
 * Fetches a list of grades from the API endpoint.
 *
 * @param {object} apiParams - Object containing query parameters to be added to the API endpoint URL.
 * @returns {object} - Response from the API.
 */
export async function fetchGradeList(apiParams) {
  const queryParam = createQueryParam(apiParams);
  const response = await cFetch(
    `${config.API_URL}/api/c/grades/?${queryParam}`,
    {
      method: 'GET',
      headers: {
        authorization: token()
      }
    }
  );
  return response;
}
/**
 * Fetches the detail of grade from the API endpoint.
 *
 * @param {integer} id - Object containing id of the grade to be added to the API endpoint URL.
 * @returns {object} - Response from the API.
 */
export async function fetchGradeDetail(id) {
  const response = await cFetch(`${config.API_URL}/api/c/grades/${id}/`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
  return response;
}

/**
 * Updates a grade in the API.
 *
 * @param {number} id - The ID of the grade to be updated.
 * @param {object} updatedGrade - The updated grade information to be sent to the API.
 * @returns {object} - Response from the API in JSON format.
 */
export async function updateGradeList(id, updatedGrade) {
  const response = await cFetch(`${config.API_URL}/api/grades/${id}/`, {
    method: 'PATCH',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(updatedGrade)
  });
  return await response.json();
}
