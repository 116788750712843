import React, { useRef } from 'react';
import useQuery from '../../hooks/useQuery';
import { getGradeTag } from './GradeListingTable';
import { ElementBoxes } from '../Spectrometer/SpectroListingTable';
import { getRangeString } from '../../utils';
import styles from './GradeListingTable.module.scss';
import { Radio } from '@mui/material';
import { motion } from 'framer-motion';
import { isEafFurnace } from '../../utils/Grade';

const GradesComposition = ({ data }) => {
  const readingRef = useRef();
  return (
    <div style={{ marginRight: '4rem' }}>
      <div ref={readingRef}>
        <ElementBoxes
          ref={readingRef}
          elements={data.map(d => ({
            ...d,
            element_symbol: d.element__symbol,
            element_composition: getRangeString(d)
          }))}
        />
      </div>
    </div>
  );
};

export const getGradeTableSchema = (query, selectedValue) => [
  {
    id: 'radio',
    data: row => row,
    style: {
      display: query.duplicate ? 'table-cell' : 'none',
      transition: 'display 0.3s ease'
    },
    cell: d => <RadioBtn data={d} selectedValue={selectedValue} />,
    width: '5%'
  },
  {
    header: 'Tag Id',
    id: 'sku',
    data: row => row,
    cell: d => <TagId data={d} />,
    align: 'center',
    width: '7%'
  },
  {
    header: 'Grade',
    id: 'grade',
    data: row => row,
    cell: d => <GradeText data={d} />,
    align: 'left',
    width: '7%'
  },
  {
    header: 'Code',
    id: 'code',
    data: row => row,
    cell: d => <GradeCode data={d} />,
    align: 'left',
    width: '4%'
  },
  {
    header: 'Part Name / No.',
    id: 'partName',
    data: row => row,
    cell: d => <PartName data={d} />,
    align: 'left',
    width: '15%'
  },
  {
    header: 'Raw Materials',
    id: 'rawMaterial',
    data: row => row,
    cell: d => <RawMaterialsCount data={d} />,
    align: 'center',
    width: '12%',
    headerStyle: { textAlign: 'center' }
  },
  {
    header: 'Target Chemistry',
    id: 'targetChemistry',
    data: row => row,
    cell: d => <TargetChemistry data={d} />,
    align: 'left',
    width: '50%'
  }
];

const RadioBtn = ({ data, selectedValue }) => (
  <motion.div initial='hidden' animate='show' exit='hidden'>
    <Radio
      checked={selectedValue == data.id}
      value={data?.id}
      name='radio-buttons'
      inputProps={{ 'aria-label': 'B' }}
    />
  </motion.div>
);

const TagId = ({ data }) => (
  <div className={styles.tagBox}>{`${data?.grade_tag_id}`}</div>
);

const GradeText = ({ data }) => (
  <div className={styles.smallText}>
    {data.name}
    <div className={styles.nameTagContainer}>
      <div>{getGradeTag(data.grade_category?.symbol)}</div>
    </div>
  </div>
);

const GradeCode = ({ data }) => (
  <div className={`${styles.smallText} ${styles.partDetailsContainer}`}>
    <span>{data?.grade_code}</span>
  </div>
);

const PartName = ({ data }) => (
  <div className={`${styles.smallText} ${styles.partDetailsContainer}`}>
    <span>{data?.part_name}</span> <span>{data?.part_no}</span>
  </div>
);

const RawMaterialsCount = ({ data }) => (
  <div className={styles.flexCenter}>
    <div className={styles.rawMaterialCount}>{data?.item_count || 0}</div>
  </div>
);

const TargetChemistry = ({ data }) => {
  const [query, _] = useQuery();
  return (
    <div className={styles.compositionWrapper}>
      <GradesComposition
        data={isEafFurnace(data.furnace_type) ? data.pit_tc : data.grade_tc}
      />
      {!query.duplicate && (
        <div className={styles.switch}>
          <div className={styles.viewButton}>
            View <i className='ri-arrow-right-line'></i>
          </div>
        </div>
      )}
    </div>
  );
};
