import * as slackApi from '../../api/SlackNotificationApi';
import { notify_slack_person } from '../../utils';
import { createFromDefaultAction } from '../actionUtils';

import {
  SLACK_NOTIFICATION,
  SLACK_NOTIFICATION_FROM_SIDEBAR
} from '../actionTypes';

/**
 * This function is used to send notification on slack.
 * @param {string} channel
 * @param {string} message
 */
export function notifyOnSlack(channel, message, meta = {}) {
  return createFromDefaultAction(
    SLACK_NOTIFICATION,
    () => slackApi.notifyOnSlack(channel, message),
    { analytics: meta.analytics },
    { successMsg: meta.successMsg }
  );
}

export function notifySlackFromSideBar(channel, slack_msg, userDetail) {
  const slack_notifier = notify_slack_person(userDetail.customer);
  const message = `${slack_notifier}, ${userDetail.name}(${userDetail.customer.company_name}) ${slack_msg}`;

  return createFromDefaultAction(SLACK_NOTIFICATION_FROM_SIDEBAR, () =>
    slackApi.notifyOnSlack(channel, message)
  );
}
