import React from 'react';
import classes from './ElementBox.module.scss';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { toFixedValue } from '../../utils/ChargeMix';
import { calcReadingInRange } from '../../utils/Spectrometer';


/**
 * Determines the styling and arrow direction for displaying an element's value in a range.
 * @param {Object} params - The parameters for the element range calculation.
 * @param {boolean|null|undefined} params.isEleInRange - Whether the element value is within the range.
 * @param {boolean|null|undefined} params.isEleInTolerance - Whether the element value is within the tolerance range.
 * @param {number|null|undefined} params.eleDeviation - The deviation of the element value from the range.
 * @param {number} params.elementValue - The value of the element.
 * @param {[number, number]} params.elementRange - The range of acceptable values for the element.
 * @returns {Object} An object containing the CSS class name and arrow icon for styling the element.
 */
export const elementInRange = ({isEleInRange, isEleInTolerance, eleDeviation, elementRange, elementValue}) => {
  if(isEleInRange === undefined || isEleInTolerance === undefined || eleDeviation === undefined){
    return calcReadingInRange(elementValue, elementRange);
  }
  else{
    const iconStyle = { fontSize: '16px', color: '#E43E2B' };
    if (isEleInRange == null) {
      return { ele_className: classes.tc_element_box_grey, ele_arrow : null};
    } else if (isEleInRange) {
      return { ele_className: classes.tc_element_box_green, ele_arrow : null };
    } else {
      if (isEleInTolerance) {
        return { ele_className: classes.tc_element_box_warn, ele_arrow : null };
      }
      return {
        ele_className: classes.tc_element_box_red,
        ele_arrow:
          eleDeviation > 0 ? (
            <ArrowUpwardIcon sx={iconStyle} />
          ) : (
            <ArrowDownwardIcon sx={iconStyle} />
          )
      };
    }
  }
}

const index = ({
  elementSymbol,
  elementRange,
  isEleInRange,
  isEleInTolerance,
  eleDeviation,
  elementValue,
  error = null,
  sx = {}
}) => {
  const { ele_className, ele_arrow } = elementInRange({
    isEleInRange,
    isEleInTolerance,
    eleDeviation,
    elementRange,
    elementValue
  });

  return (
    <div key={'el_' + elementSymbol} className={ele_className} style={sx}>
      <div className={classes.ele_symbol_style}>
        <div>{elementSymbol} </div>
        {ele_arrow && <div className={classes.arrow_aling}>{ele_arrow}</div>}
      </div>

      {
        <div className={classes.ele_range_style}>
          {error || toFixedValue(elementValue)}
        </div>
      }
    </div>
  );
};

export default index;
