import initialState from '../initial-state';
import { PRODUCTION_PLAN_ACTION_TYPES } from './productionPlanActionTypes';

export function productionPlanList(
  state = initialState.productionPlanList,
  action
) {
  switch (action.type) {
    case PRODUCTION_PLAN_ACTION_TYPES.GET_PRODUCTION_PLAN_LIST_SUCCESS:
      return action.productionPlanList;
    default:
      return state;
  }
}

export const addPlanCurrentStep = (
  state = initialState.addPlanCurrentStep,
  action
) => {
  switch (action.type) {
    case PRODUCTION_PLAN_ACTION_TYPES.ADD_CURRENT_PLAN_STEP:
      return action.payload;
    default:
      return state;
  }
};

export const addPlanData = (state = initialState.addPlanData, action) => {
  switch (action.type) {
    case PRODUCTION_PLAN_ACTION_TYPES.SET_ADD_PRODUCTION_PLAN_SUCCESS:
      return { ...state, ...action.payload };
    case PRODUCTION_PLAN_ACTION_TYPES.RESET_ADD_PLAN_DATA:
      return action.payload;
    default:
      return state;
  }
};

export const planDetail = (state = initialState.planDetail, action) => {
  switch (action.type) {
    case PRODUCTION_PLAN_ACTION_TYPES.GET_PRODUCTION_PLAN_DETAIL:
      return action.payload;
    case PRODUCTION_PLAN_ACTION_TYPES.RESET_PRODUCTION_PLAN_DETAIL:
      return action.payload;
    default:
      return state;
  }
};
