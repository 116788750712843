import initialState from '../initial-state';
import { CONTROL_PLAN_LIST_ACTION_TYPES } from './controlPlanActionTypes';

export const addControlPlanCurrentStep = (
  state = initialState.addControlPlanCurrentStep,
  action
) => {
  switch (action.type) {
    case CONTROL_PLAN_LIST_ACTION_TYPES.ADD_CONTROL_PLAN_SET_CURRENT_STEP:
      return action.payload;
    default:
      return state;
  }
};

export const addControlPlanData = (
  state = initialState.addControlPlanData,
  action
) => {
  switch (action.type) {
    case CONTROL_PLAN_LIST_ACTION_TYPES.ADD_CONTROL_PLAN_DATA_UPDATE:
      return action.payload;
    default:
      return state;
  }
};
