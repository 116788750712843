import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pathMatch } from '../../../utils';
import classes from './HeaderLayout.module.scss';
import { Breadcrumb } from './GradeHeader';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

/**
 * Header component for the Quality Report section of the application.
 * This component displays a header with dynamic content based on the current route.
 * When viewing test details, it shows an 'ArrowBackIcon', a breadcrumb navigation to 'Grades',
 * and the name of the test with its status color-coded.
 *
 * @returns {JSX.Element} The header component for the Quality Report section, with content changing based on whether test details are being viewed.
 */
const SpectrometerHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const spectroReading = useSelector(state => state.spectroReading);

  const isTestDetails = pathMatch('/spectrometer', '/\\d+', location.pathname);
  if (!isTestDetails)
    return <span className={classes.title}> Spectrometer </span>;

  return (
    <div>
      <div className={classes.breadCrumbContainer}>
        <>
          <Breadcrumb
            navigate={navigate}
            text='Spectrometer'
            path='/spectrometer'
          />
        </>
        <i className='ri-arrow-right-s-line' />
        <span
          className={classes.spectroDetailText}
          style={{ fontSize: isMobile ?? '12px' }}
        >
          {spectroReading.heat_name}
        </span>
        <span className={classes.heatIdText}>| Heat Date:</span>
        <span className={classes.spectroDetailText}>
          {spectroReading.heat_date}
        </span>
      </div>
    </div>
  );
};

export default SpectrometerHeader;
