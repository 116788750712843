import { useState, useEffect, useRef } from 'react';

const useDebouncedApiCall = (searchFn, apiQuery, delay) => {
  const [result, setResult] = useState([]);
  const [resultLoading, setResultLoading] = useState(false);
  const timeoutRef = useRef();

  useEffect(() => {
    setResultLoading(true);
    if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(
      () =>
        searchFn(apiQuery).then(data => {
          setResultLoading(false);
          setResult(data);
        }),
      delay
    );

    // Clear timeout if the components is unmounted or the delay changes:
    return () => window.clearTimeout(timeoutRef.current);
  }, [apiQuery]);
  return [result, resultLoading];
};

export default useDebouncedApiCall;
