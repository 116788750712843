import { PRODUCT_DETAIL_ACTION_TYPES } from './productDetailActionTypes';
import initialState from '../initial-state';

export default function productDetail(
  state = initialState.productDetail,
  action
) {
  switch (action.type) {
    case PRODUCT_DETAIL_ACTION_TYPES.LOAD_PRODUCT_DETAIL_SUCCESS:
      return action.productDetail;
    default:
      return state;
  }
}
