/* eslint-disable react/display-name */
import React from 'react';

const withAnalytics = (Component, prefix) => {
  return props => {
    // globalAnalyticsListener(prefix);
    return <Component {...props} />;
  };
};

export default withAnalytics;
