import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RightArrowIcon } from '../../../assets/icons/modifiedIcons';
import { isMobile } from 'react-device-detect';
import { goBack, pathMatch } from '../../../utils';
import classes from './HeaderLayout.module.scss';
import { Breadcrumb } from './GradeHeader';

/**
 * Renders parts details such as label or part information.
 *
 * @param {string} label - Optional label to display. If present, other part details are ignored.
 * @param {object} partDetail - The part details object containing information like part tag and name.
 * @returns {JSX.Element} A span or a combination of divs showing the part details.
 */
const renderDetails = (label, partDetail) => {
  if (label) {
    return (
      <span style={{ fontSize: '16', color: 'black', whiteSpace: 'nowrap' }}>
        {label}
      </span>
    );
  }
  return (
    <>
      <div className={classes.tagBox}>{partDetail.part_tag_id}</div>
      <div
        className={classes.smallText}
      >{`${partDetail.part_name.name} / ${partDetail.number}`}</div>
    </>
  );
};

/**
 * Component to display the part details in a breadcrumb-like format.
 *
 * @param {object} props - Component props.
 * @param {object} props.partDetail - The details of the part to be displayed.
 * @param {string} props.label - Optional label to display instead of part details.
 * @returns {JSX.Element} A div element representing the part detail display.
 */
const PartDetailDisplay = ({ partDetail, label }) => (
  <div className={classes.breadCrumbContainer}>
    <span>
      <RightArrowIcon fontSize={16} />
    </span>
    {renderDetails(label, partDetail)}
  </div>
);

/**
 * Header component for the Grade section, including navigation and part detail display.
 * Uses redux to manage state and react-router-dom for navigation.
 *
 * @returns {JSX.Element} The header component with conditional rendering based on the current URL path.
 */
const PartsHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentStep = useSelector(state => state.addPartCurrentStep);
  const partDetail = useSelector(state => state.partDetail);

  const isAddPartsPath = pathMatch('/parts', '/add', location.pathname);
  const isPartsViewPath = pathMatch('/parts', '/\\d+', location.pathname);
  const isPartDetailPath = pathMatch('/parts', '/\\d+/edit', location.pathname);

  const getLabel = () => {
    if (isAddPartsPath) {
      return `Add new part - ${currentStep.split('_').join(' ')}`;
    }
    return null;
  };

  if (!isAddPartsPath && !isPartDetailPath && !isPartsViewPath) {
    return <span className={classes.title}>Parts</span>;
  }

  return (
    <div className={classes.headerDiv}>
      <ArrowBackIcon
        className={classes.backActionIcon}
        onClick={() => goBack(navigate, location)}
      />
      <Breadcrumb navigate={navigate} text='Parts' path='/parts' />

      {(isAddPartsPath ||
        ((isPartDetailPath || isPartsViewPath) &&
          Object.keys(partDetail).length > 0 &&
          !isMobile)) && (
        <PartDetailDisplay partDetail={partDetail} label={getLabel()} />
      )}
    </div>
  );
};

export default PartsHeader;
