import { cFetch } from './apiUtils';
import config from '../config/config';

/**
 * this function is used to send notification on slack
 * @param {string} channel
 * @param {string} message
 * return api response .
 */
export function notifyOnSlack(channel, message) {
  return cFetch(`${config.API_URL}/api/send_slack/`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      channel: channel,
      message: message
    })
  });
}
