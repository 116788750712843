import { combineReducers } from 'redux';
import auth from './auth/authReducer';
import apiCallsInProgress from './apiStatus/apiStatusReducer';
import categories from './categories/categoriesReducer';
import subcategories from './subcategories/subcategoriesReducer';
import products from './product/productReducer';
import cartItems from './cartItem/cartItemReducer';
import productDetail from './productDetail/productDetailReducer';
import userDetail from './userDetail/userDetailReducer';
import userDetailById from './userDetail/userDetailByIdReducer';
import favourites from './favourite/favouriteReducer';
import users from './user/userReducer';
import spares from './spare/spareReducer';
import initVal from './init/initReducer';
import inventory from './inventory/inventoryReducer';
import adminViewReducer from './reducers/adminView';
import uploadFileReducer from './reducers/uploadFile';
import chargeMixDemoReducer from './reducers/chargeMixDemo';
import { itemInventory, currentstep } from './chargeMix/chargeMixReducers';
import {
  spectrometerList,
  spectrometer,
  spectrometerOverviewData,
  spectrometerFilterOptions,
  spectroReading,
  spectroReadingAddDil,
  inventoryAddDil,
  itemInventoryByList,
  getItemInventoryDetails
} from './spectro/spectroReducer';
import {
  gradeList,
  gradeDetail,
  addGradeCurrentStep,
  addGradeStepData
} from './grade/gradeReducer';
import {
  partList,
  partDetail,
  addPartCurrentStep,
  addPartStepData
} from './part/partReducer';
import {
  addControlPlanData,
  addControlPlanCurrentStep
} from './controlPlan/controlPlanReducer';
import {
  pendingTestCount,
  testReportDetail,
  testReportList,
  testReportHeaderStat
} from './tests/testReportReducer';
import {
  productionPlanList,
  addPlanData,
  addPlanCurrentStep,
  planDetail
} from './productionPlan/productionPlanReducer';
import { analyticsKey } from './Analytics/analyticsReducer';
import { createFromDefaultReducer } from '../redux/reducers';
import * as actionTypes from './actionTypes';
import toggleSidebar from './reducers/toogleSidebar';
import { heatLogDetail } from './heatLog/heatLogReducer';

const appReducer = combineReducers({
  auth,
  apiCallsInProgress,
  categories,
  subcategories,
  products,
  cartItems,
  shippingaddress: createFromDefaultReducer(actionTypes.LOAD_ADDRESS_BY_ID),
  orders: createFromDefaultReducer(actionTypes.LOAD_ORDER),
  productDetail,
  userDetail,
  userDetailById,
  orderDetail: createFromDefaultReducer(actionTypes.LOAD_ORDER_DETAILS),
  uploadFile: uploadFileReducer,
  favourites,
  users,
  spares,
  initVal,
  gradeList,
  gradeDetail,
  partList,
  partDetail,
  chargeMix: combineReducers({
    read: createFromDefaultReducer(actionTypes.FETCH_CHARGEMIX),
    draft_cm_data: createFromDefaultReducer(
      actionTypes.DRAFT_CHARGEMIX_CHANGES
    ),
    all: createFromDefaultReducer(actionTypes.FETCH_ALL_CHARGEMIX),
    list: createFromDefaultReducer(actionTypes.FETCH_CHARGEMIX_LIST),
    cm_elements: createFromDefaultReducer(actionTypes.FETCH_CHARGEMIX_ELEMENTS),
    overview: createFromDefaultReducer(actionTypes.CHARGEMIX_OVERVIEW),
    demo: chargeMixDemoReducer
  }),

  chargeMixSuggestion: createFromDefaultReducer(
    actionTypes.FETCH_CHARGEMIX_SUGGESTIONS
  ),

  powerInsight: combineReducers({
    liveData: createFromDefaultReducer(actionTypes.FETCH_PI_LIVE_DATA),
    shiftData: createFromDefaultReducer(actionTypes.FETCH_PI_SHIFT_DATA),
    furnace: createFromDefaultReducer(actionTypes.FETCH_FURNACE)
  }),

  currentstep,
  inventory,
  itemInventory,
  itemInventoryByList,
  spectrometerList,
  productionPlanList,
  addPlanData,
  planDetail,
  addPlanCurrentStep,
  analyticsKey,
  spectrometer,
  spectrometerOverviewData,
  spectrometerFilterOptions,
  spectroReading,
  spectroReadingAddDil,
  inventoryAddDil,
  inventoryDetails: getItemInventoryDetails,
  adminView: adminViewReducer,
  toggleSidebar,
  addGradeCurrentStep,
  addGradeStepData,
  addPartCurrentStep,
  addPartStepData,
  pendingTestCount,
  testReportList,
  testReportDetail,
  testReportHeaderStat,
  addControlPlanData,
  addControlPlanCurrentStep,
  heatLogDetail
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state [Ref: https://medium.com/@asher.cassetto.cohen/resetting-redux-state-with-a-root-reducer-bonus-how-to-reset-state-selectively-e2a008d0de61]
  if (action.type === 'LOGOUT_APP') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
