import { USER_ACTION_TYPES } from './userActionTypes';
import initialState from '../initial-state';

export default function users(state = initialState.users, action) {
  switch (action.type) {
    case USER_ACTION_TYPES.LOAD_USER_SUCCESS:
      return action.user;
    default:
      return state;
  }
}
