export const COMMON_ACTION_TYPES = {
  BASIC: 'BASIC',
  FURNACE: 'FURNACE',
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  DELIVERED: 'Delivered',
  CANCELLED: 'Cancelled',
  FINAL_OUTPUT: 'FINAL_OUTPUT',
  FOUNDRY_PLATFORM: 'FOUNDRY_PLATFORM',
  BOOK_MC_DEMO: 'BOOK_MC_DEMO',
  ADDITIVES: 'ADDITIVES',
  LADLE: 'LADLE',
  NODULARIZER: 'NODULARIZER',
  FINAL_UPDATE: 'FINAL_UPDATE',
  CHARGE_MIX: 'CHARGE_MIX',
  TEST_CHANNEL: 'TEST_CHANNEL',
  RAISE_CHAT_REQUEST: 'RAISE_CHAT_REQUEST',
  BOOK_DEMO: 'BOOK_DEMO',
  DOWNLOAD: 'DOWNLOAD',
  LOGIN_FAILED: 'LOGIN_FAILED',
  SALES_QUOTED: 'SALES QUOTED',
  ORDER_PLACED: 'ORDER PLACED',
  PENDING: 'PENDING',
  ENGAGEMENT: 'ENGAGEMENT',
  ADD_DILUTE: 'ADD_DILUTE',
  SPECTROMETER_NOTI: 'SPECTROMETER_NOTI'
};
