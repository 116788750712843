import React from 'react';
import styles from './StretchContainer.module.css';

const StretchContainer = ({ sx, children, noPadding }) => {
  return (
    <div
      style={{ padding: noPadding && '0px', ...sx }}
      className={ styles.overrideLayoutOpen }
    >
      {children}
    </div>
  );
};

export default StretchContainer;
