export const rfqAmplitudeEvents = {
  eventName: 'RFQ',
  eventAction: 'QUOTE_RAISE-RFQ',
  eventLabel: 'RFQ Raised from Quote Page'
};

export const rfqGaEvents = clientDetail => ({
  eventName: 'RFQ',
  eventAction: 'RFQ GENERATED',
  eventLabel: `Rfq generated. Client Name: ${clientDetail.client_name}.`
});
