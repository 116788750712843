export const CHARGEMIX_ACTION_TYPES = {
  GET_CHARGEMIX_SUCCESS: 'GET_CHARGEMIX_SUCCESS',
  ADD_CHARGEMIX_SUCCESS: 'ADD_CHARGEMIX_SUCCESS',
  UPDATE_CHARGEMIX_SUCCESS: 'UPDATE_CHARGEMIX_SUCCESS',
  GET_CHARGEMIX_ELEMENTS_SUCCESS: 'GET_CHARGEMIX_ELEMENTS_SUCCESS',
  CURRENT_STEP_UPDATE: 'CURRENT_STEP_UPDATE',
  GET_CHARGEMIX_LIST_SUCCESS: 'GET_CHARGEMIX_LIST_SUCCESS',
  GET_CHARGEMIX_WITH_ID_SUCCESS: 'GET_CHARGEMIX_WITH_ID_SUCCESS',
  UPDATE_CHARGEMIX_LIST_SUCCESS: 'UPDATE_CHARGEMIX_LIST_SUCCESS',
  CREATE_CHARGEMIX: 'CREATE_CHARGEMIX',
  ITEM_INVENTORY_LIST_OPTIMISTIC: 'ITEM_INVENTORY_LIST_OPTIMISTIC',
  DEMO_MODE_UPDATE: 'DEMO_MODE_UPDATE',
  CLONE_CHARGEMIX_SUCCESS: 'CLONE_CHARGEMIX_SUCCESS',
  DRAFT_CHARGEMIX_CHANGES: 'DRAFT_CHARGEMIX_CHANGES',
  FINAL_OUTPUT: 'FINAL_OUTPUT'
};
