import React, { lazy } from 'react';
import { Slug } from './permissionConstants';
import {
  ActiveDashBoardIcon,
  ActiveSpectrometerIcon,
  DashboardIcon,
  SpectrometerIcon
} from '../../assets/icons/modifiedIcons';
import LoadLazyComponent from '../../components/LoadLazyComponent';
import SpectrometerHeader from '../../componentsV2/Layout/Header/SpectrometerHeader';

const SpectroApp = lazy(() =>
  LoadLazyComponent(() => import('../../pages/Spectrometer/SpectroApp'))
);

const SpectroAnalytics = lazy(() =>
  LoadLazyComponent(() => import('../../pages/Analytics/Spectrometer'))
);

export const Spectrometer_route = {
  name: 'SPECTROMETER',
  can_view: [Slug.SPECTROMETER],
  child_routes: [
    {
      name: 'Dashboard',
      icon: DashboardIcon,
      active_icon: ActiveDashBoardIcon,
      component: <SpectroAnalytics />,
      path: '/',
      permission: [],
      slug: Slug.SPECTROMETER,
      matchRoutes: '^/$',
      header: () => 'Dashboard'
    },
    {
      name: 'Spectrometer',
      icon: SpectrometerIcon,
      active_icon: ActiveSpectrometerIcon,
      component: <SpectroApp />,
      path: 'spectrometer/*',
      slug: Slug.SPECTROMETER,
      matchRoutes: '/spectrometer(/d*)?',
      permission: [],
      header: () => <SpectrometerHeader />
    }
  ]
};
