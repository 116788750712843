import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config from './config/config';

Sentry.init({
  dsn: `https://${config.SENTRY_PRIVATE_KEY}.ingest.sentry.io/${config.SENTRY_PUBLIC_KEY}`,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === 'production'
});
