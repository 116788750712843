import clsx from 'clsx';
import React, { memo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';
import styles from '../../Grades/GradeListingTable.module.scss';
import ListingTable from '../../common/ListingTable/ListingTable';
import { getSpectroTableSchema } from './SpectroTableSchema';

const SpectroWebTable = ({ spectrometerList }) => {
  const [query, _] = useQuery();
  const navigate = useNavigate();

  const tableSchema = getSpectroTableSchema();

  return (
    <ListingTable
      customTableContainerClass={styles.spectrometerTable}
      tableSchema={tableSchema}
      changeRowStyle={() => clsx(styles.hoverEffectRow, styles.spectroTableRow)}
      headerClassNames={styles.headerStyles}
      tableData={spectrometerList?.results || []}
      tableCount={spectrometerList?.count}
      limit={query?.page_size || 50}
      onRowClick={(e, row) => {
        navigate(`${row.id}`);
      }}
    />
  );
};

/**
 *function to access redux state
 *
 * @param {*} state state of redux
 * @returns {*} Data object from redux store
 */
function mapStateToProps(state) {
  return {
    spectrometerList: state.spectrometerList,
    userDetail: state.userDetail
  };
}
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(SpectroWebTable));
