export const cloneChargeMixAmplitudeEvents = chargeMix => ({
  eventName: 'Clone ChargeMix',
  eventAction: 'CHARGEMIX_CLONED',
  eventLabel: `${chargeMix.cm_name} cloned successfully`
});

export const cloneChargeMixGaEvents = chargeMix => ({
  eventName: 'Clone ChargeMix',
  eventAction: 'CHARGEMIX_CLONED',
  eventLabel: `${chargeMix.cm_name} cloned successfully`
});
