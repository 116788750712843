import React from 'react';
import { useSelector } from 'react-redux';
import RenderConditionally from '../../RenderConditionally';
import { useNavigate } from 'react-router-dom';
import classes from './SidebarLayout.module.scss';
import { motion } from 'framer-motion';
import { sendAnalytics } from '../../../utils/analyticsUtils';
import { notifySlackFromSideBar } from '../../../redux/actions/slackNotification';
import { toast } from 'react-toastify';
import config from '../../../config/config';
import { COMMON_ACTION_TYPES } from '../../../redux/commonActionTypes';
import { useDispatch } from 'react-redux';
import {
  AboutSoftwareIcon,
  BookADemoIcon,
  BrochureIcon
} from '../../../assets/icons/modifiedIcons';

const { FOUNDRY_PLATFORM, BOOK_DEMO, DOWNLOAD } = COMMON_ACTION_TYPES;

const SidebarOverlay = ({ setOpenModal }) => {
  const userDetail = useSelector(state => state.userDetail);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * Sends a notification to Slack, logs analytics, and displays a toast message.
   *
   * @param {Object} options - Notification options.
   * @param {string} options.slackMsg - The message to be sent to Slack.
   * @param {string} options.notification - The type of notification.
   * @param {string} options.downloaditem - The filename for download (optional).
   */
  const notifySlack = ({
    slackMsg,
    notification: type,
    downloaditem: filename
  }) => {
    // Logs analytics for the Slack notification.
    const analytics_Details = {
      amplitudeName: `${type}${filename ? `_` + filename : ''}`,
      amplitudeDetails: `${type}${filename ? `_` + filename : ''}`
    };

    // Send analytics data
    sendAnalytics(analytics_Details);

    // Display a toast message
    toast.dark(
      filename === ''
        ? 'Thanks for connecting with us. Our Sales Team will contact you soon.'
        : 'Downloading will start soon...',
      {
        autoClose: 6000,
        position: 'bottom-center',
        hideProgressBar: true
      }
    );

    // Notify Slack using Redux action
    dispatch(notifySlackFromSideBar(FOUNDRY_PLATFORM, slackMsg, userDetail));
  };

  const handleDownload = link => {
    if (link !== '#') {
      window.open(link, '_blank', 'noreferrer, noopener');
    }
  };

  const onBrochureClick = () => {
    const analytics_Details = {
      defaultName: `NP_Brochure`,
      defaultDetails: `path : ${`${config.IMGIX_URL}NP_Ad_FinalArtwork.pdf`}`
    };

    sendAnalytics(analytics_Details);
    notifySlack({
      name: 'Download ',
      icon: BrochureIcon,
      notification: DOWNLOAD,
      downloaditem: 'NP_Brochure',
      downloadLink: `${config.IMGIX_URL}NP_Ad_FinalArtwork.pdf`,
      slackMsg: ` downloaded NP_Brochure. Contact him/her at ${userDetail.mobile} within 1hr.`,
      can_view: true,
      tool_text: 'Download'
    });
    handleDownload(`${config.IMGIX_URL}NP_Ad_FinalArtwork.pdf`);
  };

  const onContactUs = () => {
    const analytics_Details = {
      defaultName: `CONTACT US`,
      defaultDetails: `path : #`
    };

    sendAnalytics(analytics_Details);
    notifySlack({
      name: 'Contact',
      icon: BookADemoIcon,
      notification: BOOK_DEMO,
      downloaditem: '',
      downloadLink: '#',
      slackMsg: ` requested for support. Contact him/her at ${userDetail.mobile} within 12 hrs.`,
      can_view: true,
      tool_text: 'Contact'
    });
    handleDownload('#');
  };

  const handleAboutSoftware = () => {
    const analytics_Details = {
      defaultName: `ChargeMix Optimization Whitepaper`,
      defaultDetails: `path : ${`${config.IMGIX_URL}Charge+Mix+Optimization+whitepaper.pdf`}`
    };

    sendAnalytics(analytics_Details);
    notifySlack({
      name: 'About Software',
      icon: AboutSoftwareIcon,
      notification: DOWNLOAD,
      downloaditem: 'ChargeMix Optimization Whitepaper',
      downloadLink: `${config.IMGIX_URL}Charge+Mix+Optimization+whitepaper.pdf`,
      slackMsg: ` downloaded ChargeMix Optimization Whitepaper. Contact him/her at ${userDetail.mobile} within 1hr.`,
      can_view: true,
      tool_text: 'About Software'
    });
    handleDownload(`${config.IMGIX_URL}Charge+Mix+Optimization+whitepaper.pdf`);
  };

  const handlePrivacyPolicy = () => {
    setOpenModal(true);
    const analytics_Details = {
      defaultName: `Privacy policy`,
      defaultDetails: 'privacy policy click'
    };
    sendAnalytics(analytics_Details);
  };

  return (
    <motion.div initial={{ x: '100%', opacity: 0}} animate={{ x: '0%', opacity: 1}} className={classes.overlayContainer}>
      <RenderConditionally
        condition={userDetail.is_staff || userDetail.is_superuser}
      >
        <div
          className={classes.btnContainer}
          onClick={() => navigate('/internalAnalytics')}
        >
          Internal Analytics
        </div>
        <hr />
      </RenderConditionally>
      <div className={classes.btnContainer}>
        <div className={classes.bottom_btn} onClick={onContactUs}>
          <i className='ri-phone-line'></i>
          <span>Contact Us</span>
        </div>
        <div className={classes.bottom_btn} onClick={onBrochureClick}>
          <i className='ri-download-line'></i>
          <span>Brochure</span>
        </div>
        <div className={classes.bottom_btn} onClick={handleAboutSoftware}>
          <i className='ri-information-line'></i>
          <span>About Software</span>
        </div>
        <div className={classes.bottom_btn} onClick={handlePrivacyPolicy}>
          <i className='ri-shield-user-line'></i>
          <span>Privacy Policy</span>
        </div>
      </div>
    </motion.div>
  );
};

export default SidebarOverlay;
