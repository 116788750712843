import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { HotTable } from '@handsontable/react';
import { cFetch, token } from '../../api/apiUtils';
import { Table } from 'reactstrap';
import { toast } from 'react-toastify';
import { COMMON_ACTION_TYPES } from '../../redux/commonActionTypes';
import config from '../../config/config';
import { toFixedValue } from '../../utils/ChargeMix';

const TargetChemistryComponent = ({ chargeMix, cm_elements, setData }) => {
  const cm_element_array = cm_elements?.map(ele => ele.symbol);
  const headers = ['Element', 'Min', 'Max'];
  const updateIndex = ['element', 'min', 'max'];

  const [initData, setIntData] = useState([[]]);
  const [localData, setLocalData] = useState([]);

  const handsOnRef = useRef(null);

  useEffect(() => {
    if (chargeMix) {
      const elementSymbolById = cm_elements.reduce(
        (elsSym, ele) => ({ ...elsSym, [ele.id]: ele.symbol }),
        {}
      );

      const initDataUpdated = chargeMix.cm_target_chemistry.map(tc => {
        const element = elementSymbolById[tc['element']];

        if (tc['ele_range'].indexOf('-') > -1) {
          const range = tc['ele_range'].split('-');
          return [element, range[0], range[1]];
        } else {
          const range = tc['ele_range'];
          return [element, range, range];
        }
      });
      setIntData(initDataUpdated);

      const newData = initDataUpdated.map(ele => ({
        element: ele[0],
        min: ele[1],
        max: ele[2]
      }));
      setLocalData(newData);
    }
  }, [chargeMix]);

  const setBorder = rowWithErrors => {
    const hot = handsOnRef.current.hotInstance;
    const customBordersPlugin = hot.getPlugin('customBorders');
    let borders = [];
    rowWithErrors.forEach(rowIndex => {
      borders.push({
        range: {
          from: {
            row: rowIndex,
            col: 0
          },
          to: {
            row: rowIndex,
            col: headers.length
          }
        },
        left: {
          width: 1,
          color: 'red'
        },
        right: {
          width: 1,
          color: 'red'
        },
        top: {
          width: 1,
          color: 'red'
        },
        bottom: {
          width: 1,
          color: 'red'
        }
      });
    });

    customBordersPlugin.createCustomBorders(borders);
    hot.render();
  };

  const clearBorder = changes => {
    const hot = handsOnRef.current.hotInstance;
    const customBordersPlugin = hot.getPlugin('customBorders');
    changes.forEach(([row, prop, oldValue, newValue]) => {
      customBordersPlugin.clearBorders([[row, 0, row, headers.length]]);
    });
  };

  const handleChange = (changes, source) => {
    if (changes && source != 'loadData') {
      const hot = handsOnRef.current.hotInstance;

      let newData = [...localData];
      let rowWithErrors = [];
      changes.forEach(rowChanges => {
        if (newData[rowChanges[0]]) {
          newData = newData.map((ele, index) =>
            index == rowChanges[0]
              ? { ...ele, [updateIndex[rowChanges[1]]]: rowChanges[3] }
              : ele
          );
        } else {
          newData[rowChanges[0]] = {
            [updateIndex[rowChanges[1]]]: rowChanges[3]
          };
        }
        const rowData = hot.getData(rowChanges[0], 0, rowChanges[0], 2)[0];
        if (rowData[0] || rowData[1] || rowData[2])
          if (!rowData[0] || (!rowData[1] && !rowData[2]))
            rowWithErrors.push(rowChanges[0]);
        if (cm_element_array.indexOf(rowData[0]) == -1)
          rowWithErrors.push(rowChanges[0]);
      });
      setBorder(rowWithErrors);
      setData(newData);
      setLocalData(newData);
    }
  };

  const handleLoad = (sourceData, initialLoad) => {
    if (!initialLoad && sourceData) {
      const filteredData = sourceData.filter(
        ele => ele.filter && ele.filter(cell => cell).length
      );
      const newData = filteredData.map(ele => ({
        element: ele[0],
        min: ele[1],
        max: ele[2]
      }));
      setData(newData);
    }
  };

  return (
    <div style={{ maxWidth: 400, borderBottom: 'none', margin: '0 auto' }}>
      <HotTable
        ref={handsOnRef}
        settings={{
          data: initData,
          minCols: 3,
          maxCols: 3,
          minRows: 10,
          columns: [
            {
              type: 'autocomplete',
              source: cm_element_array,
              strict: true,
              visibleRows: 5,
              className: 'htLeft'
            },
            {
              type: 'numeric',
              className: 'htCenter'
            },
            {
              type: 'numeric',
              className: 'htCenter'
            }
          ],
          colHeaders: headers,
          rowHeaders: true,
          outsideClickDeselects: true,
          stretchH: 'all',
          autoColumnSize: true,
          fixedColumnsLeft: 1,
          height: '255px',
          contextMenu: true,
          afterChange: handleChange,
          afterLoadData: handleLoad,
          beforeChange: clearBorder,
          licenseKey: 'non-commercial-and-evaluation'
        }}
      />
    </div>
  );
};

const mapStateToPropsTC = state => {
  return {
    chargeMix: state.chargeMix.read.data,
    cm_elements: state.chargeMix.cm_elements.data
  };
};

export const TargetChemistry = connect(mapStateToPropsTC)(
  TargetChemistryComponent
);

export function formatRange(ele_range) {
  if (ele_range.indexOf('-') > -1) {
    let ele_range_split = ele_range.split('-');
    let join_del = '-';
    if (ele_range_split[0] == ele_range_split[1]) return ele_range_split[0];
    if (ele_range_split[0] === '') {
      ele_range_split[0] = 'max';
      join_del = ' ';
    } else {
      ele_range_split[0] = parseFloat(ele_range_split[0]);
    }
    if (ele_range_split[1] && ele_range_split[1] === '') {
      ele_range_split[1] = 'min';
      join_del = ' ';
    } else {
      ele_range_split[1] = parseFloat(ele_range_split[1]);
    }
    return ele_range_split.join(join_del);
  }
  return ele_range;
}

export function MetaReport({
  metaData,
  furnaceSize,
  chargeMix: { cm_target_chemistry },
  currentStep
}) {
  const tcByElement = cm_target_chemistry.reduce((tc, ele) => {
    tc[ele.element] = ele.ele_range;
    return tc;
  }, {});
  if (!metaData || Object.keys(metaData).length == 0) return null;

  return (
    <Table>
      <thead>
        <tr>
          <td></td>
          {cm_target_chemistry.map(({ element__symbol }) => (
            <th key={element__symbol}>{element__symbol}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Target chemistry: </td>
          {cm_target_chemistry.map(({ element }) => (
            <td key={element}>{formatRange(tcByElement[element])}</td>
          ))}
        </tr>
        <RenderConditionally condition={furnaceSize}>
          <tr>
            <td>
              {currentStep == COMMON_ACTION_TYPES.FURNACE
                ? 'User Bath Calculations:'
                : 'Final chemistry:'}
            </td>
            {cm_target_chemistry.map(({ element }) => (
              <td key={element}>
                {metaData[element] &&
                  (metaData[element] / furnaceSize).toFixed(4)}
              </td>
            ))}
          </tr>
        </RenderConditionally>
      </tbody>
    </Table>
  );
}

export function getData(hot, target_chemistry, flatHeader) {
  const apiHeader = {
    Product: 'item_name',
    Rate: 'price',
    Qty: 'qty',
    id: 'id',
    Min: 'min_qty',
    Max: 'max_qty'
  };

  const elements = target_chemistry.map(ele => ele.element__symbol);
  const elementsBySymbol = target_chemistry.reduce((tc, ele) => {
    tc[ele.element__symbol] = ele.element;
    return tc;
  }, {});

  const sourced_data = hot.getSourceData();
  let rowWithErrors = [];
  const data = sourced_data
    .map((row, rowIndex) => {
      let rowData = Object.keys(row).reduce((tc, key) => {
        const header = flatHeader[key];

        if (row[key] != '' && row[key] != null) {
          if (elements.indexOf(header) > -1) {
            if (!tc['cm_recovery_rate']) tc['cm_recovery_rate'] = [];
            let rr = {
              element: elementsBySymbol[header],
              rate: Math.round(parseFloat(row[key]) * 100, 2) / 100
            };
            tc['cm_recovery_rate'].push(rr);
          } else if (header == 'Min' || header == 'Max') {
            tc[apiHeader[header]] =
              Math.round(parseFloat(row[key]) * 100, 2) / 100;
          } else {
            tc[apiHeader[header]] = row[key];
          }
        }
        return tc;
      }, {});
      const { id, ...rowData_without_id } = rowData;
      if (Object.keys(rowData_without_id).length > 0) {
        if (
          !rowData['item_name'] ||
          !rowData['price'] ||
          !rowData['qty'] ||
          !rowData['cm_recovery_rate']
        ) {
          rowWithErrors.push(rowIndex);
        }
        if (!rowData['min_qty'] && rowData['min_qty'] != 0)
          rowData['min_qty'] = null;
        if (!rowData['max_qty'] && rowData['max_qty'] != 0)
          rowData['max_qty'] = null;
      } else {
        rowData = {};
      }
      return rowData;
    })
    .filter(row => Object.keys(row).length);
  // console.log("data", data);
  if (rowWithErrors.length) {
    const customBordersPlugin = hot.getPlugin('customBorders');
    let borders = [];
    rowWithErrors.forEach(rowIndex => {
      borders.push({
        range: {
          from: {
            row: rowIndex,
            col: 1
          },
          to: {
            row: rowIndex,
            col: flatHeader.length
          }
        },
        left: {
          width: 1,
          color: 'red'
        },
        right: {
          width: 1,
          color: 'red'
        },
        top: {
          width: 1,
          color: 'red'
        },
        bottom: {
          width: 1,
          color: 'red'
        }
      });
    });

    customBordersPlugin.createCustomBorders(borders);
    hot.render();
  }

  if (data.length == 0) {
    toast.error("Spreadsheet can't be left empty.");
  }
  return { data, rowWithErrors };
}

function filter_cm_item(cm_items, elements, type) {
  return cm_items
    .filter(ele => ele.cm_type == type)
    .map(ele => {
      var initalData = [
        ele.id ? ele.id : '',
        ele.item_name,
        ele.price,
        ele.qty
      ];
      elements.forEach(element => {
        let ele_recovery = ele.cm_recovery_rate.filter(
          rr_ele => element == rr_ele.element && rr_ele.rate
        );
        initalData = [
          ...initalData,
          ele_recovery.length > 0 ? ele_recovery[0].rate : ''
        ];
      });
      initalData = [...initalData, ele.min_qty || ''];
      initalData = [...initalData, ele.max_qty || ''];
      return initalData;
    });
}

function HandsOnTableComponent({
  chargeMix,
  cm_step,
  hotTableRef,
  header,
  flatHeader,
  setMetaReport,
  metaReport,
  furnaceSize,
  rows,
  initFinalChemistry
}) {
  const [data, setData] = useState([]);
  const rowsProp = rows ? { minRows: rows, maxRows: rows } : { minRows: 15 };
  const elementsById = chargeMix.cm_target_chemistry.map(ele => ele.element);

  const elementsBySymbol = chargeMix.cm_target_chemistry.reduce((tc, ele) => {
    tc[ele.element__symbol] = ele.element;
    return tc;
  }, {});

  useEffect(() => {
    setData(filter_cm_item(chargeMix.cm_items, elementsById, cm_step));
  }, [chargeMix]);

  const clearBorder = changes => {
    const hot = hotTableRef.current.hotInstance;
    const customBordersPlugin = hot.getPlugin('customBorders');
    changes.forEach(([row, prop, oldValue, newValue]) => {
      customBordersPlugin.clearBorders([[row, 1, row, flatHeader.length]]);
    });
  };

  const calculateChemistry = () => {
    if (hotTableRef.current) {
      const QtyIndex = flatHeader.indexOf('Qty');
      const hot = hotTableRef.current.hotInstance;
      const filtered_data = hot
        .getData()
        .filter(row => row.filter(cell => (cell ? true : false)).length);

      if (filtered_data.length) {
        const checkQtyExists = filtered_data.every(row =>
          row[QtyIndex] ? true : false
        );
        let metaData = filtered_data.reduce(
          (mD, row) => {
            row.forEach((cell, key) => {
              const header = flatHeader[key];
              if (elementsBySymbol[header]) {
                if (!mD[elementsBySymbol[header]])
                  mD[elementsBySymbol[header]] = 0;
                if (checkQtyExists && cell) {
                  mD[elementsBySymbol[header]] +=
                    parseFloat(cell) * parseFloat(row[QtyIndex]);
                }
              }
            });
            return mD;
          },
          { ...initFinalChemistry }
        );
        setMetaReport(metaData);
      }
    }
  };

  const updateMetaReport = (changes, source) => {
    if (
      setMetaReport &&
      changes &&
      furnaceSize &&
      (source != 'loadData' || Object.keys(metaReport).length == 0)
    ) {
      calculateChemistry();
    }
  };

  const afterLoadData = (sourceData, initialLoad) => {
    if (setMetaReport && Object.keys(metaReport).length == 0) {
      calculateChemistry();
    }
  };

  return (
    <div className='HandsonTableWrapper final_review'>
      <HotTable
        ref={hotTableRef}
        settings={{
          ...rowsProp,
          data: data,
          minCols: flatHeader.length,
          maxCols: flatHeader.length,
          columns: flatHeader.map((ele, index) => {
            if (index != 1) {
              return { type: 'text' };
            } else {
              return {
                type: 'autocomplete',
                source: function (query, processKeywords) {
                  if (query) {
                    cFetch(
                      `${config.API_URL}/api/c/inventory/?search=${query}`,
                      {
                        method: 'GET',
                        headers: {
                          authorization: token()
                        }
                      }
                    ).then(data => {
                      processKeywords(data.map(inv => inv.name));
                    });
                  } else {
                    processKeywords([]);
                  }
                },
                strict: false
              };
            }
          }),
          hiddenColumns: {
            // set columns that are hidden by default
            columns: [0]
          },
          nestedHeaders: header,
          rowHeaders: true,
          outsideClickDeselects: true,
          stretchH: 'all',
          autoColumnSize: true,
          fixedColumnsLeft: 1,
          height: '350px',
          beforeChange: clearBorder,
          afterChange: updateMetaReport,
          afterLoadData,
          licenseKey: 'non-commercial-and-evaluation'
        }}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    chargeMix: state.chargeMix.read.data,
    cm_step: state.currentstep
  };
}

export default connect(mapStateToProps)(HandsOnTableComponent);
