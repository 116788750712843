import React, { useState } from 'react';
import { motion } from 'framer-motion';
import clsx from 'clsx';
import UpgrateToProModal from '../../../pages/UpgrateToProModal';
import MetalCloudLogo from '../../../assets/images/logo/MetalCloudLogo.png';
import classes from './SidebarLayout.module.scss';
import { sendAnalytics } from '../../../utils/analyticsUtils';
import TermsAndConditions from '../../../pages/TermsAndCondition';
import RenderConditionally from '../../RenderConditionally';
import SidebarFooter from './SidebarFooter';
import SidebarOverlay from './SidebarOverlay';
import SidebarNavigationList from './SidebarNavigationList';
import { isMobile } from 'react-device-detect';

const SideBar = () => {
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [openTnCModal, setOpenTnCModal] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);

  const sidebarVariants = {
    open: {
      width: 200,
      transition: { type: 'spring', stiffness: isMobile ? 125 : 100 }
    },
    closed: {
      width: isMobile ? 0 : 64,
      transition: { type: 'spring', stiffness: isMobile ? 50 : 100 }
    }
  };

  const sidebarIconClass = sidebarOpen
    ? isMobile
      ? 'ri-close-fill'
      : 'ri-menu-3-fill'
    : 'ri-menu-2-line';

  const handleSidebarToggle = () => {
    setSidebarOpen(pre => !pre);
    const analytics_Details = {
      defaultName: `Hamburger menu`,
      defaultDetails: `sidebar open/close :${sidebarOpen}`
    };
    sendAnalytics(analytics_Details);
  };

  if (!localStorage.getItem('tokenv2')) return null;

  return (
    <motion.div
      className={clsx(
        classes.sidebarContainer,
        !sidebarOpen && classes.closedSidebarContainer
      )}
      animate={sidebarOpen ? 'open' : 'closed'}
      variants={sidebarVariants}
    >
      <UpgrateToProModal
        open={openUpgradeModal}
        setOpen={setOpenUpgradeModal}
      />
      <TermsAndConditions
        open={openTnCModal}
        setOpen={setOpenTnCModal}
        handleClick={() => setOpenTnCModal(false)}
      />
      <div>
        <motion.div
          initial={{ x: '100%', opacity: 0 }}
          animate={{ x: '0%', opacity: 1 }}
          className={classes.logoNameWrapper}
        >
          <button
            className={classes.openButtonStyles}
            onClick={handleSidebarToggle}
          >
            <i className={sidebarIconClass}></i>
          </button>
          <img src={MetalCloudLogo} />
        </motion.div>
        <SidebarNavigationList
          handleSidebarToggle={handleSidebarToggle}
          setOpen={setOpenUpgradeModal}
          sidebarOpen={sidebarOpen}
        />
      </div>
      <RenderConditionally condition={sidebarOpen}>
        <SidebarOverlay setOpenModal={setOpenTnCModal} />
        <SidebarFooter />
      </RenderConditionally>
    </motion.div>
  );
};

export default SideBar;
