import React from 'react';

import './ErrorBoundary.css';
import { MCLogoIcon } from '../../assets/icons/modifiedIcons';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(`${error}, Info: ${JSON.stringify(errorInfo)}`);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{ display: 'grid', placeItems: 'center', minHeight: '100vh' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <div className='iconLogo'>
              <MCLogoIcon fontSize={280} />
            </div>

            <div
              className='errorText'
              style={{ margin: '00px 0 5px 0', fontSize: '1.5rem' }}
            >
              Oops! Something went wrong.
            </div>
            <button
              className='errorBoundaryBtn'
              onClick={() => window.location.reload()}
              style={{ minWidth: 120, fontSize: '1rem' }}
            >
              Retry
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
