import {
  actionStartType,
  actionSuccessType,
  actionFailedType,
  actionClearType
} from '../actionUtils/actionTypes';

const getInitialState = () => ({
  isLoading: true,
  data: null,
  error: null
});

export const createFromDefaultReducer =
  reducerName =>
  (state = getInitialState(), action) => {
    const { type, payload } = action;

    switch (type) {
      case actionStartType(reducerName):
        return { ...state, isLoading: true };

      case actionSuccessType(reducerName):
        return { ...state, isLoading: false, data: payload.data };

      case actionFailedType(reducerName):
        return { ...state, isLoading: false, error: payload.error };

      case actionClearType(reducerName):
        return getInitialState();

      default:
        return state;
    }
  };
