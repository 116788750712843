import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { toggleAdminView } from '../../redux/actions/adminView';

import { Switch } from '@mui/material';
import classes from './AdminSwitch.module.scss';

const AdminSwitch = ({ userDetail, className, adminView, toggleAdminView }) => {
  const location = useLocation();

  const isChecked = !!parseInt(adminView);

  if (!(location.pathname === '/cm/chargemix/' && userDetail.is_superuser))
    return null;

  return (
    <div className={classes.container}>
      <Switch
        checked={isChecked}
        onChange={toggleAdminView}
        color='primary'
        size='small'
      />
      <span className={classes.text}>Admin View</span>
    </div>
  );
};

const mapStateToProps = state => ({
  userDetail: state.userDetail,
  adminView: state.adminView
});

const mapDispatchToProps = { toggleAdminView };

export default connect(mapStateToProps, mapDispatchToProps)(AdminSwitch);
