import React from 'react';
import clsx from 'clsx';
import config from '../../../config/config';
import classes from './Icons.module.scss';

function SvgImage({ imgSrc, className, fontSize, style, ...rest }) {

  return <img 
            {...rest}
            src={ fontSize ? `${config.ECOM_IMGIX_URL}${imgSrc}?w=${fontSize}&h=${fontSize}` : `${config.ECOM_IMGIX_URL}${imgSrc}`}
            style={ style }
            className={clsx(classes.img, className)}
         />
}

export default SvgImage;