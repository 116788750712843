export function token() {
  const token_value = localStorage.getItem('tokenv2');
  if (!token_value) return '';
  return 'Token ' + token_value;
}

export async function handleResponse(response) {
  if (response.status === 204) return {};
  if (response.ok) return response.json();
  if (response.status === 400) {
    const error = await response.text();
    throw new Error(error);
  }
  if (response.status === 404) {
    //const error = await response.text();

    throw new Error('Chargemix Not Found');
  }
  if (response.status === 401) {
    localStorage.clear();
    window.location = '/login';
  }
  throw new Error('Network Response was not Ok');
}

export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error('API call failed. ' + error);
  throw error;
}

export function cFetch(baseUrl, headers = {}) {
  return fetch(baseUrl, headers).then(handleResponse).catch(handleError);
}
