import { cFetch, token } from './apiUtils';
import config from '../config/config';
import { createQueryParam } from '../utils';

const charge_mix_version = 'charge_mix_v4';
const chargeMix_upload = 'api/cm/cm_upload';

export function getChargeMix(id) {
  return cFetch(`${config.API_URL}/api/c/${charge_mix_version}/${id}/`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
}

export function getAllChargeMix({ isAdminView = 0, apiParams = {} }) {
  let apiParamsFinal = {
    page_size: apiParams.limit,
    page: apiParams.page,
    is_optimized: apiParams.is_optimized,
    search: apiParams.search,
    ...(isAdminView == 1 && { admin_view: 1 })
  };
  let queryParam = createQueryParam(apiParamsFinal);
  let apiUrl = `${config.API_URL}/api/c/${charge_mix_version}/?${queryParam}`;

  return cFetch(apiUrl, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
}

export function getChargeMixElements() {
  return cFetch(`${config.API_URL}/api/c/elements/`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
}

export function AddChargeMix(data) {
  return cFetch(`${config.API_URL}/api/c/${charge_mix_version}/`, {
    method: 'POST',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}

export function addChargeMixV2(data) {
  return cFetch(`${config.API_URL}/api/c/${charge_mix_version}/`, {
    method: 'POST',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}

export function createCMInventory(data) {
  const formData = new FormData();
  formData.append('basic_data', JSON.stringify(data));
  formData.append('excel_file', data.excel_file);

  return cFetch(`${config.API_URL}/${chargeMix_upload}/`, {
    method: 'POST',
    headers: {
      authorization: token()
    },
    body: formData
  });
}

export function updateCMInventory(data) {
  const formData = new FormData();
  formData.append('excel_file', data.excel_file);
  formData.append('cm', data.cm);

  return cFetch(`${config.API_URL}/${chargeMix_upload}/`, {
    method: 'POST',
    headers: {
      authorization: token()
    },
    body: formData
  });
}

export function UpdateChargeMix(cm_step, id, data) {
  return cFetch(`${config.API_URL}/api/c/${charge_mix_version}/${id}/`, {
    method: 'PATCH',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data, cm_step })
  });
}

export function updateChargeMixV2(cm_step, id, data) {
  return cFetch(`${config.API_URL}/api/c/${charge_mix_version}/${id}/`, {
    method: 'PATCH',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data, cm_step })
  });
}

export function searchMetalGrade(query) {
  return cFetch(`${config.API_URL}/api/c/metal_grades/?search=${query}`, {
    method: 'GET',
    headers: {
      authorization: token(),
      'Content-Type': 'application/json'
    }
  });
}

export function gradeCategoriesList() {
  return cFetch(`${config.API_URL}/api/c/grade_category/`, {
    method: 'GET',
    headers: {
      authorization: token(),
      'Content-Type': 'application/json'
    }
  });
}

export function getWareHouseList() {
  return cFetch(`${config.API_URL}/api/c/warehouse/`, {
    method: 'GET',
    headers: {
      authorization: token(),
      'Content-Type': 'application/json'
    }
  });
}

export function createGrade(data) {
  return cFetch(`${config.API_URL}/api/c/metal_grades/`, {
    method: 'POST',
    headers: {
      authorization: token(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}

export function updateGrade(data) {
  return cFetch(`${config.API_URL}/api/c/metal_grades/` + data.id + '/', {
    method: 'PATCH',
    headers: {
      authorization: token(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}

export function filterInventoryItem(query) {
  return cFetch(`${config.API_URL}/api/c/grade_item_suggestion/${query}/`, {
    method: 'GET',
    headers: {
      authorization: token(),
      'Content-Type': 'application/json'
    }
  });
}

export function getInventoryItems() {
  return cFetch(`${config.API_URL}/api/c/item_inventory/`, {
    method: 'GET',
    headers: {
      authorization: token(),
      'Content-Type': 'application/json'
    }
  });
}

export function inventoryItemById(item_id) {
  // console.log(query);
  return cFetch(`${config.API_URL}/api/c/item_inventory/${item_id}/`, {
    method: 'GET',
    headers: {
      authorization: token(),
      'Content-Type': 'application/json'
    }
  });
}

export function CloneChargeMix(data) {
  return cFetch(`${config.API_URL}/api/cm/clone/`, {
    method: 'POST',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}

export function ArchiveChargeMix(data) {
  return cFetch(`${config.API_URL}/api/c/charge_mix_v4/archive/` + data + '/', {
    method: 'PATCH',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
    // body: null
  });
}

export function getChargeMixOverview() {
  return cFetch(`${config.API_URL}/api/cm/analytic/overview/?is_optimized=1`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
}

export function getChargeMixList() {
  return cFetch(`${config.API_URL}/api/cm/filter/cm_list/?is_optimized=1`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
}

export function getHFdata(data) {
  return cFetch(`${config.API_URL}/api/cm/spectro/add_dill/`, {
    method: 'POST',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}

export function getAddDil(data) {
  return cFetch(`${config.API_URL}/api/cm/spectro/add_dill/`, {
    method: 'POST',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}

export function getChargemixSuggestion(cm_id) {
  return cFetch(`${config.API_URL}/api/cm/product_suggestion/?cm_id=${cm_id}`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
}

export function createTrailChargemix(suggestion_id) {
  return cFetch(
    `${config.API_URL}/api/cm/trial_cm_product_suggestion/?suggestion_id=${suggestion_id}`,
    {
      method: 'GET',
      headers: {
        authorization: token()
      }
    }
  );
}

export function requestSuggestionSample(suggestion_id) {
  return cFetch(`${config.API_URL}/api/cm/request_suggested_sample/`, {
    method: 'POST',
    headers: {
      authorization: token(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ suggestion_id: suggestion_id })
  });
}
