import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RightArrowIcon } from '../../../assets/icons/modifiedIcons';
import { getGradeTag } from '../../Grades/GradeListingTable';
import { isMobile } from 'react-device-detect';
import { goBack, pathMatch } from '../../../utils';
import classes from './HeaderLayout.module.scss';
import { sendAnalytics } from '../../../utils/analyticsUtils';

/**
 * Component that renders a breadcrumb for navigation.
 *
 * @param {object} props - Component props.
 * @param {Function} props.navigate - The navigate function from react-router-dom to handle navigation.
 * @param {string} props.text - The display text for the breadcrumb.
 * @param {string} props.path - The navigation path that the breadcrumb points to.
 *
 * @returns {JSX.Element} A div element representing a breadcrumb.
 */
export const Breadcrumb = ({ navigate, text, path, sx }) => {
  const { analyticsKey } = useSelector(state => state);
  return (
    <div
      className={classes.breadcrumbStyle}
      style={{ ...sx }}
      onClick={() => {
        sendAnalytics({
          defaultName: `${analyticsKey}_BackToListing`,
          defaultDetails: `Go Back to ${text} Listing Page`
        });
        navigate(path);
      }}
    >
      {text}
    </div>
  );
};

/**
 * Renders grade details such as label or grade information.
 *
 * @param {string} label - Optional label to display. If present, other grade details are ignored.
 * @param {object} gradeDetail - The grade details object containing information like grade tag and name.
 * @returns {JSX.Element} A span or a combination of divs showing the grade details.
 */
const renderDetails = (label, gradeDetail) => {
  if (label) {
    return (
      <span style={{ fontSize: '16', color: 'black', whiteSpace: 'nowrap' }}>
        {label}
      </span>
    );
  }
  return (
    <>
      <div className={classes.tagBox}>{gradeDetail.grade_tag_id}</div>
      <div className={classes.smallText}>{gradeDetail.name}</div>
      <div className={classes.nameTagContainer}>
        <div>{getGradeTag(gradeDetail.grade_category?.symbol)}</div>
      </div>
    </>
  );
};

/**
 * Component to display the grade details in a breadcrumb-like format.
 *
 * @param {object} props - Component props.
 * @param {object} props.gradeDetail - The details of the grade to be displayed.
 * @param {string} props.label - Optional label to display instead of grade details.
 * @returns {JSX.Element} A div element representing the grade detail display.
 */
const GradeDetailDisplay = ({ gradeDetail, label }) => (
  <div className={classes.breadCrumbContainer}>
    <span>
      <RightArrowIcon fontSize={16} />
    </span>
    {renderDetails(label, gradeDetail)}
  </div>
);

/**
 * Header component for the Grade section, including navigation and grade detail display.
 * Uses redux to manage state and react-router-dom for navigation.
 *
 * @returns {JSX.Element} The header component with conditional rendering based on the current URL path.
 */
const GradeHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentStep = useSelector(state => state.addGradeCurrentStep);
  const gradeDetail = useSelector(state => state.gradeDetail);

  const isAddGradePath = pathMatch('/grades', '/add', location.pathname);
  const isGradeViewPath = pathMatch('/grades', '/\\d+', location.pathname);
  const isGradeDetailPath = pathMatch(
    '/grades',
    '/\\d+/edit',
    location.pathname
  );

  const getLabel = () => {
    if (isAddGradePath) {
      return `Add new grade - ${currentStep.split('_').join(' ')}`;
    }
    return null;
  };

  if (!isAddGradePath && !isGradeDetailPath && !isGradeViewPath) {
    return <span className={classes.title}>Grades</span>;
  }

  return (
    <div className={classes.headerDiv}>
      <ArrowBackIcon
        className={classes.backActionIcon}
        onClick={() => goBack(navigate, location)}
      />
      <Breadcrumb navigate={navigate} text='Grades & Parts' path='/grades' />

      {(isAddGradePath ||
        ((isGradeDetailPath || isGradeViewPath) &&
          Object.keys(gradeDetail).length > 0 &&
          !isMobile)) && (
        <GradeDetailDisplay gradeDetail={gradeDetail} label={getLabel()} />
      )}
    </div>
  );
};

export default GradeHeader;
