import { isMobile } from 'react-device-detect';
import { TOGGLE_SIDEBAR } from '../actionTypes';

const initialState = { sidebarOpen: !isMobile };

const toggleSidebar = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { sidebarOpen: !state.sidebarOpen };
    default:
      return state;
  }
};

export default toggleSidebar;
