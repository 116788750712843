import { TOGGLE_ADMIN_VIEW } from '../actionTypes';

import { ADMIN_VIEW_CONST } from '../../constants/AdminView';

const INITIAL_STATE = {
  adminView: localStorage.getItem(ADMIN_VIEW_CONST) ?? '0'
};

const adminViewReducer = (state = INITIAL_STATE.adminView, action) => {
  switch (action.type) {
    case TOGGLE_ADMIN_VIEW:
      return state == '1' ? '0' : '1';

    default:
      return state;
  }
};

export default adminViewReducer;
