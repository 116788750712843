import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { goBack, pathMatch } from '../../../utils';
import classes from './HeaderLayout.module.scss';
import { Breadcrumb } from './GradeHeader';
import { RightArrowIcon } from '../../../assets/icons/modifiedIcons';

/**
 * InventoryHeader is a component that renders the header for the Inventory section.
 * It displays a breadcrumb navigation and the name of the current inventory item when viewing details.
 *
 * @param {object} props - Component props.
 * @param {object} props.inventoryDetail - Details of the current inventory item, provided by Redux state.
 * @returns {JSX.Element} The header component for the Inventory section, with dynamic content based on the current route.
 */
const InventoryHeader = ({ inventoryDetail }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isInventoryDetails = pathMatch(
    '/inventory',
    '/\\d+',
    location.pathname
  );

  if (!isInventoryDetails)
    return <span className={classes.title}>Inventory </span>;

  return (
    <div>
      <div className={classes.breadCrumbContainer}>
        <>
          <ArrowBackIcon
            className={classes.backActionIcon}
            onClick={() => goBack(navigate, location)}
          />
          <Breadcrumb navigate={navigate} text='Inventory' path='/inventory' />
        </>
        <span style={{ margin: '0 4px' }}>
          <RightArrowIcon fontSize={16} />
        </span>
        <div className={classes.rawMaterialHeader}>{inventoryDetail?.name}</div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  inventoryDetail: state.inventoryDetails.data
});

export default connect(mapStateToProps, null)(InventoryHeader);
