import initialState from '../initial-state';
import { SET_ANALYTICS_KEY } from './analyticsActionTypes';

export const analyticsKey = (state = initialState.analyticsKey, action) => {
  switch (action.type) {
    case SET_ANALYTICS_KEY:
      return action.payload;
    default:
      return state;
  }
};
