import { USER_DETAIL_ACTION_TYPES } from './userDetailActionTypes';
import initialState from '../initial-state';

export default function (state = initialState.userDetail, action) {
  switch (action.type) {
    case USER_DETAIL_ACTION_TYPES.LOAD_USER_DETAIL_SUCCESS: {
      return action.user;
    }
    default:
      return state;
  }
}
