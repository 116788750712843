import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/configureStore';
import initialState from './redux/initial-state';
import { ADMIN_VIEW_CONST } from './constants/AdminView';
import './sentryConfig';

import './common.scss';
// import './handsontableCommentPlugin.scss';

let store = configureStore(initialState);

store.subscribe(() => {
  const currState = store.getState();

  if (currState.auth.token) {
    localStorage.setItem('tokenv2', currState.auth.token);
    localStorage.setItem('login_status', currState.auth.isLoggedIn);
  }

  localStorage.setItem(ADMIN_VIEW_CONST, currState.adminView);
});

render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
