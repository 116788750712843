import { withDefaultUIHandler } from '../../utils';
import {
  actionStartType,
  actionSuccessType,
  actionFailedType
} from './actionTypes';

export function createFromDefaultAction(
  _reducerName,
  asyncFn,
  extraDispatchMeta,
  defaultUIHandlerArgs = {}
) {
  return dispatch =>
    withDefaultUIHandler(async () => {
      const { analytics, afterSuccessCall } = extraDispatchMeta || {};

      try {
        dispatch({ type: actionStartType(_reducerName) });

        const data = await asyncFn();

        dispatch({
          type: actionSuccessType(_reducerName),
          payload: { data },
          analytics,
          ...afterSuccessCall
        });

        return data;
      } catch (error) {
        dispatch({
          type: actionFailedType(_reducerName),
          payload: { error }
        });

        throw error;
      }
    }, defaultUIHandlerArgs);
}
