import { CHARGEMIX_ACTION_TYPES } from './chargeMixActionTypes';
import initialState from '../initial-state';

export function currentstep(state = initialState.currentstep, action) {
  switch (action.type) {
    case CHARGEMIX_ACTION_TYPES.CURRENT_STEP_UPDATE:
      return action.currentstep;
    default:
      return state;
  }
}

export function cm_elements(state = initialState.cm_elements, action) {
  switch (action.type) {
    case CHARGEMIX_ACTION_TYPES.GET_CHARGEMIX_ELEMENTS_SUCCESS:
      return action.cm_elements;
    default:
      return state;
  }
}

export function chargeMixList(state = initialState.chargeMixList, action) {
  switch (action.type) {
    case CHARGEMIX_ACTION_TYPES.GET_CHARGEMIX_LIST_SUCCESS:
      return action.chargeMixList;
    default:
      return state;
  }
}

export function chargeMix(state = initialState.chargeMix, action) {
  switch (action.type) {
    case CHARGEMIX_ACTION_TYPES.GET_CHARGEMIX_SUCCESS:
    case CHARGEMIX_ACTION_TYPES.ADD_CHARGEMIX_SUCCESS:
      // return {...state, chargeMix: action.chargeMix, currentstep: CM_STEPS[state.currentstep] };
      return action.chargeMix;
    case CHARGEMIX_ACTION_TYPES.UPDATE_CHARGEMIX_SUCCESS:
      return { ...state, ...action.chargeMix };
    case CHARGEMIX_ACTION_TYPES.UPDATE_CHARGEMIX_LIST_SUCCESS:
      return { ...state, cm_items: action.cm_items };
    case CHARGEMIX_ACTION_TYPES.CREATE_CHARGEMIX:
      return initialState.chargeMix;
    case CHARGEMIX_ACTION_TYPES.DRAFT_CHARGEMIX_CHANGES:
      return { ...state, ...action.chargeMix };
    default:
      return state;
  }
}

export function itemInventory(state = initialState.itemInventory, action) {
  switch (action.type) {
    case CHARGEMIX_ACTION_TYPES.ITEM_INVENTORY_LIST_OPTIMISTIC:
      return action.data;
    default:
      return state;
  }
}
