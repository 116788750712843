import { CART_ITEM_ACTION_TYPES } from './cartItemActionTypes';
import initialState from '../initial-state';

export default function cartItems(state = initialState.cartItems, action) {
  switch (action.type) {
    case CART_ITEM_ACTION_TYPES.LOAD_CARTS_SUCCESS:
      return action.cartItems;
    case CART_ITEM_ACTION_TYPES.CREATE_CART_ITEM_OPTIMISTIC:
      return [...state, { ...action.cartItem }];
    case CART_ITEM_ACTION_TYPES.UPDATE_CART_ITEM_OPTIMISTIC:
      return state.map(item => {
        if (item.id == action.cartItem.id) {
          return { ...item, quantity: action.cartItem.quantity };
        } else {
          return item;
        }
      });
    case CART_ITEM_ACTION_TYPES.REMOVE_CART_ITEM_OPTIMISTIC:
      return state.filter(cartItem => cartItem.id != action.cartItemId);
    default:
      return state;
  }
}
