/**
 * Computes the difference between two flat arrays of objects based on a specified index key.
 *
 * @param {Array} initialValue - The initial array of objects.
 * @param {Array} newValue - The new array of objects.
 * @param {string} indexKey - The key to compare objects' indices.
 * @returns {object} - An object containing the added and removed items.
 */
export const flatArrayDiff = (initialValue, newValue, indexKey) => ({
  added: newValue.filter(
    newItem =>
      !initialValue.find(
        initialItem => initialItem[indexKey] == newItem[indexKey]
      )
  ),
  removed: initialValue.filter(
    newItem =>
      !newValue.find(initialItem => initialItem[indexKey] == newItem[indexKey])
  )
});

/**
 * Computes the difference in values between two objects.
 * This function only checks for changed values, ignoring added or removed keys.
 * If the value is an array or object (excluding null), it is ignored.
 *
 * @param {Object} initialValue - The initial object.
 * @param {Object} newValue - The new object.
 * @returns {Object} - An object containing pairs of changed values,
 *                     with `old` property representing the initial value and `new` the updated value.
 *
 * @example
 * returns { changed: [{key: 'b', old: 2, new: 20}, {key: 'd', old: 4, new: 40}]}
 * simpleObjectDiff({a:1, b:2, c:3, d:4, e:5}, {a:1, b:20, c:3, d:40, e:5});
 */
export const simpleObjectDiff = (initialValue, newValue) => {
  const changed = Object.keys(initialValue)
    .filter(
      key =>
        (typeof initialValue[key] !== 'object') || !Array.isArray(initialValue[key])
    )
    .map(key => { 
      if ((initialValue[key] == null && newValue[key] === "") || (initialValue[key] == "" && newValue[key] == null)) {
        // Skip rendering for null to empty string change
        return null;
      }
      return {key, old: initialValue[key], new: newValue[key] }
    })
    .filter(pair => pair && (pair?.old !== pair?.new));

  return { changed };
};

/**
 * Computes the difference between two deep arrays of objects based on a specified index key and keys to compare.
 *
 * @param {Array} oldValue - The old deep array of objects.
 * @param {Array} newValue - The new deep array of objects.
 * @param {string} indexKey - The key to compare objects' indices.
 * @param {Array} keysToCompare - The keys to compare between objects.
 * @returns {object} - An object containing the added, removed, and different items.
 */
export const DeepArrayDiffWithKeys = (
  oldValue,
  newValue,
  indexKey,
  keysToCompare
) => {
  const initValueKeys = oldValue.map(item => item[indexKey]);
  const newValueKeys = newValue.map(item => item[indexKey]);
  const allKeys = [
    ...initValueKeys,
    ...newValueKeys.filter(item => initValueKeys.indexOf(item) == -1)
  ];

  const addedValues = [];
  const removedValues = [];
  const diffValues = [];

  allKeys.forEach(key => {
    const keyExistInOld = oldValue.find(item => item[indexKey] == key);
    const keyExistInNew = newValue.find(item => item[indexKey] == key);
    if (keyExistInOld && !keyExistInNew) removedValues.push(keyExistInOld);
    else if (!keyExistInOld && keyExistInNew) addedValues.push(keyExistInNew);
    else if (keyExistInOld && keyExistInNew) {
      let diffObj = { diff: [] };

      const bothKeys = [
        ...new Set([
          ...Object.keys(keyExistInOld),
          ...Object.keys(keyExistInNew)
        ])
      ];

      bothKeys.forEach(objKey => {
        if (keysToCompare.indexOf(objKey) == -1) {
          diffObj = {
            ...diffObj,
            [objKey]: keyExistInOld[objKey] || keyExistInNew[objKey]
          };
        } else if (
          keysToCompare.indexOf(objKey) > -1 &&
          ((typeof keyExistInNew[objKey] === 'string' &&
            !isNaN(keyExistInNew[objKey]) &&
            typeof keyExistInOld[objKey] === 'string' &&
            !isNaN(keyExistInOld[objKey]) &&
            parseFloat(keyExistInNew[objKey]).toFixed(6) !==
              parseFloat(keyExistInOld[objKey]).toFixed(6)) ||
            ((typeof keyExistInNew[objKey] !== 'string' ||
              isNaN(keyExistInNew[objKey]) ||
              typeof keyExistInOld[objKey] !== 'string' ||
              isNaN(keyExistInOld[objKey])) &&
              keyExistInNew[objKey] !== keyExistInOld[objKey]))
        ) {
          diffObj.diff.push({
            key: objKey,
            initialValue: keyExistInOld[objKey],
            newValue: keyExistInNew[objKey]
          });
        }
      });

      if (diffObj.diff.length) diffValues.push(diffObj);
    }
  });

  return { diff: diffValues, added: addedValues, removed: removedValues };
};
