import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { goBack, pathMatch } from '../../../utils';
import classes from './HeaderLayout.module.scss';
import { Breadcrumb } from './GradeHeader';
import { isMobile } from 'react-device-detect';

/**
 * Header component for the Quality Report section of the application.
 * This component displays a header with dynamic content based on the current route.
 * When viewing test details, it shows an 'ArrowBackIcon', a breadcrumb navigation to 'Grades',
 * and the name of the test with its status color-coded.
 *
 * @param {object} props - Component props.
 * @param {object} props.qualityReports - The quality reports data from the Redux state, containing test names and statuses.
 * @returns {JSX.Element} The header component for the Quality Report section, with content changing based on whether test details are being viewed.
 */
const QualityReportHeader = ({ qualityReports }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isTestDetails = pathMatch(
    '/qualityreports',
    '/\\d+',
    location.pathname
  );
  if (!isTestDetails)
    return <span className={classes.title}>Quality Report </span>;

  return (
    <div>
      <div className={classes.breadCrumbContainer}>
        <>
          <Breadcrumb
            navigate={navigate}
            text='Quality Reports'
            path='/qualityreports'
          />
        </>
        <i
          className='ri-arrow-right-s-line'
          style={{ padding: '10 0', fontSize: 16 }}
        />
        <span
          style={{
            fontSize: isMobile ? '12' : '16',
            color: `${
              qualityReports.status == null
                ? '#303030'
                : qualityReports.status == 'Accepted'
                ? '#2BA24C'
                : '#E43E2B'
            }`
          }}
        >
          {qualityReports.status === null ? 'Reading' : qualityReports.name}
        </span>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  qualityReports: state.testReportDetail
});

export default connect(mapStateToProps, null)(QualityReportHeader);
