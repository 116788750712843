import { Modal } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import classes from './TermsAndCondition.module.scss';

const index = ({ open, setOpen, userDetail }) => {
  const handleClose = () => {
    setOpen(ele => {
      return !ele;
    });
  };
  const openTab = () => {
    window.open(
      'https://forms.zohopublic.in/iqbal1/form/MetalCloudInterestDetails/formperma/3Gn7sVVvb5I5T5BFjYTel-96f_8xIGuPvUjqZRocVYM'
    );
  };
  return (
    <Modal open={open} sx={{ maxWidth: 1050 }}>
      <div className={classes.modalDiv}>
        <div
          style={{
            display: 'flex',
            padding: '30px 30px 21px 30px',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div className={classes.header_style}>Upgrade Required!</div>

          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleClose();
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
            >
              <g clipPath='url(#clip0_65_233)'>
                <path
                  d='M12 10.586L16.95 5.63599L18.364 7.04999L13.414 12L18.364 16.95L16.95 18.364L12 13.414L7.04999 18.364L5.63599 16.95L10.586 12L5.63599 7.04999L7.04999 5.63599L12 10.586Z'
                  fill='#909090'
                />
              </g>
              <defs>
                <clipPath id='clip0_65_233'>
                  <rect width='24' height='24' fill='white' />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
        <div style={{ paddingLeft: 32 }}>
          To use ChargeMix Module, Please upgrade to PRO!
        </div>

        <div className={classes.buttonWrapper}>
          <button
            className={classes.cancel_style}
            style={{
              border: '1px solid #1579BE',
              borderRadius: 4,
              marginTop: 10,
              marginBottom: 30
            }}
            onClick={() => {
              handleClose();
              setOpen(false);
            }}
          >
            Cancel
          </button>
          <button
            className={classes.button}
            style={{
              backgroundColor: 'rgba(33, 126, 192, 1)',
              borderRadius: 4,
              marginTop: 10,
              marginBottom: 30
            }}
            onClick={() => {
              openTab();
              setOpen(false);
            }}
          >
            Contact Us
          </button>{' '}
        </div>
      </div>
    </Modal>
  );
};

export default index;
