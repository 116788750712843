import React from 'react';
import NPLogo from '../../../assets/images/logo/NPLogo.png';
import { sendAnalytics } from '../../../utils/analyticsUtils';
import { useSelector } from 'react-redux';
import classes from './SidebarLayout.module.scss';
import { EMAIL_ID, DEFAULT_CONTACT } from '../../../constants/User';
import { motion } from 'framer-motion'

const SidebarFooter = () => {
  const userDetail = useSelector(state => state.userDetail);

  const onNumberClick = () => {
    const analytics_Details = {
      defaultName: `Phone Number`,
      defaultDetails: `Phone Number : ${
        userDetail.customer?.rm.contact || DEFAULT_CONTACT
      }`
    };
    sendAnalytics(analytics_Details);
  };

  const onMailClick = () => {
    const analytics_Details = {
      defaultName: `Email ID`,
      defaultDetails: `Email ID : ${EMAIL_ID}`
    };
    sendAnalytics(analytics_Details);
  };
  return (
    <motion.footer initial={{ y: '100%', opacity: 0}} animate={{ y: '0%', opacity: 1}} className={classes.footer_wrapper}>
      <div className={classes.footerImgContainer}>
        <img src={NPLogo} />
      </div>
      <div>
        <a
          onClick={onNumberClick}
          href={'tel:' + userDetail.customer?.rm.contact || DEFAULT_CONTACT}
          className={classes.footerData}
        >
          {userDetail.customer?.rm.contact || DEFAULT_CONTACT}
        </a>
        <br />
        <a
          onClick={onMailClick}
          href={`mailto:${EMAIL_ID}`}
          className={classes.footerData}
        >
          {EMAIL_ID}
        </a>
      </div>
    </motion.footer>
  );
};

export default SidebarFooter;
