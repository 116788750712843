import { sha256 } from 'js-sha256';

export const getBrowserFingerprint = ({
  hardwareOnly = false,
  enableWebgl = false,
  enableScreen = true,
  debug = false
} = {}) => {
  const {
    cookieEnabled,
    deviceMemory,
    doNotTrack,
    hardwareConcurrency,
    language,
    languages,
    maxTouchPoints,
    platform,
    userAgent,
    vendor
  } = window.navigator;

  const { width, height, colorDepth, pixelDepth } = enableScreen
    ? window.screen
    : {}; // undefined will remove this from the stringify down here
  const timezoneOffset = new Date().getTimezoneOffset();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const touchSupport = 'ontouchstart' in window;
  const devicePixelRatio = window.devicePixelRatio;

  const canvas = getCanvasID(debug);
  const webgl = enableWebgl ? getWebglID(debug) : undefined; // undefined will remove this from the stringify down here
  const webglInfo = enableWebgl ? getWebglInfo(debug) : undefined; // undefined will remove this from the stringify down here

  const data = hardwareOnly
    ? {
        canvas,
        colorDepth,
        deviceMemory,
        devicePixelRatio,
        hardwareConcurrency,
        height,
        maxTouchPoints,
        pixelDepth,
        platform,
        touchSupport,
        webgl,
        webglInfo,
        width
      }
    : {
        canvas,
        colorDepth,
        cookieEnabled,
        deviceMemory,
        devicePixelRatio,
        doNotTrack,
        hardwareConcurrency,
        height,
        language,
        languages,
        maxTouchPoints,
        pixelDepth,
        platform,
        timezone,
        timezoneOffset,
        touchSupport,
        userAgent,
        vendor,
        webgl,
        webglInfo,
        width
      };

  const datastring = JSON.stringify(data, null, 4);

  if (debug) console.log('fingerprint data', datastring);
  const hash = sha256.create();
  hash.update(datastring);
  return hash.hex();
};

const getCanvasID = debug => {
  try {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const text =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`~1!2@3#4$5%6^7&8*9(0)-_=+[{]}|;:',<.>/?";
    ctx.textBaseline = 'top';
    ctx.font = "14px 'Arial'";
    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = '#069';
    ctx.fillText(text, 2, 15);
    ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
    ctx.fillText(text, 4, 17);

    const result = canvas.toDataURL();

    if (debug) {
      document.body.appendChild(canvas);
    } else {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }

    const hash = sha256.create();
    hash.update(result);
    return hash.hex();
  } catch {
    return null;
  }
};

const getWebglID = debug => {
  try {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('webgl');

    if (!ctx) {
      return null;
    }

    canvas.width = 256;
    canvas.height = 128;

    const f =
      'attribute vec2 attrVertex;varying vec2 varyinTexCoordinate;uniform vec2 uniformOffset;void main(){varyinTexCoordinate=attrVertex+uniformOffset;gl_Position=vec4(attrVertex,0,1);}';
    const g =
      'precision mediump float;varying vec2 varyinTexCoordinate;void main() {gl_FragColor=vec4(varyinTexCoordinate,0,1);}';
    const h = ctx.createBuffer();

    ctx.bindBuffer(ctx.ARRAY_BUFFER, h);

    const i = new Float32Array([-0.2, -0.9, 0, 0.4, -0.26, 0, 0, 0.7321, 0]);

    ctx.bufferData(ctx.ARRAY_BUFFER, i, ctx.STATIC_DRAW);
    h.itemSize = 3;
    h.numItems = 3;

    const j = ctx.createProgram();
    const k = ctx.createShader(ctx.VERTEX_SHADER);

    ctx.shaderSource(k, f);
    ctx.compileShader(k);

    const l = ctx.createShader(ctx.FRAGMENT_SHADER);

    ctx.shaderSource(l, g);
    ctx.compileShader(l);
    ctx.attachShader(j, k);
    ctx.attachShader(j, l);
    ctx.linkProgram(j);
    ctx.useProgram(j);

    j.vertexPosAttrib = ctx.getAttribLocation(j, 'attrVertex');
    j.offsetUniform = ctx.getUniformLocation(j, 'uniformOffset');

    ctx.enableVertexAttribArray(j.vertexPosArray);
    ctx.vertexAttribPointer(j.vertexPosAttrib, h.itemSize, ctx.FLOAT, !1, 0, 0);
    ctx.uniform2f(j.offsetUniform, 1, 1);
    ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, h.numItems);

    const n = new Uint8Array(canvas.width * canvas.height * 4);
    ctx.readPixels(
      0,
      0,
      canvas.width,
      canvas.height,
      ctx.RGBA,
      ctx.UNSIGNED_BYTE,
      n
    );

    const result = JSON.stringify(n).replace(/,?"[0-9]+":/g, '');

    if (debug) {
      document.body.appendChild(canvas);
    } else {
      ctx.clear(
        ctx.COLOR_BUFFER_BIT | ctx.DEPTH_BUFFER_BIT | ctx.STENCIL_BUFFER_BIT
      );
    }

    const hash = sha256.create();
    hash.update(result);
    return hash.hex();
  } catch {
    return null;
  }
};

const getWebglInfo = () => {
  try {
    const ctx = document.createElement('canvas').getContext('webgl');

    const result = {
      VERSION: String(ctx.getParameter(ctx.VERSION)),
      SHADING_LANGUAGE_VERSION: String(
        ctx.getParameter(ctx.SHADING_LANGUAGE_VERSION)
      ),
      VENDOR: String(ctx.getParameter(ctx.VENDOR)),
      SUPORTED_EXTENSIONS: String(ctx.getSupportedExtensions())
    };

    return result;
  } catch {
    return null;
  }
};

if (typeof window !== 'undefined') {
  window.getBrowserFingerprint = getBrowserFingerprint;
}

export default getBrowserFingerprint;
