import { PRODUCT_ACTION_TYPES } from './productActionTypes';
import initialState from '../initial-state';

export default function products(state = initialState.products, action) {
  switch (action.type) {
    case PRODUCT_ACTION_TYPES.LOAD_PRODUCT_SUCCESS:
      return action.products;
    case PRODUCT_ACTION_TYPES.LOAD_PRODUCT_LIST_SUCCESS:
      return action.products;
    case PRODUCT_ACTION_TYPES.LOAD_EX_PRODUCTS_SUCCESS:
      return action.products;
    default:
      return state;
  }
}
