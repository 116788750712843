export function actionStartType(type) {
  return type + '_START';
}

export function actionSuccessType(type) {
  return type + '_SUCCESS';
}

export function actionFailedType(type) {
  return type + '_FAILED';
}

export function actionClearType(type) {
  return type + '_CLEAR';
}
