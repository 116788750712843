import {
  FormControl,
  Select,
  MenuItem,
  Pagination,
  PaginationItem
} from '@mui/material';
import { CustomInput } from '../CustomInput';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import { isMobile } from 'react-device-detect';
import useQuery from '../../hooks/useQuery';
import classes from './PaginationWrapper.module.scss';
import RenderConditionally from '../RenderConditionally';
import { sendAnalytics } from '../../utils/analyticsUtils';
import { useSelector } from 'react-redux';

function getPaginationStyles(item) {
  return item.selected || item.type == 'previous' || item.type == 'next'
    ? {
        border: '1px solid rgba(25, 118, 210, 0.5)',
        backgroundColor: 'rgba(25, 118, 210, 0.12)',
        fontWeight: 'bolder'
      }
    : {};
}

/**
 *  MUI PAGINATION WRAPPER WITH CUSTOM DESIGN AND ROWS PER PAGE COUNTS
 * @param  {Number} limit  Total number of data to be rendered in single page.
 * @param  {Function} handleRowsPerPageChange   Handler to change limit of data in single page.
 * @param  {Number} count Total number of data counts.
 * @param  {Function} handlePageChange  Page change handler.
 * @returns Returns Pagination Component.
 */
export const PaginationWrapper = ({
  limit,
  count,
  siblingsCount = isMobile ? 0 : 1
}) => {
  const [query, setQuery] = useQuery();
  const page = query.page ?? 1;

  // CALCULATE NUMBER OF PAGES
  const pagesCount = Math.ceil(count / limit);

  const { analyticsKey } = useSelector(state => state);

  /* 
   RETURN NULL IN CASE OF THERE IS PAGES < 2.
   i.e NO DATA, OR LESSER DATA 
   */
  // if (pagesCount < 2) {
  //   return null;
  // }

  /**
   *  RETURN TOTAL HOW MANY DATA ARE SHOWN IN THIS PAGE
   * @return {String} e.g. 1 - 10 of 6535 // Where  page = 1 , limit = 10, count = 6535
   */
  const paginationMessage = () => {
    return `${(page - 1) * limit + 1} - ${page * limit} of ${count}`;
  };
  /**
   * function set the page No on the user is on.
   * @summary it set the page no in url param
   * @param {SyntheticBaseEvent} event
   * @param {Integer} pageNum - current page no user is on
   */
  function handlePageChange(event, pageNum) {
    query.page = pageNum;
    //console.log('pageNum', pageNum);
    setQuery(query);

    const analytics_Details = {
      defaultName: `${analyticsKey}_PaginationPageChange`,
      defaultDetails: `Pagination number: ${pageNum}`
    };

    sendAnalytics(analytics_Details);
  }

  /**
   * @summary  function handles the no of rows to shows in one single page .
   * @param {SyntheticBaseEvent} event
   */
  function handleRowsPerPageChange(event) {
    query.page_size = event.target.value;
    query.page = 1;
    setQuery(query);
    const analytics_Details = {
      defaultName: `${analyticsKey}_PaginationRowsPerPageChange`,
      defaultDetails: `No. Of Rows: ${event.target.value}`
    };

    sendAnalytics(analytics_Details);
  }

  return (
    <div className={classes.pagination}>
      {/* Hide Rows per page incase of Mobile*/}
      <div className={classes.rowsPerPGRapper}>
        <RenderConditionally condition={!isMobile}>
          <>
            <div
              style={{
                marginRight: '7px',
                fontSize: '0.9rem'
              }}
            >
              Rows per page:
            </div>
            {/* showing the row in one page */}
            <FormControl size='small'>
              <Select
                value={limit}
                onChange={handleRowsPerPageChange}
                input={<CustomInput />}
                size='small'
              >
                {/* showing rows option in one page (5 rows per page , 10 rows per page .....) */}
                {[5, 10, 20, 30, 40, 50].map(c => (
                  <MenuItem key={c} color='#000' value={c}>
                    {c}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <div
            style={{
              fontSize: '14px',
              marginLeft: '20px',
              marginRight: '20px'
            }}
          >
            {paginationMessage()}
         </div> */}
          </>
        </RenderConditionally>
      </div>
      <div className={classes.PageNoList}>
        <Pagination
          defaultPage={page}
          count={pagesCount}
          siblingCount={siblingsCount}
          boundaryCount={1}
          page={page}
          onChange={handlePageChange}
          variant='outlined'
          color='primary'
          renderItem={item => (
            <PaginationItem
              {...item}
              key={item.page}
              style={getPaginationStyles(item)}
              variant='outlined'
              className={classes.pagecountbox}
              components={{
                previous: () => (
                  <ArrowBackIosIcon color='primary' fontSize='30px' />
                ),
                next: () => (
                  <ArrowForwardIosIcon color='primary' fontSize='30px' />
                )
              }}
            />
          )}
        />
      </div>
    </div>
  );
};
