import Tippy from '@tippyjs/react';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import StretchContainer from '../../General/StretchContainer';
import FilterBox from '../../RawMaterial/FilterBox';
import styles from '../../Grades/GradesHeader.module.scss';
import DebouceInputSearch from '../../General/DebouceInputSearch';
import { notifyOnSlack } from '../../../redux/actions/slackNotification';
import { getHeaderStat } from '../../../utils/Spectrometer';
import withAnalytics from '../../../lib/withAnalytics';
import useQuery from '../../../hooks/useQuery';
import { isMobile } from 'react-device-detect';
import usePermission from '../../../hooks/usePermissions';
import { Scopes, Slug } from '../../../constants/Routes/permissionConstants';
import { sendAnalytics } from '../../../utils/analyticsUtils';
import ListingHeaderLoader from '../../Loader/Common/Listing/ListingHeaderLoader';
import { Badge } from '@mui/material';
import { isEafFurnace } from '../../../utils/Grade';
import { badgeInitialData, filterBox } from '../../../constants/Spectrometer';

const SpectroHeader = ({
  userDetail,
  setFilterOpen,
  setAddDilModal,
  setDownloadOpen
}) => {
  const [filterBoxData, setFilterBoxData] = useState(filterBox);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useQuery();
  const hasPermission = usePermission();

  const hasEditAccess = hasPermission(Scopes.EDIT, Slug.SPECTROMETER);
  const hasShareAccess = hasPermission(Scopes.SHARE, Slug.SPECTROMETER);

  function handleChange(data) {
    const apiParams = {
      ...query,
      page: null,
      page_size: query.page_size || 50,
      search: data,
      is_tagged: query.is_tagged
    };
    setQuery(apiParams);
  }

  /**
   * Updates the grade_category__name query parameter in the search query state based on the selected filter value.
   * If the filter value is 'ALL', the grade_category__name parameter is cleared from the query.
   *
   * @param {string} cmType - The value of the selected filter.
   */
  const handleFilterChange = cmType => {
    // console.log(cmType, { ...query, grade_category__name: cmType });
    if (cmType === 'ALL') {
      setQuery({ ...query, is_tagged: '', page: null });
    } else {
      setQuery({ ...query, is_tagged: cmType, page: null });
    }
  };

  const isStaff = userDetail.is_staff || userDetail.is_superuser;

  useEffect(() => {
    (async () => {
      setLoading(true);
      const resp = await getHeaderStat();
      const newData = filterBoxData.map(d => ({ ...d, count: resp[d.key] }));
      setFilterBoxData(newData);
      setLoading(false);
    })();
  }, []);

  if (loading) return <ListingHeaderLoader />;
  const badgeQueryLength = badgeInitialData.filter(
    q => Object.keys(query).indexOf(q) !== -1
  ).length;
  return (
    <ErrorBoundary>
      <div className={styles.spectroHeaderContainer}>
        <StretchContainer sx={{ flexWrap: 'wrap', marginBottom: 10, gap: 10 }}>
          <div className={styles.filterBoxesContainer}>
            {!isEafFurnace(userDetail.customer.furnace_type) &&
              filterBoxData.map((d, i) => {
                return (
                  <FilterBox
                    key={i}
                    active={
                      query.is_tagged
                        ? query.is_tagged == d.value
                        : d.value == 'ALL'
                    }
                    text={d.text}
                    color={d.color}
                    count={d.count}
                    value={d.value}
                    sx={{ width: '11.75rem', maxWidth: 'unset' }}
                    handleChange={handleFilterChange}
                  />
                );
              })}
          </div>
          <div
            className={styles.inputHeader}
            style={isMobile ? { width: '100%' } : null}
          >
            <DebouceInputSearch
              query={query}
              handleChange={handleChange}
              sx={isMobile ? { width: '100%' } : null}
              inputStyles={
                isMobile ? { maxWidth: '100%', width: '100%' } : null
              }
            />
            {isStaff && !isMobile && hasEditAccess && (
              <Tippy content='Add New Reading' placement='bottom'>
                <button
                  className={styles.filterButton}
                  onClick={() => {
                    setAddDilModal(true);
                    sendAnalytics({ defaultName: 'Spectrometer_Add' });
                  }}
                >
                  <i className='ri-add-line'></i>
                </button>
              </Tippy>
            )}
            <Tippy content='Filter Spectrometer'>
              <Badge
                color='secondary'
                invisible={!badgeQueryLength}
                badgeContent={badgeQueryLength}
              >
                <button
                  className={`${styles.filterButton} ${
                    badgeQueryLength > 0 && styles.filterButtonActive
                  } `}
                  onClick={() => {
                    setFilterOpen(true);
                    sendAnalytics({ defaultName: 'Spectrometer_Filter' });
                  }}
                >
                  <i className='ri-equalizer-line'></i>
                </button>
              </Badge>
            </Tippy>
            {!isMobile && hasShareAccess && (
              <Tippy content='Export as CSV' placement='bottom'>
                <button
                  className={styles.filterButton}
                  onClick={() => {
                    setDownloadOpen(true);
                    sendAnalytics({ defaultName: 'Spectrometer_Download_Csv' });
                  }}
                >
                  <i className='ri-file-download-line'></i>
                </button>
              </Tippy>
            )}
          </div>
        </StretchContainer>
      </div>
    </ErrorBoundary>
  );
};

function mapStateToProps(state) {
  return {
    userDetail: state.userDetail
  };
}
const mapDispatchToProps = { notifyOnSlack };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAnalytics(SpectroHeader, 'PG_GRADE_LISTING'));
