import { cFetch, token } from './apiUtils';
import config from '../config/config';
import { createQueryParam } from '../utils';

export async function getSpectroList(apiParams) {
  let queryParam = createQueryParam(apiParams);

  return cFetch(
    `${config.API_URL}/api/iot/spectrometer_v2/` +
      (queryParam ? '?' + queryParam : ''),
    {
      method: 'GET',
      headers: {
        authorization: token()
      }
    }
  );
}

// export function getSpectroMeter(spectroId) {
//   return cFetch(`${config.API_URL}/api/c/spectrometer/${spectroId}/`, {
//     method: "GET",
//     headers: {
//       authorization: token(),
//     },
//   });
// }

export function AddSpectrometer(data) {
  return cFetch(`${config.API_URL}/api/iot/spectrometer/`, {
    method: 'POST',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
}

export function UpdateSpectrometer(id, data) {
  return cFetch(`${config.API_URL}/api/iot/spectrometer/${id}/`, {
    method: 'PATCH',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data })
  });
}

export function spectroOverviewData(apiParams) {
  let queryParam = createQueryParam(apiParams);
  return cFetch(
    `${config.API_URL}/api/iot/analytic/spectro/` +
      (queryParam ? '?' + queryParam : ''),
    {
      method: 'GET',
      headers: {
        authorization: token()
      }
    }
  );
}

export function spectroFilterOptions() {
  return cFetch(`${config.API_URL}/api/iot/filter/spectro/`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
}

export async function getSpectroReading(id) {
  return cFetch(`${config.API_URL}/api/iot/spectrometer_v2/${id}/`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
}

export async function getAddDilData(id) {
  return cFetch(`${config.API_URL}/api/iot/additon_dilution/${id}/`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
}
export async function updateAddDilData(id, data) {
  // console.log(id, data);
  return cFetch(`${config.API_URL}/api/iot/additon_dilution/${id}/`, {
    method: 'PATCH',
    headers: {
      authorization: token(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ ...data })
  });
}

export function getAddDilInventoryItems() {
  return cFetch(`${config.API_URL}/api/s/item_inventory/`, {
    method: 'GET',
    headers: {
      authorization: token(),
      'Content-Type': 'application/json'
    }
  });
}

export const getInventoryByList = apiParams => {
  const apiParamsFinal = {
    page_size: apiParams?.page_size,
    page: apiParams?.page,
    cm_type: apiParams?.cm_type,
    search: apiParams?.search,
    item_grade__grade__id: apiParams?.item_grade__grade__id
  };
  const queryParam = createQueryParam(apiParamsFinal);
  return cFetch(`${config.API_URL}/api/s/item_inventory/?${queryParam}`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
};

export const getInventoryDetail = id => {
  return cFetch(`${config.API_URL}/api/s/item_inventory/${id}/`, {
    method: 'GET',
    headers: {
      authorization: token()
    }
  });
};

export const updateItemInventory = (id, updatedMaterialDetails) => {
  return cFetch(`${config.API_URL}/api/s/item_inventory/${id}/`, {
    method: 'PATCH',
    headers: {
      authorization: token(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(updatedMaterialDetails)
  });
};

export async function getPartNames() {
  try {
    const response = await cFetch(
      `${config.API_URL}/api/cm/metalgrade/part-name/`,
      {
        method: 'GET',
        headers: {
          authorization: token()
        }
      }
    );

    return response.part_names;
  } catch (err) {
    console.error(err);
  }
}
export async function getPartNumbers() {
  try {
    const response = await cFetch(
      `${config.API_URL}/api/cm/metalgrade/part-no/`,
      {
        method: 'GET',
        headers: {
          authorization: token()
        }
      }
    );
    return response.part_no;
  } catch (err) {
    console.error(err);
  }
}
