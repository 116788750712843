import { toast } from 'react-toastify';
import { cFetch, token } from '../../api/apiUtils';
import config from '../../config/config';
import {
  BASIC_STEP,
  DEFAULT_DI_TC,
  DEFAULT_GCI_TC,
  DI_ID,
  GCI_ID
} from '../../constants/grade';
import { COMMON_ACTION_TYPES } from '../../redux/commonActionTypes';
import { GRADE_LIST_ACTION_TYPES } from '../../redux/grade/gradeActionType';

import { EAF } from '../../constants/Furnace';
import {
  DeepArrayDiffWithKeys,
  flatArrayDiff,
  simpleObjectDiff
} from '../../lib/json-diff';
import { createQueryParam } from '..';
const { LADLE, NODULARIZER } = COMMON_ACTION_TYPES;

/**
 * Function to fetch header stats for grades
 *
 * @returns {Promise<object>} - JSON object containing header stats data
 */
export async function getHeaderStat() {
  try {
    const data = await fetch(`${config.API_URL}/api/cm/grades/stats/`, {
      method: 'GET',
      headers: {
        authorization: token(),
        'Content-Type': 'application/json'
      }
    });
    return data.json();
  } catch (error) {
    console.error(error);
  }
}

/**
 * Function to create a new grade
 *
 * @param {object} overviewData - Data required to create a new grade
 * @returns {Promise<object>} - JSON object containing the created grade data
 */
export async function createGrade(overviewData) {
  try {
    const headers = {
      method: 'POST',
      headers: {
        authorization: token(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(overviewData)
    };

    const data = await cFetch(
      `${config.API_URL}/api/c/grades_add_api/`,
      headers
    );
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
}

/**
 * Function to update a new grade
 *
 * @param {object} overviewData - Data required to create a new grade
 * @returns {Promise<object>} - JSON object containing the created grade data
 */
export async function updateGrade(id, overviewData) {
  try {
    const headers = {
      method: 'PATCH',
      headers: {
        authorization: token(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(overviewData)
    };

    const data = await cFetch(
      `${config.API_URL}/api/c/grades_add_api/${id}/`,
      headers
    );
    return data;
  } catch (error) {
    throw new Error(error.message);
  }
}

/**
 * Function to check if the given Tag ID exists
 *
 * @param {number} tagId - Tag ID to be verified
 * @returns {Promise<boolean>} - Boolean value indicating if the Tag ID exists
 */
export async function checkGradeTagId(tagId) {
  try {
    const headers = {
      method: 'GET',
      headers: {
        authorization: token(),
        'Content-Type': 'application/json'
      }
    };

    const response = await cFetch(
      `${config.API_URL}/api/cm/verify_grade_tag_id/${tagId}`,
      headers
    );

    return response?.exists;
  } catch (error) {
    console.error(error);
  }
}

export async function createNewGradeTagId() {
  try {
    const headers = {
      method: 'GET',
      headers: {
        authorization: token(),
        'Content-Type': 'application/json'
      }
    };

    const response = await cFetch(
      `${config.API_URL}/api/cm/metalgrade/gen_grade_tag_id/`,
      headers
    );

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Function to check if the given part name exists
 *
 * @param {number} partName - part name to be verified
 * @returns {Promise<boolean>} - Boolean value indicating if the part name exists
 */
export async function checkPartName(data) {
  const queryParam = createQueryParam(data);
  try {
    const headers = {
      method: 'GET',
      headers: {
        authorization: token(),
        'Content-Type': 'application/json'
      }
    };

    const response = await cFetch(
      `${config.API_URL}/api/c/partname/verify?${queryParam}`,
      headers
    );

    return response?.exists;
  } catch (error) {
    console.error(error);
  }
}

export const AddGradeSteps = {
  STEP_1: 'step_1',
  STEP_2: 'step_2',
  STEP_3: 'step_3',
  STEP_4: 'step_4'
};

/**
 * Function to update the current step in the Add Grade process
 *
 * @param {string} nextStep - The next step to be set
 * @param {Function} dispatch - The Redux dispatch function
 * @returns {null} null
 */
export const handleNext = (nextStep, dispatch) => {
  dispatch({ type: 'SET_CURRENT_STEP', payload: nextStep });
  return null;
};

export const resetInventoryItem = ({ dispatch }) => {
  dispatch({ type: 'GET_ITEM_INVENTORY_BY_LIST', data: null });
  return null;
};

export const handleAddGradeStepDataUpdate = (data, dispatch, step) => {
  if (step == BASIC_STEP) {
    dispatch({
      type: GRADE_LIST_ACTION_TYPES.ADD_GRADE_BASIC_STEP_DATA_UPDATE,
      payload: data
    });
  } else {
    dispatch({
      type: GRADE_LIST_ACTION_TYPES.ADD_GRADE_INVENTORY_ITEM_STEP_DATA_UPDATE,
      payload: data
    });
  }
  return null;
};

const CI_ID = 3;

/**
 * This function calculates the relaxed min and max for each element in a dataset
 * based on the gradeType and the specific relaxations given in the lookup table.
 *
 * @param {Array} data - The dataset containing elements with their respective min and max values.
 * @param {number} gradeType - The gradeType (1 for DI, 3 for CI). Calculations will only proceed if gradeType is DI or CI.
 *
 * @returns {Array} - The dataset with updated relaxed min and max values for each element.
 */
export const calculateRelaxedMinMax = async (data, gradeType) => {
  try {
    const apiData = {
      grade_tc: data.map(d => ({
        ...d,
        id: d.element,
        element__symbol: d.symbol || d.element__symbol,
        min: Number(d.min),
        max: Number(d.max)
      })),
      grade_type: gradeType
    };
    const headers = {
      method: 'POST',
      headers: {
        authorization: token(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(apiData)
    };
    const resp = await cFetch(
      `${config.API_URL}/api/c/calculate/tolerance/`,
      headers
    );
    const mergedData = data.map(d => {
      const respItem = resp.grade_tc.find(r => r.element === d.element);
      return respItem
        ? {
            ...d,
            relaxed_min: respItem.relaxed_min,
            relaxed_max: respItem.relaxed_max
          }
        : d;
    });
    return mergedData;
  } catch (err) {
    console.error(err);
    return data;
  }

  // if (![DI_ID, CI_ID].includes(gradeType)) {
  //   return data; // Return original data if gradeType is not DI or CI
  // }

  // // Lookup table for the percentage increase/decrease for each element
  // const relaxedPercentageTable = {
  //   C: gradeType === DI_ID ? 1.5 : 2,
  //   Si: gradeType === DI_ID ? 1 : 2,
  //   Mn: gradeType === DI_ID ? 2 : 3,
  //   S: 10,
  //   P: 10,
  //   Cr: gradeType === DI_ID ? 0 : 3,
  //   Cu: gradeType === DI_ID ? 0 : 3,
  //   Mg: gradeType === DI_ID ? 1 : 0
  // };

  // // Use map to create a new array, and spread operator to create new objects
  // const newData = data.map(item => {
  //   // Check if this element's symbol is in the lookup table
  //   if (relaxedPercentageTable[item.symbol]) {
  //     const percentage = 1 + relaxedPercentageTable[item.symbol] / 100;

  //     const min = parseFloat(item.min);
  //     const relaxed_min = !isNaN(min) ? min / percentage : item.relaxed_min;

  //     const max = parseFloat(item.max);
  //     const relaxed_max = !isNaN(max) ? max * percentage : item.relaxed_max;

  //     // Return a new object with the modified values
  //     return {
  //       ...item,
  //       relaxed_min:
  //         !isNaN(relaxed_min) && relaxed_min !== ''
  //           ? parseFloat(relaxed_min.toFixed(6))
  //           : '',
  //       relaxed_max:
  //         !isNaN(relaxed_max) && relaxed_max !== ''
  //           ? parseFloat(relaxed_max.toFixed(6))
  //           : ''
  //     };
  //   }

  //   // If the element's symbol is not in the lookup table, return the item as is
  //   return item;
  // });

  // return newData;
};

/**
 * Parses an error object or a JSON error string and formats the error message(s).
 *
 * The function expects either a JSON string or an error object. If a JSON string is passed,
 * it attempts to parse it into an object. If an object is not passed, or if parsing fails,
 * it returns an error message indicating the problem.
 *
 * The function then iterates over properties in the error object. If the property value is
 * an array, it further iterates over the items in the array, each of which should be an object.
 *
 * If an item's property value is an array, the function assumes these are error details.
 * It constructs an error message for each detail and appends it to a final error message string.
 *
 * The function finally returns the error message string, which is empty if there were no errors.
 *
 * @param {object|string} error - An error object or a JSON string representing an error.
 * @returns {string} The parsed and formatted error message(s).
 * @throws {string} If the error is not a valid JSON string or object, returns an error message.
 */
export function parseError(error) {
  let errorMessage = '';

  // Check if error is a string (JSON)
  if (typeof error === 'string') {
    try {
      error = JSON.parse(error);
    } catch (e) {
      return 'Error data is not a valid JSON string.';
    }
  }

  // Check if error is an object
  if (typeof error !== 'object' || error === null) {
    return 'Error data is not an object.';
  }

  // Iterate over properties in the error object
  for (let prop in error) {
    if (Array.isArray(error[prop])) {
      error[prop].forEach((item, index) => {
        // Check if item is an object
        if (typeof item !== 'object' || item === null) {
          return;
        }

        // Iterate over properties in each item
        for (let field in item) {
          if (Array.isArray(item[field])) {
            item[field].forEach(errorDetail => {
              errorMessage += `Item ${index + 1} - ${field}: ${errorDetail}\n`;
            });
          }
        }
      });
    }
  }

  return errorMessage;
}

export const getDefaultElement = id => {
  return id == DI_ID ? DEFAULT_DI_TC : id == GCI_ID ? DEFAULT_GCI_TC : [];
};

export function hasNonEmptyValue(value) {
  return value !== undefined && value !== null && value !== '';
}

/**
 * Handles selection logic based on item inventory and user selection.
 *
 * @param {Array} itemInventory - The array of items in inventory.
 * @param {Array} selected - The array of selected items.
 * @param {Function} onClick - The function to be executed on successful selection logic.
 * @param {Object} gradeDetail - Details of the grade.
 * @param {Object} createGradeData - Data for creating a grade.
 * @param {Event} e - The event object (optional).
 */
export const handleSelectionLogic = ({
  selected,
  grade_tc,
  bath_tc,
  ladle_tc,
  addition_tc,
  pit_tc,
  onClick,
  gradeCatagory,
  add_dil_inactive_elements,
  e,
  has_bath_chemistry = false
}) => {
  e?.preventDefault();

  const isDuctileIron = gradeCatagory == DI_ID;
  const errors = [];

  const hasLadle = selected.filter(
    d => d.item_type === LADLE || d.cm_type === LADLE
  );
  const hasNod = selected.filter(
    d => d.item_type === NODULARIZER || d.cm_type === NODULARIZER
  );

  const isLadle = d => d.cm_type === LADLE;

  if (selected.length < 1) {
    errors.push('Please select at least one item');
  }

  if (
    gradeCatagory == DI_ID &&
    ((add_dil_inactive_elements || []).includes('S') ||
      (add_dil_inactive_elements || []).includes('Mg'))
  ) {
    errors.push('S and Mg are required for addition / dilution');
  }

  if (isDuctileIron) {
    if (hasLadle.length == 0) {
      errors.push('Please select at least one Ladle item');
    }
    if (hasNod.length != 1) {
      errors.push('Please select at most one Nodularizer item');
    }
  }

  grade_tc?.forEach(item => {
    if (
      Number(item.min) &&
      Number(item.max) &&
      Number(item.min) > Number(item.max)
    ) {
      errors.push('In grade tc, Min cannot be greater than max');
    }
  });

  if (has_bath_chemistry) {
    // Check min and max for bath_tc
    bath_tc?.forEach(item => {
      if (
        Number(item.min) &&
        Number(item.max) &&
        Number(item.min) > Number(item.max)
      ) {
        errors.push('In bath tc, Min cannot be greater than max');
      } else if (Number(item.min) && !Number(item.max)) {
        errors.push('In bath tc, Max is required');
      }
    });
  }

  ladle_tc?.forEach(item => {
    if (
      Number(item.min) &&
      Number(item.max) &&
      Number(item.min) > Number(item.max)
    ) {
      errors.push('In ladle tc, Min cannot be greater than max');
    }
  });

  addition_tc?.forEach(item => {
    if (
      Number(item.min) &&
      Number(item.max) &&
      Number(item.min) > Number(item.max)
    ) {
      errors.push('In addition tc, Min cannot be greater than max');
    }
  });

  pit_tc?.forEach(item => {
    if (
      Number(item.min) &&
      Number(item.max) &&
      Number(item.min) > Number(item.max)
    ) {
      errors.push('In Pit tc, Min cannot be greater than max');
    }
  });

  selected.forEach(d => {
    if ([LADLE, NODULARIZER].includes(d.cm_type)) {
      if (!d.min_qty) {
        errors.push('Please provide valid Min qty field');
      }
      if (isLadle(d) && !d.max_qty) {
        errors.push('Please provide valid Max qty field for Ladle');
      }
    }
    if (d.min_qty && d.max_qty && Number(d.min_qty) > Number(d.max_qty)) {
      errors.push('Min cannot be greater than max');
    }
    if (
      (d.min_qty && Number(d.min_qty) < 0) ||
      (d.max_qty && Number(d.max_qty) < 0)
    ) {
      errors.push('Value cannot be less than 0');
    }
    if (
      (d.min_qty && Number(d.min_qty) > 100) ||
      (d.max_qty && Number(d.max_qty) > 100)
    ) {
      errors.push('Value cannot be greater than 100');
    }
  });

  if (errors.length > 0) {
    const uniqueErrors = [...new Set(errors)];
    uniqueErrors.map(err => toast.error(err));
  } else {
    onClick(selected);
  }
};

/**
 * Checks if the furnace type is Electric Arc Furnace (EAF).
 * @param {string} type - The type of the furnace. Defaults to 'IF' (Induction Furnace).
 * @returns {boolean} - Returns true if the furnace type is EAF, otherwise false.
 */
export const isEafFurnace = (type = EAF) => type == EAF;

export const isItemAddDilInactive = (data = [], item) =>
  data?.add_dil_inactive_elements?.some(
    ele => ele == (item.symbol || item.element__symbol)
  );

export const getModuleUpdateDifferences = (
  initialData,
  updatedData,
  furnace_type
) => {
  const simpleDifferences = simpleObjectDiff(initialData, updatedData);
  const flatDifferences = flatArrayDiff(
    initialData.grade_item || [],
    updatedData.grade_item || [],
    'item'
  );
  const itemMinMaxChanges = DeepArrayDiffWithKeys(
    initialData.grade_item?.map(item => ({
      ...item,
      min_qty: item.min_qty || '',
      max_qty: item.max_qty || ''
    })) || [],
    updatedData.grade_item?.map(item => ({
      ...item,
      min_qty: item.min_qty || '',
      max_qty: item.max_qty || ''
    })) || [],
    'item',
    ['min_qty', 'max_qty']
  );

  const AddDilChanges = {
    added: updatedData?.add_dil_inactive_elements?.filter(
      data =>
        !initialData?.add_dil_inactive_elements?.find(
          initialItem => initialItem == data
        )
    ),
    removed: initialData?.add_dil_inactive_elements?.filter(
      initialItem =>
        !updatedData?.add_dil_inactive_elements?.find(
          newItem => newItem == initialItem
        )
    )
  };
  const [deepDifferences, deepDifferencesPit] = ['grade_tc', 'pit_tc'].map(
    key =>
      DeepArrayDiffWithKeys(
        (initialData?.[key] || []).map(d => ({
          ...d,
          relaxed_min: Number(d.relaxed_min) || '',
          relaxed_max: Number(d.relaxed_max) || '',
          min: Number(d.min) || '',
          max: Number(d.max) || '',
          losses: Number(d.losses) || ''
        })),
        (updatedData?.[key] || []).map(d => ({
          ...d,
          relaxed_min: Number(d.relaxed_min) || '',
          relaxed_max: Number(d.relaxed_max) || '',
          min: Number(d.min) || '',
          max: Number(d.max) || '',
          losses: Number(d.losses) || ''
        })),
        'element',
        ['relaxed_max', 'relaxed_min', 'min', 'max', 'losses']
      )
  );

  const [deepDifferencesBath, deepDifferencesAddition, deepDifferencesLadle] = [
    'bath_tc',
    'addition_tc',
    'ladle_tc'
  ].map(key =>
    DeepArrayDiffWithKeys(
      (initialData?.[key] || []).map(d => ({
        ...d,
        min: Number(d.min) || '',
        max: Number(d.max) || ''
      })),
      (
        updatedData?.[key]?.filter(tc => Number(tc.min) || Number(tc.max)) || []
      ).map(d => ({
        ...d,
        min: Number(d.min) || '',
        max: Number(d.max) || ''
      })),
      'element',
      ['min', 'max']
    )
  );

  const eafTcData = isEafFurnace(furnace_type)
    ? [
        deepDifferencesBath,
        deepDifferencesAddition,
        deepDifferencesLadle,
        deepDifferencesPit
      ]
    : [deepDifferencesBath, deepDifferences];
  const hasTcChanged = eafTcData
    ?.map(
      differences =>
        [...differences?.diff, ...differences?.added, ...differences?.removed]
          ?.length > 0
    )
    ?.some(Boolean);

  const isChanged =
    simpleDifferences.changed.length ||
    flatDifferences?.added?.length > 0 ||
    flatDifferences?.removed?.length > 0 ||
    AddDilChanges?.added?.length ||
    AddDilChanges?.removed?.length ||
    itemMinMaxChanges?.diff?.length ||
    hasTcChanged;

  return {
    simpleDifferences,
    flatDifferences,
    AddDilChanges,
    itemMinMaxChanges,
    deepDifferences,
    deepDifferencesPit,
    deepDifferencesBath,
    deepDifferencesAddition,
    deepDifferencesLadle,
    isChanged
  };
};

export const getPartModuleUpdateDifferences = (
  initialData,
  updatedData,
  furnace_type
) => {
  const simpleDifferences = simpleObjectDiff(initialData, updatedData);
  const flatDifferences = flatArrayDiff(
    initialData.part_items || [],
    updatedData.part_items || [],
    'item'
  );
  const itemMinMaxChanges = DeepArrayDiffWithKeys(
    initialData.part_items?.map(item => ({
      ...item,
      min_qty: item.min_qty || '',
      max_qty: item.max_qty || ''
    })) || [],
    updatedData.part_items?.map(item => ({
      ...item,
      min_qty: item.min_qty || '',
      max_qty: item.max_qty || ''
    })) || [],
    'item',
    ['min_qty', 'max_qty']
  );

  const AddDilChanges = {
    added: updatedData?.add_dil_inactive_elements?.filter(
      data =>
        !initialData?.add_dil_inactive_elements?.find(
          initialItem => initialItem == data
        )
    ),
    removed: initialData?.add_dil_inactive_elements?.filter(
      initialItem =>
        !updatedData?.add_dil_inactive_elements?.find(
          newItem => newItem == initialItem
        )
    )
  };
  const [deepDifferences, deepDifferencesPit] = ['part_tc', 'pit_tc'].map(key =>
    DeepArrayDiffWithKeys(
      (initialData?.[key] || []).map(d => ({
        ...d,
        relaxed_min: Number(d.relaxed_min) || '',
        relaxed_max: Number(d.relaxed_max) || '',
        min: Number(d.min) || '',
        max: Number(d.max) || '',
        losses: Number(d.losses) || ''
      })),
      (updatedData?.[key] || []).map(d => ({
        ...d,
        relaxed_min: Number(d.relaxed_min) || '',
        relaxed_max: Number(d.relaxed_max) || '',
        min: Number(d.min) || '',
        max: Number(d.max) || '',
        losses: Number(d.losses) || ''
      })),
      'element',
      ['relaxed_max', 'relaxed_min', 'min', 'max', 'losses']
    )
  );

  const [deepDifferencesBath, deepDifferencesAddition, deepDifferencesLadle] = [
    'bath_tc',
    'addition_tc',
    'ladle_tc'
  ].map(key =>
    DeepArrayDiffWithKeys(
      (initialData?.[key] || []).map(d => ({
        ...d,
        min: Number(d.min) || '',
        max: Number(d.max) || ''
      })),
      (
        updatedData?.[key]?.filter(tc => Number(tc.min) || Number(tc.max)) || []
      ).map(d => ({
        ...d,
        min: Number(d.min) || '',
        max: Number(d.max) || ''
      })),
      'element',
      ['min', 'max']
    )
  );

  const eafTcData = isEafFurnace(furnace_type)
    ? [
        deepDifferencesBath,
        deepDifferencesAddition,
        deepDifferencesLadle,
        deepDifferencesPit
      ]
    : [deepDifferencesBath, deepDifferences];
  const hasTcChanged = eafTcData
    ?.map(
      differences =>
        [...differences?.diff, ...differences?.added, ...differences?.removed]
          ?.length > 0
    )
    ?.some(Boolean);

  const isChanged =
    simpleDifferences.changed.length ||
    flatDifferences?.added?.length > 0 ||
    flatDifferences?.removed?.length > 0 ||
    AddDilChanges?.added?.length ||
    AddDilChanges?.removed?.length ||
    itemMinMaxChanges?.diff?.length ||
    hasTcChanged;
  return {
    simpleDifferences,
    flatDifferences,
    AddDilChanges,
    itemMinMaxChanges,
    deepDifferences,
    deepDifferencesPit,
    deepDifferencesBath,
    deepDifferencesAddition,
    deepDifferencesLadle,
    isChanged
  };
};
