import {
  addPlanFormInitalData,
  addPlanStepCount
} from '../constants/ProductionPlan';
import { AddGradeSteps } from '../utils/Grade';
import { COMMON_ACTION_TYPES } from './commonActionTypes';

const initialState = {
  auth: {
    token: null,
    isLoggedIn: 0,
    isAuthenticated: false,
    user: null,
    mobileno: null
  },
  userDetail: {},
  userDetailById: {},
  categories: {
    results: []
  },
  subcategories: {
    results: []
  },
  products: {
    results: []
  },
  productDetail: {},
  cartItems: [],
  favourites: [],
  users: [],
  spares: [],
  apiCallsInProgress: 0,
  initVal: {
    categoryId: '',
    subCategoryId: '',
    scrollpos: 0
  },
  currentstep: COMMON_ACTION_TYPES.BASIC,
  inventory: [],
  spectrometer: null,
  spectrometerList: null,
  productionPlanList: null,
  spectrometerOverviewData: {},
  spectrometerFilterOptions: {},
  spectroReading: {},
  spectroReadingAddDil: {},
  itemInventory: [],
  inventoryDetails: [],
  itemInventoryByList: [],
  gradeList: [],
  gradeDetail: {},
  addGradeCurrentStep: AddGradeSteps.STEP_1,
  addGradeStepData: {
    basic_data: {},
    grade_item: []
  },
  partList: [],
  partDetail: {},
  addPartCurrentStep: AddGradeSteps.STEP_1,
  addPartStepData: {
    basic_data: {},
    part_item: []
  },
  inventoryAddDil: {
    data_loaded: false,
    data: undefined
  },
  addControlPlanCurrentStep: AddGradeSteps.STEP_1,
  addControlPlanData: {
    tag_id: '',
    category: '',
    subcategory: '',
    acceptance_limit: []
  },
  testReportList: [],
  testReportDetail: {},
  testReportHeaderStat: {},
  pendingTestCount: 0,
  heatLogDetail: {},
  addPlanCurrentStep: addPlanStepCount.STEP_1,
  addPlanData: addPlanFormInitalData,
  planDetail: addPlanFormInitalData,
  analyticsKey: 'NP'
};

export default initialState;
