import { UPLOAD_FILE } from '../actionTypes';
import {
  actionStartType,
  actionSuccessType,
  actionFailedType,
  actionClearType
} from '../actionUtils/actionTypes';

const initialState = { isLoading: false, uploadSuccess: false };

const uploadFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionStartType(UPLOAD_FILE):
      return { isLoading: true, uploadSuccess: false };

    case actionSuccessType(UPLOAD_FILE):
      return { isLoading: false, uploadSuccess: true };

    case actionFailedType(UPLOAD_FILE):
      return { isLoading: false, uploadSuccess: false };

    case actionClearType(UPLOAD_FILE):
      return initialState;

    default:
      return state;
  }
};

export default uploadFileReducer;
