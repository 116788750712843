import React from 'react';
import StretchContainer from '../../../General/StretchContainer';
import { Skeleton } from '@mui/material';

const ListingTableLoader = () => {
  return (
    <StretchContainer
      sx={{ flexWrap: 'wrap', gap: 0, overflow: 'hidden', minHeight: '100vh' }}
      noPadding
    >
      {new Array(30).fill('').map((d, i) => {
        if (i % 2) {
          return (
            <Skeleton
              key={i}
              height={50}
              animation={'wave'}
              sx={{ backgroundColor: '#f5f5f5', borderRadius: '0.25rem' }}
              variant='rectangular'
              width={'100%'}
            />
          );
        } else
          return (
            <Skeleton
              key={i}
              height={50}
              animation={'wave'}
              sx={{ backgroundColor: '#e7e7e7', borderRadius: '0.25rem' }}
              variant='rectangular'
              width={'100%'}
            />
          );
      })}
    </StretchContainer>
  );
};

export default ListingTableLoader;
