import { SPACE_ACTION_TYPES } from './spareActionTypes';
import initialState from '../initial-state';

export default function products(state = initialState.spares, action) {
  switch (action.type) {
    case SPACE_ACTION_TYPES.LOAD_SPARES_SUCCESS:
      return action.spares;
    default:
      return state;
  }
}
