import React, { lazy } from 'react';
import LoadLazyComponent from '../../components/LoadLazyComponent';
import {
  ActiveChargeMixIcon,
  ActiveDashBoardIcon,
  ActiveHoldingIcon,
  ChargeMixIcon,
  DashboardIcon,
  HoldingIcon
} from '../../assets/icons/modifiedIcons';
import { Slug } from './permissionConstants';
import MeltingFurnaceHeader from '../../componentsV2/Layout/Header/MeltingFurnaceHeader';

const MeltingFurnace = lazy(() =>
  LoadLazyComponent(() => import('../../pages/ChargeMixV2'))
);

const DashBoard = lazy(() =>
  LoadLazyComponent(() => import('../../pages/CMDashboard/CMDashboard'))
);

const FurnaceHolding = lazy(() =>
  LoadLazyComponent(() => import('../../pages/FurnaceHolding'))
);

export const ChargeMix_route = {
  name: 'CHARGEMIX',
  can_view: [Slug.CHARGEMIX],
  paid_feature: true,
  child_routes: [
    {
      name: 'Dashboard',
      icon: DashboardIcon,
      active_icon: ActiveDashBoardIcon,
      path: '/cm/dashboard',
      slug: Slug.CHARGEMIX,
      matchRoutes: '^/cm/dashboard',
      header: () => 'Chargemix Dashboard',
      component: <DashBoard />,
      permission: []
    },
    {
      name: 'Melting furnace',
      icon: ChargeMixIcon,
      active_icon: ActiveChargeMixIcon,
      path: '/cm/chargemix//*',
      slug: Slug.CHARGEMIX,
      matchRoutes: '^/cm/chargemix',
      header: () => <MeltingFurnaceHeader />,
      component: <MeltingFurnace />,
      permission: []
    },
    {
      name: 'Holding furnace',
      icon: HoldingIcon,
      active_icon: ActiveHoldingIcon,
      path: '/cm/holding',
      slug: Slug.CHARGEMIX,
      matchRoutes: '^/cm/holding',
      header: () => 'Holding Furnace',
      component: <FurnaceHolding />,
      permission: []
    }
  ]
};
