import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';

import rootReducer from './rootReducer';

import { analyticsMiddleware } from './middlewares/analyticsMiddleware';
import { afterActionCallMiddleware } from './middlewares/afterActionCallMiddleware';

export default function configureStore(initialState) {
  const isProduction = process.env.NODE_ENV === 'production';

  const composeEnhancers =
    (!isProduction && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  const startState = {
    ...initialState,
    auth: {
      token: localStorage.getItem('tokenv2'),
      isLoggedIn: parseInt(localStorage.getItem('login_status'))
    }
  };

  const middlewares = [thunk, afterActionCallMiddleware, analyticsMiddleware];

  if (!isProduction) {
    middlewares.push(reduxImmutableStateInvariant());
  }

  return createStore(
    rootReducer,
    startState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}
