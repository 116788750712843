import { INIT_ACTION_TYPES } from './initActionTypes';
import initialState from '../initial-state';

export default function initVal(state = initialState.initVal, action) {
  switch (action.type) {
    case INIT_ACTION_TYPES.INIT_VAR_UPDATE:
      return {
        ...state,
        categoryId: action.catId,
        subCategoryId: action.subCatId,
        scrollpos: action.scrollPos
      };
    default:
      return state;
  }
}
