import classes from './HeaderLayout.module.scss'
import clsx from 'clsx';
import React from 'react';
import { useLocation } from 'react-router-dom';
import AdminSwitch from '../../AdminSwitch';
import { RouteList } from '../../../constants/Routes';
import UserProfile from './UserProfile';

export function determineRouteHeader(locationPathname, routeList) {
  const findMatchingChildRoute = childRoutes =>
    childRoutes.find(child => {
      const regex = new RegExp(child.matchRoutes);
      return regex.test(locationPathname);
    });

  const matchedChild = routeList
    .map(route => findMatchingChildRoute(route.child_routes))
    .find(child => child !== undefined);

  return matchedChild ? matchedChild.header() : '';
}

const Header = () => {
  const location = useLocation();

  return (
    <header className={clsx(classes.header)}>
      <div className={classes.titleContainer}>
        {determineRouteHeader(location.pathname, RouteList)}
      </div>
      <div className={classes.content}>
          <div className={classes.actionContainer}>
            <AdminSwitch />
          </div>
          <UserProfile />
      </div>
    </header>
  );
};

export default Header;
