import ReactGA from 'react-ga';
import config from '../config/config';
import { Add_Amplitude_Event_Analytics } from './amplitudeAnalytics';

/**
 * sendAnalytics is a fucntion which sends analytics details to google analytics & amplitude
 * @summary If the description is long, write your summary here. Otherwise, feel free to remove this.
 * @param {string} googleName - event in googleAnalytics
 * @param {string} googleDetails - details value of event in googleAnalytics
 * @param {string} amplitudeName - event in amplitude
 * @param {string} amplitudeDetails - details value of event in amplitude
 * @param {string} defaultName - details value of event for all
 * @param {string} defaultDetails - details value of event for all
 */
export function sendAnalytics({
  googleName,
  googleDetails,
  amplitudeName,
  amplitudeDetails,
  defaultName,
  defaultDetails
}) {
  if (defaultName) {
    googleName = defaultName;
    amplitudeName = defaultName;
  }

  if (defaultDetails) {
    googleDetails = defaultDetails;
    amplitudeDetails = defaultDetails;
  }

  if (googleName || googleDetails) {
    AddAnalytics(googleName, googleDetails);
  }
  if (amplitudeName || amplitudeDetails) {
    const ampdetails = {
      description: amplitudeDetails
    };
    Add_Amplitude_Event_Analytics(amplitudeName, ampdetails);
  }
}

/**
 * This function used to add events in Google Analytics.
 * @param {string} category_name
 * @param {string} action_value
 */
export function AddAnalytics(category_name, action_value) {
  ReactGA.event({
    category: category_name,
    action: action_value
  });
}

/**
 * This function used to show the current active page in Google Analytics
 * @param {string} page_name
 */
export function AnalyticsPageView(page_name) {
  ReactGA.pageview(page_name);
}

/**
 * This function used to show the activities of a single user in Google Analytics
 * @param {string} UserName_Id
 */
export function ViewUserActivityAnalytics(UserName_Id) {
  ReactGA.ga('set', 'userId', UserName_Id);
}

/**
 * Measure product details view after clicking on the product listing, a user views the product details page.
 * @param {Object} productDetail
 */
export function getProductDetails(productDetail, InitialQty) {
  AddProductAnalytics(
    productDetail.product_id,
    InitialQty,
    productDetail.price
  );
  ReactGA.ga('ec:setAction', 'detail');
}

/**
 * Called when the user begins the checkout process.
 * @param {Array} Items An array representing the user's shopping cart.
 */
function ProductAnalytics(Items) {
  for (var i = 0; i < Items.length; i++) {
    var cart = Items[i];
    AddProductAnalytics(
      cart.product,
      cart.quantity,
      cart.product.suppliers[0].price
    );
  }
}

export function CheckoutProductAnalytics(cartItems) {
  ProductAnalytics(cartItems);
  ReactGA.ga('ec:setAction', 'checkout');
}

/**
 * Called when the user clicked  on the Place Order.
 * @param {Array} Items An array representing the user's shopping cart.
 */
function PlaceOrderProductAnalytics(Items) {
  for (var i = 0; i < Items.length; i++) {
    var cart = Items[i];
    AddProductAnalytics(cart.product, cart.quantity, cart.price);
  }
}

function AddProductAnalytics(product, quantity, price) {
  ReactGA.ga('ec:addProduct', {
    id: product.id,
    name: product.name,
    category: product.category.name,
    brand: product.brand != null ? product.brand.name : null,
    quantity,
    price
  });
}

export function placeorderAnalytics(placeorder) {
  placeorder.forEach(order => {
    PlaceOrderProductAnalytics(order.order_item);
    ReactGA.ga('ec:setAction', 'purchase', {
      id: `${config.GA_ACCESS_CODE}`,
      revenue: order.total,
      tax: order.tax,
      shipping: order.shipping_charge
    });
  });
}
