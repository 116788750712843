import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { pathMatch } from '../../../utils';
import classes from './HeaderLayout.module.scss';
import { Breadcrumb } from './GradeHeader';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import useQuery from '../../../hooks/useQuery';
import CancelModal from '../../ProductionPlan/AddPlan/CancelModal';

/**
 * Header component for the Production Plan module of the application.
 * This component displays a header with dynamic content based on the current route.
 * When viewing test details, it shows an 'ArrowBackIcon', a breadcrumb navigation to 'Production Plan',
 * and the name of the test with its status color-coded.
 *
 * @returns {JSX.Element} The header component for the Production Plan section, with content changing based on whether test details are being viewed.
 */
const ProductionPlanHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [query] = useQuery();
  const [cancelModal, setCancelModal] = useState(false);

  const { heat_no = '', status } = useSelector(state => state.planDetail);
  const addPlanCurrentStep = useSelector(state => state.addPlanCurrentStep);

  const isPlanDetail = pathMatch('/productionplan', '/\\d+', location.pathname);
  const isAddPlan = location.pathname.includes('/add');
  const isDuplicate = query?.duplicate;
  const isEdit = query?.edit;

  if (!isPlanDetail && !isAddPlan && !isDuplicate && !isEdit) {
    return <span>Heat Plan</span>;
  }

  let headerText = '';
  if (isPlanDetail) {
    headerText = `${heat_no}`;
  } else if (isAddPlan || isDuplicate) {
    headerText = `Add new Step - ${addPlanCurrentStep}`;
  } else if (isEdit) {
    headerText = 'Edit Plan';
  }

  return (
    <div>
      <div className={classes.breadCrumbContainer}>
        <>
          <Breadcrumb
            navigate={path => {
              if (query.edit || query.duplicate) setCancelModal(true);
              else navigate(path);
            }}
            text='Heat Plan'
            path='/productionplan'
          />
        </>
        {isPlanDetail && (
          <>
            <i className='ri-arrow-right-s-line' />
            <Breadcrumb
              sx={{ paddingLeft: 0 }}
              navigate={path => {
                if (query.edit || query.duplicate) setCancelModal(true);
                else navigate(path);
              }}
              text={status}
              path='/productionplan'
            />
          </>
        )}
        <i className='ri-arrow-right-s-line' />
        {headerText && (
          <span
            className={classes.heatDetailsHeaderText}
            style={{ fontSize: isMobile ?? '12px' }}
          >
            {headerText}
          </span>
        )}
      </div>
      <CancelModal
        open={cancelModal}
        heat_no={heat_no}
        handleClose={() => {
          setCancelModal(false);
        }}
        handleConfirm={() => {
          setCancelModal(false);
          navigate('/productionplan');
        }}
      />
    </div>
  );
};

export default ProductionPlanHeader;
