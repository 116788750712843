import * as getUserDetails from '../../api/useDetailApi';
import { beginApiCall, apiCallError } from '../apiStatus/apiStatusAction';
import { USER_DETAIL_ACTION_TYPES } from './userDetailActionTypes';

const defaultRm = {
  name: 'Rayhan',
  contact: '+918240794517',
  email: 'foundry@nowpurchase.com',
  slack_id: 'UPYPVDE21'
};

export function loadUserSuccess(user) {
  if (!user.customer.rm) {
    user.customer.rm = defaultRm;
  }

  return {
    type: USER_DETAIL_ACTION_TYPES.LOAD_USER_DETAIL_SUCCESS,
    user
  };
}

/**
 * This function is used to load the success of userdetail by UserId .
 * @param {Object} User
 * return type and userDetail
 */
export function loadUserIdSuccess(userById) {
  return {
    type: USER_DETAIL_ACTION_TYPES.LOAD_USER_BY_ID_DETAIL_SUCCESS,
    userById
  };
}

export function loadUserDetail() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getUserDetails
      .getUserDetails()
      .then(user => {
        dispatch(
          loadUserSuccess({
            ...user,
            plan: user?.customer?.is_paid ? 'PROFESSIONAL' : 'BASIC'
          })
        );

        return user;
      })
      .catch(error => {
        throw error;
      });
  };
}

/**
 * This function is used to load userdetail by UserId .
 * @param {integer} UserId
 * return userDetail
 */
export function loadUserDetailById(UserId) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return getUserDetails
      .getUserDetailbyUserId(UserId)
      .then(userById => {
        dispatch(loadUserIdSuccess(userById));
        return userById;
      })
      .catch(error => {
        throw error;
      });
  };
}
