export const afterActionCallMiddleware = store => next => action => {
  const { $actionMeta } = action;

  if ($actionMeta) {
    const { type, modificationType, enhancedPayload } = $actionMeta;
    const thisData = $actionMeta.thisData(store.getState()).data;

    const payloadArr = Array.isArray(action.payload.data)
      ? action.payload.data
      : [action.payload.data];

    let updatedData;

    switch (modificationType) {
      case 'replaceData':
        updatedData = enhancedPayload
          ? enhancedPayload(action.payload.data, thisData)
          : action.payload.data;
        break;

      case 'addData':
        updatedData = [...thisData, ...payloadArr];
        break;

      default:
        updatedData = thisData;
        break;
    }

    store.dispatch({ type, payload: { data: updatedData } });
  }

  return next(action);
};
