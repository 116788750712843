import { Add_Amplitude_Event_Analytics } from '../../utils/amplitudeAnalytics';
import { AddAnalytics } from '../../utils/analyticsUtils';

export const analyticsMiddleware = store => next => action => {
  const { analytics } = action;

  if (analytics) {
    if (analytics.$amplitude) {
      const { eventAction, eventLabel } = analytics.$amplitude;

      Add_Amplitude_Event_Analytics(eventAction, eventLabel);
    }

    if (analytics.$ga) {
      const { eventAction, eventLabel } = analytics.$ga;

      AddAnalytics(eventAction, eventLabel);
    }
  }

  return next(action);
};
