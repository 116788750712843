import { useSelector } from 'react-redux';

/**
 * Custom React hook to check user permissions based on app_permissions from Redux store.
 *
 * @returns {function} hasPermission - A function that takes permission and slug as arguments
 * and returns a boolean indicating whether the user has the specified permission.
 */

const usePermission = () => {
  const userDetails = useSelector(state => state.userDetail);
  const { app_permissions = {} } = userDetails;
  const hasPermission = (permission, slug) => {
    if (!slug || !(slug in app_permissions)) {
      return false;
    }
    if (!permission?.length) {
      return app_permissions[slug].length > 0;
    }
    return permission.some(perm => app_permissions[slug]?.includes(perm));
  };

  return hasPermission;
};

export default usePermission;
