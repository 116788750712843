import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Header, { determineRouteHeader } from './Header';
import classes from './Layout.module.scss';
import { useLocation } from 'react-router-dom';
import RenderConditionally from '../RenderConditionally';
import { RouteList } from '../../constants/Routes';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import SpinnerWrapper from '../common/SpinnerWrapper';
import { token } from '../../api/apiUtils';

const Layout = ({ children }) => {
  const location = useLocation();
  const userDetails = useSelector(state => state.userDetail);
  const [headerSidebarIncluded, setHeaderSidebarIncluded] = useState(true);

  const tokenV2 = token();

  useEffect(() => {
    setHeaderSidebarIncluded(
      determineRouteHeader(location.pathname, RouteList)
    );
  }, [location]);

  if (
    tokenV2 &&
    Object.keys(userDetails).length <= 0 &&
    location.pathname == '/' &&
    !location.search
  )
    return <SpinnerWrapper />;

  return (
    <motion.div className={clsx(classes.layout)}>
      {/* INCLUDE SIDEBAR AND HEADER TO THE LAYOUT */}
      <RenderConditionally condition={headerSidebarIncluded}>
        <motion.div className={clsx(classes.sidebar)}>
          <Sidebar />
        </motion.div>
      </RenderConditionally>

      <motion.div
        className={clsx(classes.layoutBodyContainer)}
        data-header-enabled={Boolean(headerSidebarIncluded)}
      >
        <RenderConditionally condition={headerSidebarIncluded}>
          <motion.div>
            <Header />
          </motion.div>
        </RenderConditionally>

        <RenderConditionally condition={children}>
          <motion.div className={classes.childrenEle}>{children}</motion.div>
        </RenderConditionally>
      </motion.div>
    </motion.div>
  );
};

export default Layout;
