import parse from 'date-fns/parseISO';
import format from 'date-fns/format';
import { toast } from 'react-toastify';
import flatten from 'flat';
import { toDate, differenceInDays } from 'date-fns';
import config from '../config/config';
import { Route } from 'react-router-dom';
import { BASIC_PLAN } from '../constants/User';
import { SPECTRO_CONSTS } from '../constants/Spectrometer';
import { TODAY, YESTERDAY } from '../constants/MeltingSection';
import { getPageMetaData } from '../constants';

export function is8601_to_readable(iso8601_string) {
  if (iso8601_string) {
    return format(parse(iso8601_string), ['MMM dd, yyyy hh:mm a']);
  } else {
    return null;
  }
}

export function is8601_to_readable_date(iso8601_string) {
  if (iso8601_string) {
    return format(parse(iso8601_string), ['MMM dd, yyyy']);
  } else {
    return null;
  }
}

export function is8601_to_readable_time(iso8601_string) {
  if (iso8601_string) {
    return format(parse(iso8601_string), ['hh:mm a']);
  } else {
    return null;
  }
}

export function is8601_to_readable_datetime(iso8601_string) {
  if (iso8601_string) {
    let Date_Time = [];
    Date_Time[0] = format(parse(iso8601_string), ['MMM dd, yyyy']);
    Date_Time[1] = format(parse(iso8601_string), ['hh:mm:ss a']);
    return Date_Time;
  } else {
    return null;
  }
}

export function calculateDifferenceInDays(startDate, endDate) {
  return differenceInDays(
    new Date(
      startDate
        .toLocaleString('en-us', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2') // Format to YYYY-MM-DD
    ),
    new Date(
      endDate
        .toLocaleString('en-us', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        })
        .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2') // Format to YYYY-MM-DD
    )
  );
}

export function dateDestructure(date) {
  const newDate = new Date(date);
  return {
    date: newDate,
    shortMonthName: newDate
      .toLocaleString('en-IN', { month: 'short' })
      .slice(0, 3),
    longMonthName: newDate.toLocaleString('en-IN', { month: 'long' }),
    dateNumber: newDate.getDate(),
    shortDayName: newDate
      .toLocaleString('en-IN', { weekday: 'short' })
      .slice(0, 3),
    longDayName: newDate.toLocaleString('en-IN', { weekday: 'long' }),
    fullYear: newDate.getFullYear(),
    local12HourTime: newDate.toLocaleTimeString('en-IN', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    }),
    shortedDate: newDate.toLocaleDateString()
  };
}

export function dateFormat(date) {
  let string = '';
  const differenceInDays = calculateDifferenceInDays(
    new Date(),
    new Date(date)
  );
  if (differenceInDays === 0) {
    string = TODAY;
  }
  if (differenceInDays === 1) {
    string = YESTERDAY;
  }

  return string
    ? string + ' at ' + dateDestructure(date).local12HourTime
    : is8601_to_readable(date);
}

/*
Expectation: 
* APIs or whatevers will throw a javascript Error object.
* Error.message will contain the string (which is actually a json)
that contains the standard django error formatted json.
* we jsonify that string and display some error toasts
* the standard django error obj is:
{
  <fieldName> : [<array of string messages>]
}
*/
export function errorToaster(error) {
  if (typeof error !== 'string') {
    const errorObj = JSON.parse(error);

    // a django error object will have deep nesting depending on the request
    // lets flatten and display all the error messages
    const flatshiz = flatten(errorObj);

    console.log(
      "Hey developer, here's a more detailed error for you: ",
      flatshiz
    );

    for (const key in flatshiz) {
      toast.error(flatshiz[key]);
    }
  } else {
    toast.error(error);
  }
}

export function isAuthenticated() {
  return localStorage.getItem('tokenv2') &&
    localStorage.getItem('tokenv2') != 'Token '
    ? true
    : false;
}

/**
 *
 * @param link string : link to redirect to
 * @returns string : parsed link to redirect to
 */
function splitString(link) {
  const regex = /\/(.*)\//;
  const match = link.match(regex);
  return match ? match[1] : null;
}

/**
 * return the parent link for the breadcrumb
 *
 * @param headerSidebarRoutes : Array of all the routes in the header sidebar
 * @returns string : parent link for the breadcrumb
 */
export function getParentLink(headerSidebarRoutes) {
  let returnRoute = '';
  headerSidebarRoutes.forEach(header => {
    const pathnameToFind = header.path;
    const pathnameRegex = new RegExp(`^${pathnameToFind}$`);
    if (pathnameRegex.test(window.location.pathname)) {
      if (header.title == 'Melting Furnace') returnRoute = '/cm/chargemix/';
      else {
        window.location.pathname.replace(/\/$/, '');
        returnRoute = `/${splitString(header.path)}`;
      }
    }
  });
  return returnRoute;
}

export function goBack(navigate, location) {
  // if (location?.pathname.includes(['/cm/chargemix/wizard/'])) {
  //   return navigate('/cm/chargemix/');
  // }

  window.history.length > 2 ? navigate(-1) : navigate('/');
}

export function formatDate(date, dateformat) {
  return format(toDate(date), dateformat);
}

export function makeNumRound(num) {
  return Math.round(num);
}

export function makedecimalupto2digit(num, digit = 2) {
  const parsedNum = Number.parseFloat(num);
  return parsedNum % 1 === 0 ? parsedNum.toString() : parsedNum.toFixed(digit);
}

export function random_item(items) {
  var todayDate = formatDate(new Date(), 'dd');

  return items[todayDate % 3];
}

export function random_from_array(array) {
  var index = Math.floor(Math.random() * array.length);
  return array[index];
}

export function notify_slack_person(user) {
  var slack_notifier = `<!channel>`;
  if (user.rm && user.rm.slack_id) {
    slack_notifier = `<@${user.rm.slack_id}>`;
  }
  return slack_notifier;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function createQueryParam(apiParams) {
  const queryParam = [];

  for (const index in apiParams) {
    if (apiParams[index] && apiParams[index].toString().length) {
      queryParam.push(`${index}=${apiParams[index]}`);
    }
  }

  return queryParam.join('&');
}

export function isProduction() {
  return config.ENV === 'production';
}

export async function withDefaultUIHandler(
  fn,
  {
    successMsg = false,
    errorMsg = true,
    propagateError = true,
    onErrorHandler = null
  }
) {
  try {
    const data = await fn();

    if (successMsg) {
      toast.success(successMsg === true ? 'Success...' : successMsg);
    }

    return data;
  } catch (error) {
    if (errorMsg) {
      errorMsg === true
        ? errorToaster(
            error.message ?? 'Something went wrong. Please try again.'
          )
        : errorToaster(errorMsg);
    }

    if (onErrorHandler) onErrorHandler();

    if (propagateError) {
      throw error;
    }
  }
}

/**
 * Convert number to Rupee formatted String with ₹ Symbol
 * @param  {Number} number Value to be shown in Rupee formatted
 * @param  {Number} fractions Decimal places to be formatted
 * @return {string}   Rupee formatted String e.g - ₹00.00
 */
export function rupeeFormat(number, fractions = 2) {
  if (!number) {
    // Empty string found
    return '₹00.00';
  }

  return new Intl.NumberFormat('en-IN', {
    maximumFractionDigits: fractions,
    style: 'currency',
    currency: 'INR'
  }).format(number);
}

/**
 * suggestedProductName description- function returns a string literal which hold the suggested item name and title(tooltip)
 * @param  {string,string} item_name title - [SuggestedproductName ,its tooltip text(title)
 * @return {string}     string literal holding html code with suggested product and tooltip
 */

export function suggestedProductName(item_name, title) {
  return `<div><div class="bg">${item_name}
  <div class="infoContainer"><span class="info" title="${title}">i</span>
  </div>
</div></div>`;
}

/**
 * Generates an array of React Router Route components based on provided route elements,
 * permissions, and Slug values.
 *
 * @param {Object[]} routeElements - An array of route element objects containing path, component, and permission information.
 * @param {function} hasPermission - A function that checks if the user has the required permission.
 * @param {string} Slug - A route slug for identification.
 * @returns {JSX.Element[]} - An array of React Router Route components.
 */

export const routeElements = (routeElements, hasPermission, Slug) => {
  return routeElements
    .filter(({ permission }) => hasPermission(permission, Slug))
    .map(({ path, component: Component, exact }) => {
      return (
        <Route key={path} exact={exact} path={path} element={<Component />} />
      );
    });
};

/**
 * Checks if the header should be visible based on the presence of allowed routes and user permissions.
 *
 * @param {Object[]} slugList - An array of Slug representing different header sub-section in the application.
 * @returns {boolean} - True if there are allowed routes with valid permissions and icons; otherwise, false.
 */
export const isModuleGroupedHeaderVisible = (slugList, userDetail) => {
  return slugList.some(slug => {
    if (Object.keys(userDetail).length > 0) {
      const { app_permissions = {} } = userDetail;
      const shouldDisplay =
        Object.keys(app_permissions).length > 0
          ? Object.keys(app_permissions).includes(slug)
          : true;
      return shouldDisplay;
    }
  });
};

/**
 * Formats a range string based on provided minimum and maximum values.
 *
 * @param {object} d - The data object containing min and max values.
 * @param {number} d.min - The minimum value.
 * @param {number} d.max - The maximum value.
 * @returns {string} The formatted range string.
 */
export const getRangeString = d => {
  if (d.min && d.max) {
    return `${parseFloat(d.min).toFixed(2)} - ${parseFloat(d.max).toFixed(2)}`;
  }
  if (!d.min && d.max) return `max ${parseFloat(d.max).toFixed(2)}`;
  if (!d.max && d.min) return `min ${parseFloat(d.min).toFixed(2)}`;
  return '-';
};
/**
 * Checks if a feature requires a paid plan based on the user.
 *
 * @param {object} userDetail - User Detail
 * @param {boolean} paid_feature - Indicates if the feature requires a paid plan.
 * @returns {boolean} - Returns true if the feature requires a paid plan for the user's current plan.
 */
export const hasPaidModuleAccess = (userDetail, paid_feature) =>
  userDetail.plan === BASIC_PLAN && paid_feature;

/**
 * @summary returns random color of background as per the user name
 * @param {String} string - Input user name as string
 * @return {color} return background color.
 */
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    // COLOR STRING IS CREATED HERE
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

/**
 * @summary return the intial of user name .
 * @param {String} name - name of user whoes initals is needed
 * @return {String} returns Intials of userName
 */
export function stringAvatar(name) {
  let initals = '';
  name.split(' ').forEach(nameBreak => {
    initals += nameBreak[0];
  });

  return {
    sx: {
      bgcolor: stringToColor(name)
    },
    children: `${initals}`
  };
}

/**
 * Determines if the provided path matches the current location.
 *
 * @param {string} base - base path.
 * @param {string} path - Path to be matched.
 * @param {string} locationPathname - Current location's pathname.
 * @returns {boolean} - True if matches, false otherwise.
 */
export const pathMatch = (base, path, locationPathname) => {
  const pathRegex = new RegExp(`^${base}${path}$`);
  return pathRegex.test(locationPathname);
};

export const handleDateChange = (type, value, data) => {
  const { START_DATE, END_DATE } = SPECTRO_CONSTS;
  const startDate = type == START_DATE ? value : data.heat_date__gte;
  const endDate = type == END_DATE ? value : data.heat_date__lte;

  if (type == START_DATE) {
    if (endDate && endDate < value) {
      // If end date is set and before start date, reset end date
      return { heat_date__gte: value, heat_date__lte: '' };
    }
    return { heat_date__gte: value };
  } else {
    if (startDate && startDate > value) {
      // If start date is set and after end date, reset start date
      return { heat_date__gte: '', heat_date__lte: value };
    }
    return { heat_date__lte: value };
  }
};

/**
 * Retrieves the analytics key and title based on the current path and query parameters.
 *
 * This function matches the provided path against a set of predefined patterns
 * to determine the appropriate analytics key and page title. If no pattern matches,
 * default values are returned.
 *
 * @param {string} path - The current pathname (e.g., "/inventory/123").
 * @param {object} query - The query parameters (e.g., { duplicate: true }).
 * @returns {object} - An object containing the `analyticsKey` and `analyticsTitle`.
 *  - `analyticsKey` {string}: The analytics event key (e.g., "RMINDP").
 *  - `analyticsTitle` {string}: The title to be set for the document (e.g., "Inventory Details").
 *
 * @example
 * const { analyticsKey, analyticsTitle } = getPageTitleAndAnalytics("/inventory/123", {});
 * console.log(analyticsKey); // Outputs: "RMINDP"
 * console.log(analyticsTitle); // Outputs: "Inventory Details"
 */
export const getPageTitleAndAnalytics = (path, query) => {
  let analyticsKey = 'NP';
  let analyticsTitle = 'NowPurchase Foundry';
  const pageEvents = getPageMetaData(query);
  Object.keys(pageEvents).forEach(pattern => {
    if (new RegExp(pattern).test(path)) {
      analyticsKey = pageEvents[pattern].event;
      analyticsTitle = pageEvents[pattern].title;
    }
  });
  return {
    analyticsKey,
    analyticsTitle
  };
};
