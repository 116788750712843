export const uploadFileAmplitudeEvents = {
  eventName: 'Upload File',
  eventAction: 'CM_FILE_UPLOAD',
  eventLabel: 'Chargemix File Uploaded successfully'
};

export const uploadFileGaEvents = {
  ...uploadFileAmplitudeEvents,
  eventAction: 'CHARGEMIX_UPLOAD_SUCCESS'
};
