import React from 'react';
import classes from './HeaderLayout.module.scss';
import { isMobile } from 'react-device-detect';

const MaterialTestHeader = () => {
  return (
    <div>
      <div className={classes.breadCrumbContainer}>
        <span
          className={classes.heatDetailsHeaderText}
          style={{ fontSize: isMobile ?? '12px' }}
        >
          Material Test
        </span>
      </div>
    </div>
  );
};

export default MaterialTestHeader;
