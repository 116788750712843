import { apiCallError, beginApiCall } from '../apiStatus/apiStatusAction';
import * as gradeApi from '../../api/gradeApi';
import { GRADE_LIST_ACTION_TYPES } from './gradeActionType';

/**
 * Fetches a list of grades from the API and dispatches an action to update the store.
 *
 * @param {object} apiParams - Object containing query parameters to be added to the API endpoint URL.
 * @returns {Function} - A function that dispatches the appropriate actions.
 */
export function getGradeList(apiParams) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return gradeApi
      .fetchGradeList(apiParams)
      .then(gradeList => {
        dispatch(getGradeListSuccess(gradeList));
        return gradeList;
      })
      .catch(error => {
        dispatch(apiCallError());
        throw error;
      });
  };
}
/**
 * thunk to fetch the detail of grade from the API and dispatches an action to update the store.
 *
 * @param {object} id - Object containing query parameters to be added to the API endpoint URL.
 * @returns {Function} - A function that dispatches the appropriate actions.
 */
export function getGradeDetail(id) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return gradeApi
      .fetchGradeDetail(id)
      .then(gradeDetail => {
        dispatch(getGradeDetailSuccess(gradeDetail));
        return gradeDetail;
      })
      .catch(error => {
        dispatch(apiCallError());
        throw error;
      });
  };
}

/**
 * Updates a grade in the API and dispatches an action to update the store.
 *
 * @param {number} id - The ID of the grade to be updated.
 * @param {object} updatedGrade - The updated grade information to be sent to the API.
 * @returns {Function} - A function that dispatches the appropriate actions.
 */
export function updateGradeList(id, updatedGrade) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return gradeApi
      .updateGradeList(id, updatedGrade)
      .then(responseJson => {
        dispatch(updateGradeListSuccess(responseJson));
        return responseJson;
      })
      .catch(error => {
        dispatch(apiCallError());
        throw error;
      });
  };
}

/**
 * Creates an action object to update the store with the fetched list of grades.
 *
 * @param {Array} gradeList - An array of grade objects returned from the API.
 * @returns {object} - An action object with the grade list as payload.
 */
export function getGradeListSuccess(gradeList) {
  return {
    type: GRADE_LIST_ACTION_TYPES.GET_GRADE_LIST_SUCCESS,
    gradeList
  };
}

/**
 * Creates an action object to store with the fetched detail of grade.
 *
 * @param {Array} gradeDetail - An array of grade objects returned from the API.
 * @returns {object} - An action object with the grade list as payload.
 */
export function getGradeDetailSuccess(gradeDetail) {
  return {
    type: GRADE_LIST_ACTION_TYPES.GET_GRADE_DETAIL_SUCCESS,
    gradeDetail
  };
}

/**
 * Creates an action object to update the store with the updated grade object.
 *
 * @param {object} updatedGrade - The updated grade object returned from the API.
 * @returns {object} - An action object with the updated grade as payload.
 */
export function updateGradeListSuccess(updatedGrade) {
  return {
    type: GRADE_LIST_ACTION_TYPES.UPDATE_GRADE_LIST_SUCCESS,
    updatedGrade
  };
}
