import React from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { goBack, pathMatch } from '../../../utils';
import classes from './HeaderLayout.module.scss';
import { Breadcrumb } from './GradeHeader';
import { RightArrowIcon } from '../../../assets/icons/modifiedIcons';

/**
 * Component to display details of a charge mix.
 * It shows the charge mix tag ID, a 'Trial' label if applicable, and the charge mix name.
 *
 * @param {object} props - Component props.
 * @param {object} props.chargeMix - The charge mix data from the Redux state.
 * @returns {JSX.Element} A div element representing the detailed display of the charge mix.
 */
const ChargeMixDetailDisplay = ({ chargeMix }) => {
  return (
    <div className={classes.titleContainer}>
      <span>
        <RightArrowIcon fontSize={16} />
      </span>
      <span style={{ fontSize: '16' }}>{chargeMix?.cm_tag_id}</span>
      {chargeMix?.trial_item_id && (
        <div className={classes.trialBox}>Trial</div>
      )}
      <span style={{ fontWeight: 'bold !important' }}>
        {' '}
        - {chargeMix?.cm_name}
      </span>
    </div>
  );
};

/**
 * Header component for the Melting Furnace section in an application.
 * It displays a breadcrumb navigation and detailed charge mix information when viewing a specific charge mix.
 *
 * @param {object} props - Component props.
 * @param {object} props.chargeMix - The charge mix data from the Redux state.
 * @returns {JSX.Element} The header component for the Melting Furnace section with dynamic content based on the current route.
 */
const MeltingFurnaceHeader = ({ chargeMix }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const isMeltingFurnaceDetail = pathMatch(
    '/cm/chargemix/wizard',
    '/\\d+',
    location.pathname
  );

  if (!isMeltingFurnaceDetail)
    return <span className={classes.title}>Melting Furnace </span>;

  return (
    <div>
      <div className={classes.breadCrumbContainer}>
        <>
          <ArrowBackIcon
            className={classes.backActionIcon}
            onClick={() => goBack(navigate, location)}
          />
          <Breadcrumb
            navigate={navigate}
            text='Melting Furnace'
            path='/cm/chargemix'
          />
        </>
        <ChargeMixDetailDisplay chargeMix={chargeMix} />
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  chargeMix: state.chargeMix.read.data
});

export default connect(mapStateToProps, null)(MeltingFurnaceHeader);
