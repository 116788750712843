// Layout

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

// Order Details
export const LOAD_ORDER_DETAILS = 'LOAD_ORDER_DETAILS';

// Order
export const LOAD_ORDER = 'LOAD_ORDER';

// Upload File
export const UPLOAD_FILE = 'UPLOAD_FILE';

// Admin View
export const TOGGLE_ADMIN_VIEW = 'TOGGLE_ADMIN_VIEW';

// Address
// export const LOAD_ADDRESS = 'LOAD_ADDRESS';
export const LOAD_ADDRESS_BY_ID = 'LOAD_ADDRESS_BY_ID';

// RFQ
export const PLACE_RFQ = 'PLACE_RFQ';

// ChargeMix
export const FETCH_CHARGEMIX = 'FETCH_CHARGEMIX';
export const FETCH_ALL_CHARGEMIX = 'FETCH_ALL_CHARGEMIX';
export const FETCH_CHARGEMIX_ELEMENTS = 'FETCH_CHARGEMIX_ELEMENTS';
export const ADD_CHARGEMIX = 'ADD_CHARGEMIX';
export const UPDATE_CHARGEMIX = 'UPDATE_CHARGEMIX';
export const CLONE_CHARGEMIX = 'CLONE_CHARGEMIX';
export const GET_INVENTORY_ITEM_SUGGESTION = 'GET_INVENTORY_ITEM_SUGGESTION';
export const DEMO_MODE = 'DEMO_MODE';
export const DRAFT_CHARGEMIX_CHANGES = 'DRAFT_CHARGEMIX_CHANGES';
export const CHARGEMIX_OVERVIEW = 'CHARGEMIX_OVERVIEW';
export const FETCH_CHARGEMIX_LIST = 'FETCH_CHARGEMIX_LIST';

// Chargemix Suggestions
export const FETCH_CHARGEMIX_SUGGESTIONS = 'FETCH_CHARGEMIX_SUGGESTIONS';
export const VIEW_TRAIL_CHARGEMIX = 'VIEW_TRAIL_CHARGEMIX';
export const REQUEST_TRAIL_SAMPLE = 'REQUEST_TRAIL_SAMPLE';

// Slack Notification
export const SLACK_NOTIFICATION = 'SLACK_NOTIFICATION';
export const SLACK_NOTIFICATION_FROM_SIDEBAR =
  'SLACK_NOTIFICATION_FROM_SIDEBAR';
export const FETCH_PI_LIVE_DATA = 'FETCH_PI_LIVE_DATA';
export const FETCH_PI_SHIFT_DATA = 'FETCH_PI_SHIFT_DATA';
export const FETCH_FURNACE = 'FETCH_FURNACE';
