import { suggestedProductName } from '..';
import { cFetch, token } from '../../api/apiUtils';
import { formatRange } from '../../componentsV2/ChargeMix/handsontable';
import config from '../../config/config';
import {
  CHARGEMIX_COMMON_CONSTS,
  GET_CM_RR
} from '../../constants/ChargeMix/common';
import { FINALANALYSIS_CONSTS } from '../../constants/ChargeMix/FinalAnalysis';
import { COMMON_ACTION_TYPES } from '../../redux/commonActionTypes';

import {
  FURNACE_CUPOLA,
  getChemistry,
  qtyLable,
  toFixedValue
} from '../ChargeMix';

const { CM_ID_INDEX, OPTIMIZEDQTY } = FINALANALYSIS_CONSTS;
const {
  PRODUCT,
  RATE,
  QTY,
  KG,
  RUPEE,
  STOCK,
  MIN,
  MAX,
  METAL_RR,
  YIELD,
  TO_FIXED_CONST,
  SUGGESTED_ITEM_TITLE
} = CHARGEMIX_COMMON_CONSTS;

const { ADDITIVES, FURNACE, NODULARIZER, LADLE } = COMMON_ACTION_TYPES;

/**
 * split range to min and max
 *
 * @param {string} element element
 * @param {Array} target_chemistry target chemistry
 * @returns {Object} split range to min abd max
 */
export function splitRangeIntoMinMax(element, target_chemistry) {
  const get_tc = target_chemistry
    .filter(ele => ele.element__symbol == element)
    .map(ele => {
      const ele_range = ele.ele_range.split('-');
      let min = ele_range[0];
      let max = ele_range[1];
      if (!min) min = 0;
      if (!max) max = 'inf';
      return { min, max };
    });
  return (get_tc.length && get_tc[0]) || { min: null, max: null };
}

/**
 * Sets the row values in an object based on a specific index and value.
 * @param {Array} indexedHeader - The indexed header array.
 * @param {string} index - The index to match with the elements in the indexedHeader array.
 * @param {*} value - The value to set for the matching index.
 * @returns {Object} - The object with row values set based on the index and value.
 */
export function setRowByNotion(indexedHeader, index, value) {
  return indexedHeader.reduce((fo, ele) => {
    fo[ele] = ele == index ? value : null;
    return fo;
  }, {});
  // return indexedHeader.map((ele) => (ele == index ? value : ""));
}

/**
 * filter chargemix items
 *
 * @param {Array} cm_items chargemix items
 * @param {string} elements elements to filter
 * @param {string} type type
 * @param {string} elementSymbolById element
 * @param {number} cm_rr chargemix recovery rate
 * @returns {Object} filtered
 */
export function filterCmItems(
  cm_items,
  elements,
  type,
  elementSymbolById,
  cm_rr
) {
  return cm_items
    .filter(ele => ele.cm_type == type)
    .map(ele => {
      let initalData = {};
      if (ele.is_new_item) {
        initalData = {
          [CM_ID_INDEX]: ele.id,
          [PRODUCT]: suggestedProductName(ele.item_name, SUGGESTED_ITEM_TITLE),
          is_new_item: ele.is_new_item,
          cm_type: ele.cm_type,
          [KG]: toFixedValue(ele.qty, 3),
          [STOCK]: toFixedValue(ele.stock, 3),
          [OPTIMIZEDQTY]: toFixedValue(ele.optimized_qty, 3),
          [RUPEE]: toFixedValue(ele.price, 2),
          [YIELD]: toFixedValue(ele.metal_rr, 2)
        };
      } else {
        initalData = {
          [CM_ID_INDEX]: ele.id,
          [PRODUCT]: ele.item_name,
          [KG]: toFixedValue(ele.qty, 3),
          [STOCK]: toFixedValue(ele.stock, 3),
          [OPTIMIZEDQTY]: toFixedValue(ele.optimized_qty, 3),
          [RUPEE]: toFixedValue(ele.price, 2),
          [YIELD]: toFixedValue(ele.metal_rr, 2),
          is_new_item: ele.is_new_item,
          cm_type: ele.cm_type
        };
      }

      elements.forEach(element => {
        let ele_recovery = ele[cm_rr].filter(
          rr_ele => element == rr_ele.element && rr_ele.rate
        );

        initalData = {
          ...initalData,
          [elementSymbolById[element]]:
            ele_recovery.length > 0 ? toFixedValue(ele_recovery[0].rate) : null
        };
      });
      initalData = {
        ...initalData,
        [MAX]:
          ele.max_qty || parseInt(ele.max_qty) == 0
            ? toFixedValue(ele.max_qty, 4)
            : null,
        [MIN]: ele.min_qty ? toFixedValue(ele.min_qty, 4) : null
      };

      return initalData;
    });
}

/**
 * Creates an object mapping element IDs to their symbols.
 * @param {Array} elements - The array of elements containing ID and symbol properties.
 * @returns {Object} - The object mapping element IDs to their symbols.
 */
export const get_element_sym_by_id = elements =>
  elements.reduce((obj, item) => ({ ...obj, [item.id]: item.symbol }), {});

/**
 * Sets the cell metadata for the chemistry values based on the target chemistry range.
 * @param {Array} indexedHeader - The indexed header array.
 * @param {number} rowIndex - The index of the row.
 * @param {Object} predicted_chemistry - The predicted chemistry object.
 * @param {Array} target_chemistry - The target chemistry array.
 * @returns {Array} - The array of cell metadata objects.
 */
export const setMetaForChemistry = (
  indexedHeader,
  rowIndex,
  predicted_chemistry,
  target_chemistry
) => {
  let cell_meta = [];
  indexedHeader?.map((ele, index) => {
    const tc = target_chemistry.find(tc => tc.element__symbol == ele);
    if (
      tc &&
      isFinite(predicted_chemistry[ele]) &&
      !isNaN(predicted_chemistry[ele])
    ) {
      const splitRange = tc.ele_range.split('-');
      const min = Number(splitRange[0] || 0);
      const max = Number(splitRange[1] || 100);
      let className = '';
      if (predicted_chemistry[ele] >= min && predicted_chemistry[ele] <= max)
        className = 'valid';
      else className = 'not-valid';
      cell_meta.push({
        rowNum: rowIndex,
        colNum: index,
        className: className
      });
    }
  });
  return cell_meta;
};

/**
 * Calculates the predicted chemistry based on the charge mix data.
 * @param {Object} chargeMixData - The charge mix data object.
 * @param {Function} get_element_sym_by_id - A function to retrieve the element symbol by its ID.
 * @param {string} qty_index - The index used for quantity calculations.
 * @param {Array} filter_cm_type - An optional array of charge mix types to filter.
 * @returns {Object} - The predicted chemistry object.
 */
export const calculateChemistry = (
  { cm_items, cm_target_chemistry, is_composition, config, ...chargemix_data },
  get_element_sym_by_id,
  qty_index,
  filter_cm_type = []
) => {
  let items = cm_items;
  const conc_index = is_composition
    ? 'cm_item_composition'
    : 'cm_recovery_rate';
  let predicted_chemistry_sum = {
    [FURNACE]: {},
    [LADLE]: {},
    [ADDITIVES]: {},
    [NODULARIZER]: {}
  };
  let cm_type_qty_sum = {
    [FURNACE]: 0,
    [LADLE]: 0,
    [ADDITIVES]: 0,
    [NODULARIZER]: 0
  };

  if (filter_cm_type.length)
    items = items?.filter(item => filter_cm_type.indexOf(item.cm_type) > -1);
  items?.forEach(item => {
    const qty = item[qty_index];
    if (!item.metal_rr || parseFloat(item.metal_rr) == 0) item.metal_rr = 100;

    let metal_rr = 1;

    if (
      item.cm_type != FURNACE &&
      item.metal_rr &&
      parseFloat(item.metal_rr) > 0
    )
      metal_rr = parseFloat(item.metal_rr) / 100;

    cm_type_qty_sum = {
      ...cm_type_qty_sum,
      [item.cm_type]: cm_type_qty_sum[item.cm_type] + qty * metal_rr
    };
    item[conc_index].forEach(el_cm => {
      const element_symbol =
        el_cm.element_symbol || get_element_sym_by_id[el_cm.element];
      if (element_symbol && el_cm.rate && qty) {
        if (
          chargemix_data.has_nodularization &&
          chargemix_data.mg_in_fesimg &&
          item.cm_type == NODULARIZER &&
          element_symbol == 'Mg'
        )
          metal_rr = parseFloat(chargemix_data.mg_in_fesimg) / 100;
        let ele_sum =
          (predicted_chemistry_sum[item.cm_type][element_symbol] || 0) +
          qty * parseFloat(el_cm.rate) * metal_rr;
        predicted_chemistry_sum = {
          ...predicted_chemistry_sum,
          [item.cm_type]: {
            ...predicted_chemistry_sum[item.cm_type],
            [element_symbol]: ele_sum
          }
        };
      }
    });
  });
  let predicted_chemistry = cm_target_chemistry?.reduce(
    (obj, item) => ({ ...obj, [item.element__symbol]: 0 }),
    {}
  );

  Object.keys(predicted_chemistry_sum).forEach(cm_type => {
    Object.keys(predicted_chemistry_sum[cm_type]).forEach(ele => {
      let cal_cm = predicted_chemistry[ele] || 0;
      const tc = cm_target_chemistry.find(tc => tc.element__symbol == ele);
      let losses = 1;

      if (tc?.losses && [FURNACE, ADDITIVES].indexOf(cm_type) > -1)
        losses = 1 - parseFloat(tc.losses) / 100;
      const ele_sum =
        (predicted_chemistry_sum[cm_type][ele] * losses) /
        (chargemix_data.has_sponge_iron && chargemix_data.liquid_metal_weight
          ? chargemix_data.liquid_metal_weight
          : chargemix_data.furnace_size);
      predicted_chemistry = { ...predicted_chemistry, [ele]: cal_cm + ele_sum };
    });
  });
  if (qty_index == 'optimized_qty') {
    if (chargemix_data['mg_final'])
      predicted_chemistry['Mg'] = chargemix_data['mg_final'];
    if (chargemix_data['s_final'])
      predicted_chemistry['S'] = chargemix_data['s_final'];
    if (predicted_chemistry?.C && config.carbon_loss)
      predicted_chemistry.C =
        predicted_chemistry?.C * (1 - config.carbon_loss / 100);
  }
  Object.keys(predicted_chemistry).forEach(ele => {
    const tc = cm_target_chemistry.find(tc => tc.element__symbol == ele);
    // let losses = 0;
    // if (tc.losses) losses = parseFloat(tc.losses);
    // predicted_chemistry[ele] = predicted_chemistry[ele] * (1 - losses / 100);
    predicted_chemistry[ele] =
      Math.round(predicted_chemistry[ele] * 1000, 3) / 1000;
  });

  return predicted_chemistry;
};

/**
 *
 * Calculates final_chemistry Sulphur amount
 *
 * @constant {Number} {sulpherInitial} = Gives the value of sulphur for sumation(FURNACE + ADDITIVES)
 * @constant {Number} {t2} = (feSiMgQty * mgInFeSiMg * mgRR) / ((Math.pow(temperature / 1450, 2) * furnace_size))
 * @constant {Number} {mgFinal} = min value of Mg in target chemistry
 * @constant {Number} {t4} = 0.001 * pouring_time
 *
 * @param {Object} chargeMix
 *
 * @returns {Number} = sulphurInitial - (t2 - mgFinal - t4) / 0.76 or 0.000 incase of sulphur
 *
 */
function calcSulphurAmount(chargeMix, heelQty) {
  // function for calculating qty of Sulpher in ChargeMix
  try {
    const { cm_rr } = GET_CM_RR(chargeMix.is_composition);

    const elementIdBySymbol = chargeMix.cm_target_chemistry.reduce(
      (eleId, ele) => ({ ...eleId, [ele.element__symbol]: ele.element }),
      {}
    );

    const feSiMg = chargeMix.cm_items.find(ele => ele.cm_type === NODULARIZER);

    const feSiMgQty = Number(feSiMg.qty);
    const mgInFeSiMg = Number(
      feSiMg[cm_rr].find(ele => ele.element === elementIdBySymbol['Mg']).rate
    );

    const mgRR = Number(chargeMix.mg_in_fesimg / 100 || 1);

    const sulphurInitial =
      chargeMix.cm_items
        .filter(d => d.cm_type === FURNACE || d.cm_type === ADDITIVES)
        ?.reduce((acc, currVal) => {
          const sulphur = currVal[cm_rr].find(
            ele => ele.element === elementIdBySymbol['S']
          );

          if (sulphur) {
            acc +=
              getRecoveryRate(
                chargeMix.is_composition,
                sulphur.rate,
                currVal.metal_rr
              ) * Number(currVal.qty);
          }

          return acc;
        }, 0) / chargeMix.furnace_size;

    const t2 =
      (feSiMgQty * mgInFeSiMg * mgRR) /
      (Math.pow(chargeMix.temperature / 1450, 2) *
        (chargeMix.furnace_size - heelQty));

    const mgFinal = Number(
      splitRangeIntoMinMax('Mg', chargeMix.cm_target_chemistry).min
    );

    const t4 = 0.001 * Number(chargeMix.pouring_time);

    const sulphur = sulphurInitial - (t2 - mgFinal - t4) / 0.76;

    if (sulphur > 0) {
      return sulphur;
    }

    return Number(0);
  } catch (err) {
    toast.error('Mg or S value missing.');
    console.error(err);
    const sMax = Number(
      splitRangeIntoMinMax('S', chargeMix.cm_target_chemistry)?.max
    );
    return sMax;
  }
}

/**
 * Generates the fixed header for the charge mix data.
 * @param {Object} chargeMixData - The charge mix data object.
 * @returns {Array} - The fixed header array.
 */
export const getFixedHeader = chargeMixData => {
  // Define logic for creating fixedHeader here...
  const fixedHeader = [
    [CM_ID_INDEX],
    [PRODUCT],
    {
      label: QTY,
      colspan: 1
    },
    {
      label: OPTIMIZEDQTY,
      colspan: 1
    },
    ...(chargeMixData.is_stock ? [[STOCK]] : []),
    {
      label: RATE,
      colspan: 1
    },
    ...(chargeMixData.is_composition
      ? [
          {
            label: METAL_RR,
            colspan: 1
          }
        ]
      : [])
  ];
  return fixedHeader;
};

/**
 * Generates the new CSV header based on the fixedHeader, elementsHeader, and cm_rr_head.
 * @param {Array} fixedHeader - The array of fixed headers.
 * @param {Array} elementsHeader - The array of elements headers.
 * @param {string} cm_rr_head - The header for cm_rr.
 * @returns {Array} - The new CSV header array.
 */
export const getNewCsvHeader = (fixedHeader, elementsHeader, cm_rr_head) => {
  // Define logic for creating newCsvHeader here...
  const newCsvHeader = [
    [
      ...fixedHeader,
      {
        label: `${cm_rr_head} (%)`,
        colspan: elementsHeader.length
      },
      {
        label: qtyLable(),
        colspan: 2
        // className: 'qty'
      }
    ],
    [
      ...fixedHeader.map(head => {
        if (head.label === QTY) {
          return KG;
        }
        if (head.label === RATE) {
          return RUPEE;
        }
        if (head.label === OPTIMIZEDQTY) {
          return KG;
        }
        if (head.label === METAL_RR) {
          return YIELD;
        }
        return '';
      }),
      ...elementsHeader,
      [MIN],
      [MAX]
    ]
  ];
  return newCsvHeader;
};

/**
 * Generates the indexed headers by combining the fixed headers and the elements headers.
 * @param {Array} fixedHeader - The array of fixed headers.
 * @param {Array} elementsHeader - The array of elements headers.
 * @returns {Array} - The indexed headers array.
 */
export const getIndexedHeaders = (fixedHeader, elementsHeader) => {
  return [
    ...fixedHeader.map(head => {
      if (head.label == QTY) {
        return KG;
      }
      if (head.label == RATE) {
        return RUPEE;
      }
      if (head.label == OPTIMIZEDQTY) {
        return OPTIMIZEDQTY;
      }
      if (head.label === METAL_RR) {
        return YIELD;
      }
      return head;
    }),
    ...elementsHeader,
    [MIN],
    [MAX]
  ];
};

/**
 * Retrieves the furnace data based on the provided charge mix data and other parameters.
 * @param {Object} chargeMixData - The charge mix data.
 * @param {Array} newIndexedHeader - The array of new indexed headers.
 * @param {Object} elementsById - The elements data indexed by ID.
 * @param {Object} elementSymbolById - The element symbols data indexed by ID.
 * @param {Object} cm_rr - The CM reaction rate data.
 * @param {number} furnace_size - The size of the furnace.
 * @returns {Object} - An object containing the furnace data (rowData and cellMeta).
 */
export const getFurnaceData = (
  chargeMixData,
  newIndexedHeader,
  elementsById,
  elementSymbolById,
  cm_rr,
  furnace_size
) => {
  // Define logic for processing Furnace data here...
  let rowData = [];
  let cellMeta = [];

  let rowHeader = setRowByNotion(
    newIndexedHeader,
    'Product',
    '<div class="hand_item_name_header">Furnace</div>'
  );
  rowData = [...rowData, rowHeader];
  cellMeta.push({
    rowNum: rowData.length + 5,
    colNum: 1,
    className: 'header'
  });

  let furnaceData = filterCmItems(
    chargeMixData.cm_items,
    elementsById,
    'FURNACE',
    elementSymbolById,
    cm_rr,
    furnace_size
  );

  rowData = [...rowData, ...furnaceData];

  return { rowData, cellMeta };
};

/**
 * Retrieves the additives data based on the provided charge mix data and other parameters.
 * @param {Object} chargeMixData - The charge mix data.
 * @param {Array} newIndexedHeader - The array of new indexed headers.
 * @param {Object} elementsById - The elements data indexed by ID.
 * @param {Object} elementSymbolById - The element symbols data indexed by ID.
 * @param {Object} cm_rr - The CM reaction rate data.
 * @param {number} furnace_size - The size of the furnace.
 * @returns {Object} - An object containing the additives data (rowData and cellMeta).
 */
export const getAdditivesData = (
  chargeMixData,
  newIndexedHeader,
  elementsById,
  elementSymbolById,
  cm_rr,
  furnace_size
) => {
  let rowData = [];
  let cellMeta = [];

  let rowHeader = setRowByNotion(
    newIndexedHeader,
    'Product',
    '<div class="hand_item_name_header">Additives</div>'
  );
  rowData = [...rowData, rowHeader];

  let additivesData = filterCmItems(
    chargeMixData.cm_items,
    elementsById,
    'ADDITIVES',
    elementSymbolById,
    cm_rr,
    furnace_size
  );
  rowData = [...rowData, ...additivesData];

  return { rowData, cellMeta };
};

/**
 * Retrieves the bath chemistry data based on the provided charge mix data and other parameters.
 * @param {Array} rowData - The current rowData array.
 * @param {Array} cellMeta - The current cellMeta array.
 * @param {Object} chargeMixData - The charge mix data.
 * @param {Array} newIndexedHeader - The array of new indexed headers.
 * @param {Object} elementSymbolById - The element symbols data indexed by ID.
 * @returns {Object} - An object containing the bath chemistry data (rowData and cellMeta).
 */
export const getBathChemistryData = ({
  rowData,
  cellMeta,
  chargeMixData,
  newIndexedHeader,
  elementSymbolById
}) => {
  const bath_qty_chemistry = chargeMixData.curr_pred_chemistry
    ? chargeMixData.curr_pred_chemistry[FURNACE]
    : calculateChemistry(chargeMixData, elementSymbolById, 'qty', [
        FURNACE,
        ADDITIVES
      ]);

  const bathChemistryRow = {
    ...bath_qty_chemistry,
    Product: '<div class="hand_item_name_header">Bath Chemistry</div>'
  };

  const cell_meta_qty_chemistry = setMetaForChemistry(
    newIndexedHeader,
    rowData.length,
    bath_qty_chemistry,
    chargeMixData.cm_target_chemistry
  );

  cellMeta = [...cellMeta, ...cell_meta_qty_chemistry];
  rowData = [...rowData, bathChemistryRow];

  if (
    chargeMixData.is_optimized &&
    chargeMixData.furnace_type !== FURNACE_CUPOLA
  ) {
    let pred_bath_qty_chemistry = getChemistry({
      predKey: 'b',
      chargeMixData,
      elementSymbolById
    });

    const cell_meta_qty_chemistry = setMetaForChemistry(
      newIndexedHeader,
      rowData.length,
      pred_bath_qty_chemistry,
      chargeMixData.cm_target_chemistry
    );

    cellMeta = [...cellMeta, ...cell_meta_qty_chemistry];

    const predBathChemistryRow = {
      ...pred_bath_qty_chemistry,
      Product:
        '<div class="hand_item_name_header">Suggested Bath Chemistry</div>'
    };

    rowData = [...rowData, predBathChemistryRow];
  }

  return { rowData, cellMeta };
};

/**
 * Retrieves the nodularization data based on the provided charge mix data and other parameters.
 * @param {Array} rowData - The current rowData array.
 * @param {Array} cellMeta - The current cellMeta array.
 * @param {Object} chargeMixData - The charge mix data.
 * @param {Array} newIndexedHeader - The array of new indexed headers.
 * @param {Object} elementsById - The elements data indexed by ID.
 * @param {Object} elementSymbolById - The element symbols data indexed by ID.
 * @param {Object} cm_rr - The charge mix recovery rates.
 * @param {number} furnace_size - The size of the furnace.
 * @returns {Object} - An object containing the nodularization data (rowData and cellMeta).
 */
export const getNodularizationData = (
  rowData,
  cellMeta,
  chargeMixData,
  newIndexedHeader,
  elementsById,
  elementSymbolById,
  cm_rr,
  furnace_size
) => {
  let rowHeader = setRowByNotion(
    newIndexedHeader,
    'Product',
    '<div class="hand_item_name_header">Nodularization</div>'
  );
  rowData = [...rowData, rowHeader];

  let nodularizerData = filterCmItems(
    chargeMixData.cm_items,
    elementsById,
    'NODULARIZER',
    elementSymbolById,
    cm_rr,
    furnace_size
  );
  rowData = [...rowData, ...nodularizerData];

  return { rowData, cellMeta };
};

/**
 * Retrieves the ladle data based on the provided charge mix data and other parameters.
 * @param {Object} chargeMixData - The charge mix data.
 * @param {Array} newIndexedHeader - The array of new indexed headers.
 * @param {Object} elementsById - The elements data indexed by ID.
 * @param {Object} elementSymbolById - The element symbols data indexed by ID.
 * @param {Object} cm_rr - The charge mix recovery rates.
 * @param {number} furnace_size - The size of the furnace.
 * @returns {Object} - An object containing the ladle data (rowData and cellMeta).
 */
export const getLadleData = (
  chargeMixData,
  newIndexedHeader,
  elementsById,
  elementSymbolById,
  cm_rr,
  furnace_size
) => {
  let rowData = [];
  let cellMeta = [];

  let rowHeader = setRowByNotion(
    newIndexedHeader,
    'Product',
    '<div class="hand_item_name_header">Ladle</div>'
  );
  rowData = [...rowData, rowHeader];

  let ladleData = filterCmItems(
    chargeMixData.cm_items,
    elementsById,
    'LADLE',
    elementSymbolById,
    cm_rr,
    furnace_size
  );
  rowData = [...rowData, ...ladleData];

  return { rowData, cellMeta };
};

/**
 * Sets the target chemistry data based on the provided parameters and updates the target chemistry state.
 * @param {Object} chargeMixData - The charge mix data.
 * @param {Array} newIndexedHeader - The array of new indexed headers.
 * @param {Function} setTargetChemistry - The function to set the target chemistry state.
 * @returns {Object} - The target chemistry row object.
 */
export const setTargetChemistryData = ({
  chargeMixData,
  newIndexedHeader,
  setTargetChemistry
}) => {
  let targetChemistryRow = {};
  newIndexedHeader.map(header => {
    let tc = chargeMixData.cm_target_chemistry.find(
      ele => ele.element__symbol == header
    );
    targetChemistryRow = {
      ...targetChemistryRow,

      [header]: tc ? `<b>${formatRange(tc.ele_range)}</b>` : null
    };
  });
  // SET TARGET CHEMISTRY
  setTargetChemistry(targetChemistryRow);

  targetChemistryRow = {
    ...targetChemistryRow,
    id: 'tc',
    Product: '<div class="hand_item_name_header">Target Chemistry</div>'
  };
  return targetChemistryRow;
};

/**
 * Retrieves the final chemistry data and updates the rowData and cellMeta arrays accordingly.
 * @param {Array} rowData - The array of table row data.
 * @param {Array} cellMeta - The array of cell metadata.
 * @param {Object} chargeMixData - The charge mix data.
 * @param {Array} newIndexedHeader - The array of new indexed headers.
 * @param {Object} elementSymbolById - The mapping of element symbol by ID.
 * @returns {Object} - An object containing the updated rowData and cellMeta arrays.
 */
export const getFinalChemistryData = (
  rowData,
  cellMeta,
  chargeMixData,
  newIndexedHeader,
  elementSymbolById
) => {
  const final_qty_chemistry = chargeMixData.curr_pred_chemistry
    ? chargeMixData.curr_pred_chemistry[LADLE]
    : calculateChemistry(chargeMixData, elementSymbolById, 'qty');

  const finalChemistryRow = {
    ...final_qty_chemistry,
    Product: '<div class="hand_item_name_header">Final Chemistry</div>'
  };

  const cell_meta_qty_final = setMetaForChemistry(
    newIndexedHeader,
    rowData.length,
    final_qty_chemistry,
    chargeMixData.cm_target_chemistry
  );

  cellMeta = [...cellMeta, ...cell_meta_qty_final];
  rowData = [...rowData, finalChemistryRow];

  return { rowData, cellMeta };
};

/**
 * Retrieves the optimized final chemistry data and updates the rowData and cellMeta arrays accordingly.
 * @param {Object} params - The parameters object.
 * @param {Array} params.rowData - The array of table row data.
 * @param {Array} params.cellMeta - The array of cell metadata.
 * @param {Object} params.chargeMixData - The charge mix data.
 * @param {Array} params.newIndexedHeader - The array of new indexed headers.
 * @param {Object} params.elementSymbolById - The mapping of element symbol by ID.
 * @returns {Object} - An object containing the updated rowData and cellMeta arrays.
 */
export const getOptimizedFinalChemistryData = ({
  rowData,
  cellMeta,
  chargeMixData,
  newIndexedHeader,
  elementSymbolById
}) => {
  let optimized_qty_chemistry = getChemistry({
    predKey: 'f',
    chargeMixData,
    elementSymbolById
  });

  const cell_meta_optimized_qty_chemistry = setMetaForChemistry(
    newIndexedHeader,
    rowData.length,
    optimized_qty_chemistry,
    chargeMixData.cm_target_chemistry
  );
  cellMeta = [...cellMeta, ...cell_meta_optimized_qty_chemistry];

  const optimizedFinalChemistryRow = {
    ...optimized_qty_chemistry,
    Product:
      '<div class="hand_item_name_header">Suggested Final Chemistry</div>'
  };

  rowData = [...rowData, optimizedFinalChemistryRow];

  return { rowData, cellMeta };
};

/**
 * Adds cell metadata for a specific column in the table based on the provided rowData.
 * @param {Array} rowData - The array of table row data.
 * @param {number} columnNumber - The column number to apply the cell metadata.
 * @param {string} className - The class name to be assigned to the cell metadata.
 * @returns {Array} - An array of cell metadata objects representing the positions of cells in the specified column.
 *                    Each object contains the rowNum, colNum, and className properties.
 *                    Returns an empty array if no cells with non-null values are found in the specified column.
 */
export const addCellMeta = (rowData, columnNumber, className) => {
  let cellMeta = [];
  for (let i = 0; i < rowData.length; i++) {
    if (rowData[i][OPTIMIZEDQTY] || rowData[i][OPTIMIZEDQTY] == 0) {
      cellMeta.push({ rowNum: i, colNum: columnNumber, className });
    }
  }
  return cellMeta;
};

/**
 * Adds cell metadata for new items in the table based on the provided rowData and headers.
 * @param {Array} rowData - The array of table row data.
 * @param {Array} headers - The array of table headers.
 * @returns {Array} - An array of cell metadata objects representing the positions of new items.
 *                    Each object contains the rowNum, colNum, and className properties.
 *                    Returns an empty array if no new items are found in the rowData.
 */
export const addNewItemMeta = (rowData, headers) => {
  let cellMeta = [];
  if (rowData.findIndex(i => i.is_new_item == true) > -1) {
    headers.map((header, index) => {
      cellMeta.push({
        rowNum: rowData.findIndex(i => i.is_new_item == true),
        colNum: index,
        className: 'new_item'
      });
    });
  }
  return cellMeta;
};

/**
 * Filters CM Items with types
 * @param {Array} cm_items - CM Items
 * @param {Number} furnace_size - Size of the Furnace
 * @param {String} type - Items Type
 * @param {String} cm_rr - CM Recovery Rate
 * @param {String} heelQty - Size of Heel Quantity
 * @return {Object} Object containing row shown in Handsontable
 */
export function filterAndMapCmItems(
  { cm_items, furnace_size },
  type,
  cm_rr,
  heelQty = 0
) {
  return cm_items
    .filter(item => item.cm_type === type)
    .map(item => {
      return {
        cm_type: type,
        id: item.id,
        item_name: item.item_name,
        qty: toFixedValue(item.qty, 3) || '0',
        stock: toFixedValue(item.stock, 3) || null,
        price: toFixedValue(item.price, 2),
        modulus_qty: item.modulus_qty ? item.modulus_qty.toString() : '0',
        max_qty:
          item.max_qty || parseInt(item.max_qty) === 0
            ? toFixedValue(item.max_qty, 4)
            : null,
        min_qty: item.min_qty ? toFixedValue(item.min_qty, 4) : null,
        [cm_rr]: item[cm_rr]?.reduce((rrObject, rr) => {
          rrObject[rr.element] = rr.rate;
          return rrObject;
        }, {}),
        metal_rr: toFixedValue(item.metal_rr, 2)
      };
    });
}

/**
 * Sets the row metadata for the table based on the provided data and functions.
 * @param {Object} options - Options object containing the following properties:
 *   - chargeMixData {Object}: The charge mix data object.
 *   - elementsHeader {Array}: The array of elements header.
 *   - cm_rr_head {Array}: The array of charge mix recovery rate header.
 *   - elementsById {Object}: The elements data object indexed by ID.
 *   - elementSymbolById {Object}: The element symbol lookup object.
 *   - cm_rr {Object}: The charge mix recovery rate data object.
 *   - setTargetChemistry {Function}: Function to set the target chemistry row.
 *   - setHeaderLength {Function}: Function to set the length of the table header.
 *   - setCsvHeader {Function}: Function to set the CSV header.
 *   - setIndexedHeader {Function}: Function to set the indexed header.
 *   - setCsvData {Function}: Function to set the CSV data.
 *   - setTableCellMeta {Function}: Function to set the table cell metadata.
 */
export function setRowMetaData({
  chargeMixData,
  elementsHeader,
  cm_rr_head,
  elementsById,
  elementSymbolById,
  cm_rr,
  setTargetChemistry,
  setHeaderLength,
  setCsvHeader,
  setIndexedHeader,
  setCsvData,
  setTableCellMeta
}) {
  const fixedHeader = getFixedHeader(chargeMixData);
  const newCsvHeader = getNewCsvHeader(fixedHeader, elementsHeader, cm_rr_head);
  const newIndexedHeader = getIndexedHeaders(fixedHeader, elementsHeader);

  let { rowData, cellMeta } = getFurnaceData(
    chargeMixData,
    newIndexedHeader,
    elementsById,
    elementSymbolById,
    cm_rr,
    chargeMixData.furnace_size
  );
  const blankRow = setRowByNotion(newIndexedHeader, null, null);
  const additivesData = getAdditivesData(
    chargeMixData,
    newIndexedHeader,
    elementsById,
    elementSymbolById,
    cm_rr,
    chargeMixData.furnace_size
  );
  cellMeta = [...cellMeta, ...additivesData.cellMeta];
  rowData = [...rowData, blankRow, ...additivesData.rowData, blankRow];
  const bathChemistryData = getBathChemistryData({
    rowData,
    cellMeta,
    chargeMixData,
    newIndexedHeader,
    elementSymbolById: elementsById
  });

  cellMeta = [...bathChemistryData.cellMeta];
  rowData = [...bathChemistryData.rowData, blankRow];

  const nodularizationData = getNodularizationData(
    rowData,
    cellMeta,
    chargeMixData,
    newIndexedHeader,
    elementsById,
    elementSymbolById,
    cm_rr,
    chargeMixData.furnace_size
  );
  rowData = [...nodularizationData.rowData, blankRow];

  const ladleData = getLadleData(
    chargeMixData,
    newIndexedHeader,
    elementsById,
    elementSymbolById,
    cm_rr,
    chargeMixData.furnace_size
  );
  rowData = [...rowData, ...ladleData.rowData, blankRow];

  let targetChemistryRow = {};
  newIndexedHeader.map(header => {
    let tc = chargeMixData.cm_target_chemistry.find(
      ele => ele.element__symbol == header
    );
    targetChemistryRow = {
      ...targetChemistryRow,

      [header]: tc ? `<b>${formatRange(tc.ele_range)}</b>` : null
    };
  });
  // SET TARGET CHEMISTRY
  setTargetChemistry(targetChemistryRow);

  targetChemistryRow = {
    ...targetChemistryRow,
    id: 'tc',
    Product: '<div class="hand_item_name_header">Target Chemistry</div>'
  };
  rowData = [...rowData, blankRow, blankRow, targetChemistryRow];

  const finalChemistryData = getFinalChemistryData(
    rowData,
    cellMeta,
    chargeMixData,
    newIndexedHeader,
    // elementsById,
    elementSymbolById
  );
  cellMeta = [...finalChemistryData.cellMeta];
  rowData = [...finalChemistryData.rowData];

  const optimizedFinalChemistryData = getOptimizedFinalChemistryData({
    rowData,
    cellMeta,
    chargeMixData,
    newIndexedHeader,
    // elementsById,
    elementSymbolById
  });
  cellMeta = [...optimizedFinalChemistryData.cellMeta];
  rowData = [...optimizedFinalChemistryData.rowData];

  cellMeta = [...cellMeta, ...addCellMeta(rowData, 3, 'valid')];
  cellMeta = [...cellMeta, ...addNewItemMeta(rowData, newIndexedHeader)];

  setHeaderLength(
    fixedHeader.length + chargeMixData.cm_target_chemistry.length
  );
  setCsvHeader(newCsvHeader);
  setIndexedHeader(newIndexedHeader);
  setCsvData(rowData);
  setTableCellMeta(cellMeta);
}

export const getCMChemistry = async cmData => {
  try {
    const headers = {
      method: 'POST',
      headers: {
        authorization: token(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(cmData)
    };

    const response = await cFetch(
      `${config.API_URL}/api/c/calculate_chemistry/`,
      headers
    );

    return response;
  } catch (error) {
    console.error(error);
  }
};
