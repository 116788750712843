import { Button, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import {
  exportAsCSV,
  getCmList_v2,
  getGradeCodeList_v2,
  getGradeList_v2,
  getISOTime,
  getSampleType
} from '../../../utils/Spectrometer';
import classes from './SpectroListingTable.module.scss';
import CustomAutocomplete from '../../General/CustomAutocomplete';
import clsx from 'clsx';
import { getPartNames, getPartNumbers } from '../../../api/spectroApi';
import { useSelector } from 'react-redux';
import { SPECTRO_CONSTS } from '../../../constants/Spectrometer';
import { handleDateChange } from '../../../utils';

/**
 * this function check wether the query param are empty or not
 * @param {object} data
 * return Boolean
 * )
 */
function checkQueryContainsParam(data) {
  return Object.keys(data).some(keys => {
    return data[keys] != undefined;
  });
}

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: 400,
    backgroundColor: theme.palette.background.default
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  inputBox: {
    width: '100%',
    color: 'black'
  }
}));

const DownloadDrawer = ({ handleClose, query }) => {
  const [text, setText] = useState('');
  const [chargeText, setChargeText] = useState('');
  const [partNameText, setPartNameText] = useState('');
  const [partNumberText, setPartNumberText] = useState('');
  const [gradeCode, setGradeCode] = useState('');
  const [data, setData] = useState({
    grade: '' || query.grade,
    grade__grade_code: '' || query.grade__grade_code,
    sample_type: '' || query.readings__sample_type,
    cm: '' || query.cm,
    heat_date__gte: query.heat_date__gte || getISOTime(30),
    heat_date__lte: query.heat_date__lte || getISOTime(),
    grade__part_name: '' || query.grade__part_name,
    grade__part_no: '' || query.grade__part_no
  });

  const userDetail = useSelector( state => state.userDetail )

  const { START_DATE, END_DATE } = SPECTRO_CONSTS

  const sampleTypeOptions = getSampleType(userDetail?.customer?.furnace_type)

  const muiStyles = useStyles();

  const handleChange = (type, value) => {
    const date_range = handleDateChange(type, value, data);
    setData(prev => ({ ...prev, ...date_range}))
  }

  return (
    <div>
      <Button className={muiStyles.closeButton} onClick={handleClose}>
        <i
          className='ri-close-line'
          style={{ fontSize: '2rem', lineHeight: '2rem', color: '#909090' }}
        ></i>
      </Button>
      <div className={classes.filterHeader}>Download Requirements</div>
      <div className={classes.horizontalLine} />
      <div className={classes.filterBody}>
        <div className={classes.filterSubHeader}>Date Range</div>
        <div className={classes.dateRangeInput}>
          <input
            type='date'
            className={classes.inputbox_date}
            max={getISOTime()}
            value={data.heat_date__gte}
            onChange={(e) => handleChange(START_DATE, e.target.value)}
          />
          <span className={classes.spaceHifen}>-</span>
          <input
            type='date'
            className={classes.inputbox_date}
            max={getISOTime()}
            value={data.heat_date__lte}
            onChange={(e) => handleChange(END_DATE, e.target.value)}
          />
        </div>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Sample Type</div>
        <select
          placeholder='Sample Type'
          id='outlined-basic'
          value={data.sample_type}
          className={classes.selectBox}
          onChange={e => setData({ ...data, sample_type: e.target.value })}
        >
            {sampleTypeOptions.map(({label, value}) => <option key={value} value={value || label}>{label}</option>)}
        </select>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Charge Mix</div>
        <FormControl fullWidth>
          <label style={{ width: '100%' }}>
            <CustomAutocomplete
              onSelect={value => setData({ ...data, cm: value?.id })}
              handleChange={value => setChargeText(value)}
              userInput={chargeText}
              placeholderText='Select the chargemix'
              label='Chargemix Name'
              nameIndentifier={'cm_name'}
              callbackFunc={getCmList_v2}
              apiQuery={chargeText}
            />
          </label>
        </FormControl>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Grade </div>
        <FormControl fullWidth>
          <label style={{ width: '100%' }}>
            <CustomAutocomplete
              onSelect={value => setData({ ...data, grade: value?.id })}
              setUserInput={value => setText(value)}
              userInput={text}
              placeholderText='Select a Metal Grade'
              label='Metal grade'
              nameIndentifier={'name'}
              callbackFunc={getGradeList_v2}
              handleChange={value => setText(value)}
              apiQuery={text}
              deSelectItem={value => setData({ ...data, grade: '' })}
            />
          </label>
        </FormControl>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Grade Code</div>
        <FormControl fullWidth>
          <label style={{ width: '100%' }}>
            <CustomAutocomplete
              onSelect={value => setData({ ...data, grade__grade_code: value })}
              setUserInput={value => setGradeCode(value)}
              userInput={gradeCode}
              placeholderText='Select a Metal Grade Code'
              label='Grade Code'
              callbackFunc={getGradeCodeList_v2}
              handleChange={value => setGradeCode(value)}
              apiQuery={gradeCode}
              deSelectItem={value => setData({ ...data, grade__grade_code: '' })}
            />
          </label>
        </FormControl>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Part Name</div>
        <CustomAutocomplete
          onSelect={value => setData({ ...data, grade__part_name: value })}
          setUserInput={value => setPartNameText(value)}
          userInput={partNameText}
          placeholderText='Select a Part Name'
          label='Part Name'
          callbackFunc={getPartNames}
          handleChange={value => setPartNameText(value)}
          apiQuery={partNameText}
          deSelectItem={value => setData({ ...data, grade__part_name: '' })}
        />
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Part Number</div>
        <CustomAutocomplete
          onSelect={value => setData({ ...data, grade__part_no: value })}
          setUserInput={value => setPartNumberText(value)}
          userInput={partNumberText}
          placeholderText='Select a Part Number'
          label='Part Number'
          callbackFunc={getPartNumbers}
          handleChange={value => setPartNumberText(value)}
          apiQuery={partNumberText}
          deSelectItem={value => setData({ ...data, grade__part_no: '' })}
        />
        <div className={classes.filterBtnContainer}>
          <Button
            className={muiStyles.applyButton}
            variant='contained'
            disabled={!checkQueryContainsParam(data)}
            fullWidth
            onClick={() => {
              exportAsCSV(data);
              handleClose();
            }}
          >
            <i className={clsx('ri-check-line', classes.iconGap)}></i> Download
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DownloadDrawer;
