import { useEffect, useRef } from 'react';
import { isDeepEqual } from '../utils/deepEquals';

function useDeepCompareMemoize(deps) {
  const ref = useRef();

  const isSame = isDeepEqual(deps, ref.current);
  if (!isSame) ref.current = JSON.parse(JSON.stringify(deps));

  return ref.current;
}

export function useDeepCompareEffect(callback, dependencies) {
  useEffect(callback, useDeepCompareMemoize(dependencies));
}
