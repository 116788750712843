import React from 'react';
import { sendAnalytics } from '../../utils/analyticsUtils';
import styles from './General.module.scss';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

const FilterBox = ({
  count,
  text,
  active = false,
  color,
  value,
  handleChange,
  eventPrefix = '',
  sx = {},
  className = ''
}) => {
  const { analyticsKey } = useSelector(state => state);
  return (
    <div
      className={clsx(
        styles.headerChargemixBoxes,
        className,
        active ? styles.active : ''
      )}
      style={{
        color,
        border: `1px solid ${active ? color : '#eee'}`,
        backgroundColor: `${active ? `${color}1a` : '#fff'}`,
        ...sx
      }}
      onClick={() => {
        if (!active) {
          const eventName = `${analyticsKey || eventPrefix}_Filter${text}`;
          const analytics_Details = {
            defaultName: eventName.replace(/\s+/g, ''),
            defaultDetails: `${
              analyticsKey || eventPrefix
            } filter changed to ${text}`
          };
          sendAnalytics(analytics_Details);
          handleChange(value);
        }
      }}
    >
      <div className={styles.chargemixesNumber}>{count}</div>
      <div className={styles.chargemixesText}>{text}</div>
    </div>
  );
};

export default FilterBox;
