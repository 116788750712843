import { useState, cloneElement } from 'react';

import Tooltip from '@mui/material/Tooltip';

import classes from './WithTooltip.module.scss';
import { isMobile } from 'react-device-detect';

const WithTooltip = ({
  tooltipText,
  children,
  placement = 'top',
  onMouseMovements
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const additionalProps = onMouseMovements
    ? {
        onMouseEnter: () => setIsTooltipOpen(true),
        onMouseLeave: () => setIsTooltipOpen(false)
      }
    : {
        onFocus: () => setIsTooltipOpen(true),
        onBlur: () => setIsTooltipOpen(false)
      };

  if (isMobile) {
    return cloneElement(children, additionalProps);
  }

  return (
    <Tooltip
      placement={placement}
      arrow={true}
      title={tooltipText}
      open={isTooltipOpen}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
    >
      {cloneElement(children, additionalProps)}
    </Tooltip>
  );
};

export default WithTooltip;
