import { USER_DETAIL_ACTION_TYPES } from './userDetailActionTypes';
import initialState from '../initial-state';

export default function userDetailById(
  state = initialState.userDetailById,
  action
) {
  switch (action.type) {
    case USER_DETAIL_ACTION_TYPES.LOAD_USER_BY_ID_DETAIL_SUCCESS: {
      return action.userById;
    }
    default:
      return state;
  }
}
