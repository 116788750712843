import { DEMO_MODE } from '../actionTypes';
import {
  actionClearType,
  actionFailedType,
  actionSuccessType
} from '../actionUtils/actionTypes';

const INITIAL_STATE = { isDemoMode: false };

const chargeMixDemoReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionSuccessType(DEMO_MODE):
      return { ...state, isDemoMode: true };

    case actionFailedType(DEMO_MODE):
      return { ...state, isDemoMode: false };

    case actionClearType(DEMO_MODE):
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default chargeMixDemoReducer;
