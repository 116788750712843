import { AUTH_ACTION_TYPES } from './authActionTypes';
import initialState from '../initial-state';

export default function (state = initialState.auth, action) {
  switch (action.type) {
    case AUTH_ACTION_TYPES.LOGIN_SUCCESS: {
      localStorage.setItem('tokenv2', action.token);
      return {
        ...state,
        token: action.token,
        isLoggedIn: 1
      };
    }
    case AUTH_ACTION_TYPES.SEND_OTP_SUCCESS: {
      return {
        ...state,
        mobileno: action.mobileno
      };
    }
    case AUTH_ACTION_TYPES.LOGOUT_APP: {
      return {
        ...state,
        token: null,
        isLoggedIn: 0
      };
    }
    default:
      return state;
  }
}
