import { SUBCATEGORIES_ACTION_TYPES } from './subcategoriesActionTypes';
import initialState from '../initial-state';

export default function (state = initialState.subcategories, action) {
  switch (action.type) {
    case SUBCATEGORIES_ACTION_TYPES.LOAD_SUBCATEGORIES_SUCCESS:
      return action.subcategories;
    default:
      return state;
  }
}
