import React, { useEffect, useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Box,
  Tabs,
  Tab,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { connect } from 'react-redux';
import styles from './PrintModal.module.scss';
import { sendAnalytics } from '../../../utils/analyticsUtils';
import {
  updateChargeMixV2,
  getChargeMix
} from '../../../redux/actions/chargeMix';
import { ReactComponent as CheckedIcon } from '../../../assets/images/checkedIcon.svg';
import { ReactComponent as UnCheckedIcon } from '../../../assets/images/unCheckedIcon.svg';
import {
  fetchUserContacts,
  getPdf,
  shareOverWhatsApp
} from '../../../utils/ChargeMix';

import { handleApiCallWithToast } from '../../../lib/react-toastify';

/**
 * A component to render the content of a tab panel.
 *
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be rendered inside the tab panel.
 * @param {number} props.value - The current value of the tab.
 * @param {number} props.index - The index of the tab panel.
 * @param {Object} props.other - Additional props to be spread onto the root div element.
 * @returns {JSX.Element} - The TabPanel component.
 */
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: '20px' }}>{children}</Box>}
    </div>
  );
}

export const boxStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#fff',
  boxShadow: 24,
  padding: '24px',
  borderRadius: '12px',
  minHeight: '300px',
  minWidth: '600px'
};

/**
 * A modal component for printing and sharing charge mix data.
 *
 * @param {boolean} openShareCm - Flag indicating whether the print modal is open or closed.
 * @param {Function} handleClose - Callback function to handle the close event of the print modal.
 * @param {Object} chargeMixData - The data representing the charge mix.
 * @returns {JSX.Element} - The PrintModal component.
 */
const PrintModal = ({ openShareCm, handleClose, chargeMixData }) => {
  // Storing Different Tab Data
  const tabData = [
    {
      label: (
        <>
          <i className='ri-printer-line'></i>Print
        </>
      )
    },
    {
      label: (
        <>
          <i className='ri-share-forward-line'></i>Share on Whatsapp
        </>
      )
    }
  ];

  const [selectedTab, setSelectedTab] = useState(0);
  const [contactsData, setContactsData] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);

  // Boolean Variable to check if all contacts are selected or not.
  const isSelectedAll = contactsData?.length === selectedContacts?.length;

  /**
   * Handle the change event of a tab selection.
   *
   * @param {Object} event - The event object triggered by the tab selection.
   * @param {any} value - The value of the selected tab.
   * @returns {void}
   */
  const handleChange = (event, value) => {
    setSelectedTab(value);
  };

  /**
   * Generate accessibility props for a tab component based on the given index.
   *
   * @param {number} index - The index of the tab.
   * @returns {Object} - An object containing the generated accessibility props.
   */
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  }

  /**
   * Handle the click event for opening and downloading ChargeMix PDF file.
   *
   * @param {Event} e - The click event object.
   * @returns {void}
   */
  const handlePdfClick = async e => {
    e.preventDefault();
    handleApiCallWithToast({
      apiFunc: () => getPdf(chargeMixData.id),
      toastText: {
        pending: 'Please Wait! We’re generating your ChargeMix report.',
        error:
          'Error encountered while generating your ChargeMix Report. Please retry.',
        success: 'Your ChargeMix PDF report is ready'
      },
      delay: 2000
    });
    const analytics_Details = {
      defaultName: 'BOTTOM_PRINT_CHARGEMIX',
      defaultDetails: `Clicked on Print Button of ChargeMix ${chargeMixData.cm_name}-${chargeMixData.grade}`
    };
    sendAnalytics(analytics_Details);
  };

  /**
   * Handle the click event for sharing the ChargeMix PDF file over WhatsApp.
   *
   * @returns {void}
   */
  const handleWhatsAppShare = async () => {
    const bodyData = {
      user_id_list: selectedContacts
    };
    handleApiCallWithToast({
      apiFunc: () => shareOverWhatsApp(chargeMixData.id, bodyData),
      onSuccess: () => handleClose(),
      toastText: {
        pending: 'Sending your ChargeMix report',
        error: 'Oops! An error occurred while sharing. Please try again.',
        Success: 'ChargeMix report has been successfully shared'
      },
      delay: 2000
    });
    const analytics_Details = {
      defaultName: 'SHARE_ON_WHATSAPP_CM',
      defaultDetails: `Clicked on Share Button of ChargeMix ${chargeMixData.cm_name}-${chargeMixData.grade}`
    };
    sendAnalytics(analytics_Details);
  };

  /**
   * Handle the selection of all contacts.
   *
   * @param {Event} event - The event object triggered by the select all checkbox.
   * @returns {void}
   */
  const handleSelectAll = event => {
    if (event.target.checked) {
      setSelectedContacts(contactsData.map(contact => contact.id));
    } else setSelectedContacts([]);
  };

  /**
   * Handle the selection of each contact.
   *
   * @param {Event} event - The event object triggered by the select all checkbox.
   * @param {Number} user_id - Id of the user selected or deselected.
   * @returns {void}
   */
  const handleContactSelection = (event, user_id) => {
    if (event.target.checked) {
      setSelectedContacts(prev => [...prev, user_id]);
    } else {
      setSelectedContacts(
        selectedContacts.filter(contact => contact !== user_id)
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchUserContacts();
        if (response) {
          setContactsData(response);
          setSelectedContacts(response.map(contact => contact.id));
        }
      } catch (err) {
        console.error(
          `Failed to load the user contacts data. Please, Try again.`
        );
      }
    };

    fetchData();
  }, []);

  return (
    <Modal
      open={openShareCm}
      onClose={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
        components: {
          backdrop: Backdrop // Use slots.backdrop instead of BackdropComponent
        }
      }}
    >
      <Fade in={openShareCm}>
        <Box sx={boxStyle}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label='basic tabs example'
            className={styles.MuiTabs_scroller}
          >
            {tabData.map((tab, index) => {
              return (
                <Tab
                  className={styles.headerContainer}
                  label={tab.label}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            <span className={styles.printDesc}>
              {' '}
              Print ChargeMix Details for Approval{' '}
            </span>
            <div className={styles.modalFooter}>
              <button className={styles.cancelButton} onClick={handleClose}>
                <i className='ri-close-line'></i>
                <span>Cancel</span>
              </button>
              <button className={styles.shareButton} onClick={handlePdfClick}>
                <i className='ri-printer-line'></i>
                <span>Print</span>
              </button>
            </div>
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <div className={styles.shareHeader}>
              <span className={styles.shareDesc}>
                {' '}
                Select People to Share over WhatsApp{' '}
              </span>
              <FormControlLabel
                value='Select_All'
                control={
                  <Checkbox
                    icon={<UnCheckedIcon width='16px' />}
                    checkedIcon={<CheckedIcon width='16px' />}
                    checked={isSelectedAll}
                    onChange={handleSelectAll}
                  />
                }
                label='Select All'
                labelPlacement='end'
              />
            </div>
            <div className={styles.contactsData}>
              {contactsData?.map(contact => {
                const selected =
                  selectedContacts.filter(d => d === contact.id)?.length > 0;
                return (
                  <div
                    key={contact.id}
                    className={`${styles.contactsContainer} ${
                      selected ? styles.checkedBox : styles.unCheckedBox
                    }`}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          icon={<UnCheckedIcon />}
                          checkedIcon={<CheckedIcon />}
                          className={
                            selected
                              ? styles.contactCheckbox
                              : styles.contactUnChecked
                          }
                          checked={selected}
                          onChange={e => handleContactSelection(e, contact?.id)}
                        />
                      }
                      label={`${contact.name} (${contact.mobile})`}
                    />
                  </div>
                );
              })}
            </div>
            <div className={styles.modalFooter}>
              <button className={styles.cancelButton} onClick={handleClose}>
                <i className='ri-close-line'></i>
                <span>Cancel</span>
              </button>
              <button
                className={styles.shareButton}
                onClick={handleWhatsAppShare}
              >
                <i className='ri-whatsapp-line'></i>
                <span>Share</span>
              </button>
            </div>
          </TabPanel>
        </Box>
      </Fade>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    chargeMixData: state.chargeMix.read.data
  };
}

const mapDispatchToProps = {
  updateChargeMixV2,
  getChargeMix
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintModal);
